import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    result: {
      display: 'flex',
      alignItems: 'baseline',
      marginTop: 40,
      marginBottom: 20,
    },
  },
  sg,
)
