// LIBS
import immutable from "immutable";

export default immutable.fromJS({
  list: [
    {
      type: "glossary",
      content: "",
    },
  ],
});
