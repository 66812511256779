/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// REACT
import React, { Fragment, useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

import TextField from "components/text-field";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  SearchOutlined,
} from "@material-ui/icons";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getFilters,
  getRestUri,
  getResults,
  getSchede,
} from "redux/getters";
import {
  findSchede,
  getAdminIndirizziSchedeAjax,
  getAdminSchedeAjax,
  getSchede_idsAjax,
} from "redux/actions";

// LIBS
import cn from "classnames";

// STYLE
import styles from "./style.js";

function AdminSchedeIndirizziLocali({ classes, t }) {
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const [textFilter, setTextFilter] = useState("");
  const [filteredSchede, setFilteredSchede] = useState([]);
  const results = useSelector(getResults);
  const [alphabet, setAlphabet] = useState({});
  const schede = useSelector(getSchede);
  useEffect(() => {
    dispatch(getAdminIndirizziSchedeAjax(restUri));
  }, []);
  useEffect(() => {
    if (results.size > 0) {
      dispatch(
        getSchede_idsAjax(restUri, {
          query: {
            _ids: results.toJS(),
          },
        }),
      );
    }
  }, [results]);
  useEffect(() => {
    const temp = {};
    const filteredSchedeTemp = schede.filter((scheda, index) =>
      scheda.titolo_prestazione
        .toLowerCase()
        .match(new RegExp(`.*${textFilter.toLowerCase()}.*`)),
    );
    setFilteredSchede(filteredSchedeTemp);
    filteredSchedeTemp.forEach((scheda, index) => {
      if (temp[scheda.titolo_prestazione[0].toUpperCase()] === undefined) {
        temp[scheda.titolo_prestazione[0].toUpperCase()] = index;
      }
    });
    setAlphabet(temp);
  }, [schede, textFilter]);
  return (
    <div className={classes.wrapperWidthLimiter}>
      <div className={classes.title}>
        <Typography variant="h2">Lista schede</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textFieldInput}
            placeholder="Scrivi qui la prestazione che cerchi"
            onChange={(event) => setTextFilter(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlined className={classes.searchOutlined} />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Paper
        classes={{
          root: cn(classes.paper),
        }}
      >
        <List
          dense
          classes={{
            root: classes.list,
          }}
        >
          {filteredSchede.map((scheda, index) => {
            const labelId = `checkbox-list-secondary-label-${index + 1}`;
            return (
              <Fragment>
                {index !== 0 && (
                  <Divider
                    classes={{
                      root: classes.divider,
                    }}
                  />
                )}
                <ListItem
                  classes={{
                    root: classes.listItem,
                  }}
                  role={undefined}
                  dense
                  button
                  disableRipple
                  component="a"
                  href={`#/admin/schede/${scheda._id}/inserisci-modifica-indirizzi-locali/`}
                >
                  {Object.values(alphabet).indexOf(index) !== -1 && (
                    <div className={classes.circle}>
                      <Typography>
                        {scheda.titolo_prestazione[0].toUpperCase()}
                      </Typography>
                    </div>
                  )}
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                    }}
                    id={labelId}
                  >
                    <Typography
                      classes={{
                        root: classes.listItemTextTypography,
                      }}
                    >
                      {`${scheda.titolo_prestazione}`}
                    </Typography>
                  </ListItemText>
                  {
                    <ChevronRight
                      iconstyle={classes.chevronRight.path}
                      classes={{
                        root: classes.chevronRight,
                      }}
                    />
                  }
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      </Paper>
    </div>
  );
}

AdminSchedeIndirizziLocali.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminSchedeIndirizziLocali));
