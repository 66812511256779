/* eslint-disable camelcase */
import {
  DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST,
  GET_ADMIN_STATIC_CONTENTS_AJAX_REQUEST, GET_SCHEDE_RECENTI_AJAX_REQUEST,
  UPDATE_ADMIN_STATIC_CONTENTS_AJAX_REQUEST,
} from "../../action-types";

export const getAdminIndirizziAjax = (restUri, others) => ({
  type: "GET_ADMIN_INDIRIZZI_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/indirizzi`,
  ...others,
});

export const createAdminIndirizziAjax = (restUri, payload, others) => ({
  type: "CREATE_ADMIN_INDIRIZZI_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/admin/indirizzi`,
  data: payload,
  ...others,
});

export const getAdminIndirizziSchedeAjax = (restUri, others) => ({
  type: "GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/indirizzi/schede`,
  ...others,
});

export const getAdminSchedeAjax = (restUri, others) => ({
  type: "GET_ADMIN_SCHEDE_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/schede`,
  ...others,
});

export const createAdminSchedeAjax = (restUri, payload, others) => ({
  type: "CREATE_ADMIN_SCHEDE_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/admin/schede`,
  data: payload,
  ...others,
});

export const getAdminSchedeBy_idAjax = (restUri, _id, others) => ({
  type: "GET_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/schede/${_id}`,
  ...others,
});

export const updateAdminSchedeBy_idAjax = (restUri, _id, payload, others) => ({
  type: "UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST",
  method: "PUT",
  url: `${restUri}api/v0/admin/schede/${_id}`,
  data: payload,
  ...others,
});

export const deleteAdminSchedeBy_idAjax = (restUri, _id, others) => ({
    type: DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST,
    method: "DELETE",
    url: `${restUri}api/v0/admin/schede/${_id}`,
    ...others,
});

export const getAdminStaticContentsAjax = (restUri, contentType, others) => ({
  type: GET_ADMIN_STATIC_CONTENTS_AJAX_REQUEST,
  method: "GET",
  url: `${restUri}api/v0/admin/static-contents?type=${contentType}`,
  ...others,
});

export const updateAdminStaticContentsAjax = (restUri, payload, others) => ({
  type: UPDATE_ADMIN_STATIC_CONTENTS_AJAX_REQUEST,
  method: "PUT",
  url: `${restUri}api/v0/admin/static-contents`,
  data: payload,
  ...others,
});

export const getAdminTagsAjax = (restUri, others) => ({
  type: "GET_ADMIN_TAGS_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/tags`,
  ...others,
});

export const createAdminTagsAjax = (restUri, payload, others) => ({
  type: "CREATE_ADMIN_TAGS_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/admin/tags`,
  data: payload,
  ...others,
});

export const updateAdminTagsBy_idAjax = (restUri, _id, payload, others) => ({
  type: "UPDATE_ADMIN_TAGS_BY__ID_AJAX_REQUEST",
  method: "PUT",
  url: `${restUri}api/v0/admin/tags/${_id}`,
  data: payload,
  ...others,
});

export const deleteAdminTagsBy_idAjax = (restUri, _id, others) => ({
  type: "DELETE_ADMIN_TAGS_BY__ID_AJAX_REQUEST",
  method: "DELETE",
  url: `${restUri}api/v0/admin/tags/${_id}`,
  ...others,
});

export const getAdminUsersAjax = (restUri, others) => ({
  type: "GET_ADMIN_USERS_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/users`,
  ...others,
});

export const createAdminUsersAjax = (restUri, payload, others) => ({
  type: "CREATE_ADMIN_USERS_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/admin/users`,
  data: payload,
  ...others,
});

export const getAdminUsersBy_idAjax = (restUri, _id, others) => ({
  type: "GET_ADMIN_USERS_BY__ID_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/admin/users/${_id}`,
  ...others,
});

export const updateAdminUsersBy_idAjax = (restUri, _id, payload, others) => ({
  type: "UPDATE_ADMIN_USERS_BY__ID_AJAX_REQUEST",
  method: "PUT",
  url: `${restUri}api/v0/admin/users/${_id}`,
  data: payload,
  ...others,
});

export const getComuniAjax = (restUri, others) => ({
  type: "GET_COMUNI_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/comuni`,
  ...others,
});

export const getIndirizziSchedeAjax = (restUri, others) => ({
  type: "GET_INDIRIZZI_SCHEDE_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/indirizzi/schede`,
  ...others,
});

export const getSchedeAjax = (restUri, others) => ({
  type: "GET_SCHEDE_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/schede`,
  ...others,
});

export const getSchedeRecentiAjax = (restUri, others) => ({
    type: GET_SCHEDE_RECENTI_AJAX_REQUEST,
    method: "GET",
    url: `${restUri}api/v0/schede-recenti`,
})

export const getSchedeBy_idAjax = (restUri, _id, others) => ({
  type: "GET_SCHEDE_BY__ID_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/schede/${_id}`,
  ...others,
});

export const getSchede_idsAjax = (restUri, others) => ({
  type: "GET_SCHEDE__IDS_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/schede/_ids`,
  ...others,
});

export const getUsersMeAjax = (restUri, others) => ({
  type: "GET_USERS_ME_AJAX_REQUEST",
  method: "GET",
  url: `${restUri}api/v0/users/me`,
  ...others,
});

export const deleteUsersTokensAjax = (restUri, others) => ({
  type: "DELETE_USERS_TOKENS_AJAX_REQUEST",
  method: "DELETE",
  url: `${restUri}api/v0/users/tokens`,
  ...others,
});

export const createUsersTokensAjax = (restUri, payload, others) => {
  console.log(`${restUri}api/v0/users/tokens`);
  return {
    type: "CREATE_USERS_TOKENS_AJAX_REQUEST",
    method: "POST",
    url: `${restUri}api/v0/users/tokens`,
    data: payload,
    ...others,
  };
};

export const createUsersResetCodeAjax = (restUri, payload, others) => ({
  type: "CREATE_USERS_RESET_CODE_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/users/reset-codes`,
  data: payload,
  ...others,
});

export const updateUsersPasswordAjax = (restUri, payload, others) => ({
  type: "UPDATE_USERS_PASSWORD_AJAX_REQUEST",
  method: "POST",
  url: `${restUri}api/v0/users/recovery-password`,
  data: payload,
  ...others,
});
