import {
  GET_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS,
  UPDATE_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS,
} from "redux/action-types";
import immutable from "immutable";
import initialState from "../../initial-states/static-contents";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS:
    case UPDATE_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS:
      state = state.updateIn(["list"], (list) => {
        const updatedStaticContent = action.data.staticContent;

        const isStaticContentAlreadyInList = list.some((item) => {
          return item.get("type") === updatedStaticContent.type;
        });

        if (isStaticContentAlreadyInList) {
          return list.map((item) => {
            if (item.get("type") === updatedStaticContent.type) {
              return immutable.fromJS(updatedStaticContent);
            }
            return item;
          });
        } else {
          return list.push(immutable.fromJS(updatedStaticContent));
        }
      });
      return state;
    default:
      return state;
  }
};
