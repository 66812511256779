import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    formControl: {
      width: '100%',
    },
    select: {
      '&:focus': {
        backgroundColor: 'white',
      },
      height: '45px',
      padding: '0 0 0 20px',
      borderRadius: '5px',
      alignItems: 'center',
      display: 'flex',
    },
    inputBase: {
      marginTop: 10,
      backgroundColor: 'white',
      height: 50,
      borderRadius: 5,
      width: '100%',
    },
    icon: {
      marginRight: 15,
    },
    menuItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemText: {
      paddingTop: 12,
      paddingBottom: 12,
      marginTop: 0,
      marginBottom: 0,
    },
    listItemTextChecked: {
      color: theme.palette.primary.main,
    },
    listItemTextTypographyChecked: {
      fontWeight: 600,
    },
    menu: {
      marginTop: 10,
    },
    checkBox: {
      width: 30,
      height: 30,
    },
    checkBoxUnchecked: {
      color: '#E7E7EA',
    },
  },
  sg,
)
