/* eslint camelcase: 0 */
// CONSTANTS
// REACT
import React, { Fragment, useEffect } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Typography, withStyles } from "@material-ui/core";
import SchedeList from "components/schede-list";
import SearchResults from "scenes/nucleo/scenes/nucleo-ricerca-risultati/components/search-results";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getFilters,
  getPrintMode,
  getRestUri,
  getResults,
  getTotal,
} from "redux/getters";
import { getSchede_idsAjax, findSchede } from "redux/actions";

// STYLE
import styles from "./style.js";

function NucleoRicercaRisultati({ classes }) {
  const filters = useSelector(getFilters);
  const results = useSelector(getResults);
  const restUri = useSelector(getRestUri);
  const total = useSelector(getTotal);
  const printMode = useSelector(getPrintMode);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findSchede(restUri, filters));
  }, [filters]);

  useEffect(() => {
    if (results.size > 0) {
      dispatch(
        getSchede_idsAjax(restUri, {
          query: {
            _ids: results.toJS(),
          },
        }),
      );
    }
  }, [results]);

  return (
    <Fragment>
      <SearchResults />
      {!printMode && (
        <div className={classes.result}>
          <Typography variant="h2">Prestazioni disponibili</Typography>
          &nbsp;
          <Typography variant="h3">{results.size}</Typography>
          <Typography variant="h4">/{total}</Typography>
        </div>
      )}
      <SchedeList />
    </Fragment>
  );
}

NucleoRicercaRisultati.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(NucleoRicercaRisultati));
