/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// CONSTANTS
import chapters from "constants/chapters";

// REACT
import React, { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import { Button, Grid, Paper, withStyles } from "@material-ui/core";
import { useForm } from "components/use-form";
import EditorScheda, {
  editingForm,
} from "scenes/admin/components/editor-scheda";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import BackButton from "components/back-button";

// REDUX
import {getIsSuperAdmin, getRestUri} from "redux/getters";
import { useSelector, useDispatch } from "react-redux";
import { createAdminSchedeAjax } from "redux/actions";

// LIBS
import immutable from "immutable";
import moment from "moment";

// STYLE
import styles from "./style.js";
import cn from "classnames";

function AdminCreaScheda({ classes, t }) {
  const restUri = useSelector(getRestUri);
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const populateInitial = () => {
    const scheda = {
      titolo_prestazione: null,
      dominio_geografico: isSuperAdmin ? "n" : "c",
      dipende_da_condizione_economica: "s",
      bisogno_prioritario: [],
      area_di_utenza: [],
      iseeFilters: [],
      ageMembers: [],
      scheda_utente: EditorState.createEmpty(),
      campo_locale: EditorState.createEmpty(),
      minori: [0, Number.MAX_SAFE_INTEGER],
      num_componenti_nucleo: [0, Number.MAX_SAFE_INTEGER],
      indicazione_geografica: null,
      publicato: false,
      permesso_lungo_periodo: false,
      inizio_richiesta: moment().format("YYYY-MM-DD"),
      fine_richiesta: moment().add(1, "year").format("YYYY-MM-DD"),
      senza_scadenza: false,
    };
    // eslint-disable-next-line array-callback-return
    chapters.map((chapter) => {
      scheda[chapter.textId] = EditorState.createEmpty();
    });
    return immutable.fromJS(scheda);
  };
  const onSubmitForm = () => {
    const parsedData = {};
    // eslint-disable-next-line no-use-before-define
    data &&
      // eslint-disable-next-line no-use-before-define,array-callback-return
      Object.entries(data.toJS()).map(([key, value]) => {
        if (
          key.indexOf("capitolo") !== -1 ||
          key.indexOf("scheda_utente") !== -1 ||
          key.indexOf("campo_locale") !== -1
        ) {
          // eslint-disable-next-line no-use-before-define
          parsedData[key] = draftToHtml(
            convertToRaw(data.get(key).getCurrentContent()),
          );
          if (parsedData[key] === "<p></p>\n") {
            parsedData[key] = "";
          }
        } else if (
          key.indexOf("inizio_richiesta") !== -1 ||
          key.indexOf("fine_richiesta") !== -1
        ) {
          // eslint-disable-next-line no-use-before-define
          parsedData[key] = moment(data.get(key)).format("DD/MM/YYYY");
        } else {
          parsedData[key] = value;
        }
      });
    // eslint-disable-next-line no-use-before-define
    dispatch(createAdminSchedeAjax(restUri, parsedData));
  };
  const {
    data,
    setInitial,
    onChange,
    onChangeEvent,
    isValid,
    validations,
    onSubmit,
    onResetArray,
  } = useForm({
    initial: populateInitial(),
    joiValidationSchema: editingForm,
    onSubmit: () => onSubmitForm(),
  });

  useEffect(() => {
    setInitial(populateInitial());
  }, []);

  const [showPanels, setShowPanels] = useState(false);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <form onSubmit={onSubmit}>
        <Paper className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <BackButton
                onClick={() => window.history.back()}
                text="Torna alla lista schede"
              />
            </Grid>
          </Grid>
          {data && (
            <EditorScheda
              scheda={data}
              onChange={onChange}
              onResetArray={onResetArray}
              onChangeEvent={onChangeEvent}
              validations={validations}
              showPanels={showPanels}
              setShowPanels={setShowPanels}
            />
          )}
          {
            !showPanels && (
              <Button
                color="primary"
                className={classes.MT20}
                disabled={!isValid}
                type="submit"
                variant="contained"
              >
                CREA
              </Button>
          )}
        </Paper>
      </form>
    </div>
  );
}

AdminCreaScheda.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminCreaScheda));
