import {
  createMuiTheme,
} from '@material-ui/core/styles'
import _ from 'lodash'

const themeBase = {
  palette: {
    type: 'light',
    primary: {
      main: '#5378B2',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Jost*", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 1.5,
      '@media print': {
        fontSize: 24,
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      '@media print': {
        fontSize: 20,
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 21,
    },
    h4: {
      fontWeight: 600,
      fontSize: 17,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 16,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 12,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Arial, sans-serif',
      fontWeight: 400,
      fontSize: '16px !important',
      lineHeight: 1.5,
      wordWrap: 'break-word',
      textAlign: 'justify',
      '@media print': {
        fontSize: '.9em',
        pageBreakInside: 'avoid',
      },
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
    },
    caption: {
      fontWeight: 600,
      fontSize: 15,
    },
  },
}

export default createMuiTheme(themeBase)

export const common = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      secondary: {
        main: '#8B8A9D',
      },
    },
  }),
)

export const alternative = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      secondary: {
        main: '#E39257',
        contrastText: '#fff',
      },
    },
  }),
)

export const error = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      secondary: {
        main: '#E35757',
        contrastText: '#fff',
      },
    },
  }),
)

export const dark = createMuiTheme(
  _.merge({}, themeBase, {
    palette: {
      type: 'dark',
      textPrimary: '#fff',
    },
  }),
)
