import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      circle: {
        display: "none",
        "@media (min-width: 600px)": {
          height: 46,
          width: 46,
          borderRadius: 30,
          position: "absolute",
          transform: "translateX(-85%)",
          backgroundColor: "#5378B2",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        },
        "@media print": {
          display: "none",
        },
      },
      recentSchedeContainer: {
        maxHeight: 500,
        overflow: "scroll",
      },
    },
    sg,
  );
