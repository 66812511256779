import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    container: {
      width: '100%',
      borderRadius: 10,
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: 26,
      position: 'relative',
      height: 200,
      alignItems: 'flex-end',
      borderRadius: 10,
      '@media (max-width: 600px)': {
        height: 135,
        marginTop: 65,
      },
    },
    text: {
      display: 'block',
      textAlign: 'center',
    },
  },
  sg,
)
