/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// REACT
import React, { Fragment, useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

import TextField from "components/text-field";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  SearchOutlined,
} from "@material-ui/icons";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getFilters,
  getRestUri,
  getResults,
  getUsers,
} from "redux/getters";
import { getAdminUsersAjax } from "redux/actions";

// LIBS
import cn from "classnames";

// STYLE
import CustomButton from "components/button";
import FilterIcon from "components/filter-icon";
import styles from "./style.js";

function AdminUsersList({ classes, t }) {
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const [textFilter, setTextFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const results = useSelector(getResults);
  const filters = useSelector(getFilters);
  const [alphabet, setAlphabet] = useState({});
  const users = useSelector(getUsers);

  useEffect(() => {
    dispatch(getAdminUsersAjax(restUri));
  }, []);

  useEffect(() => {
    const temp = {};
    const filteredUsersTemp = users.filter((user, index) =>
      user.email
        .toLowerCase()
        .match(new RegExp(`.*${textFilter.toLowerCase()}.*`)),
    );
    setFilteredUsers(filteredUsersTemp);
    filteredUsersTemp.forEach((user, index) => {
      if (temp[user.email[0].toUpperCase()] === undefined) {
        temp[user.email[0].toUpperCase()] = index;
      }
    });
    setAlphabet(temp);
  }, [users, textFilter]);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <div className={classes.title}>
        <Typography variant="h2">Lista utenti</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textFieldInput}
            placeholder="Scrivi qui il nome dell'utente da cercare"
            onChange={(event) => setTextFilter(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlined className={classes.searchOutlined} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid className={classes.buttonContainer} item xs={12} sm={6}>
          <CustomButton
            className={classes.button}
            color="primary"
            variant="contained"
            href="#/admin/crea-utente"
          >
            <Typography variant="caption" className={classes.filterLabel}>
              Crea utente
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>
      <Paper
        classes={{
          root: cn(classes.paper),
        }}
      >
        <List
          dense
          classes={{
            root: classes.list,
          }}
        >
          {filteredUsers.map((user, index) => {
            const labelId = `checkbox-list-secondary-label-${index + 1}`;
            return (
              <Fragment>
                {index !== 0 && (
                  <Divider
                    classes={{
                      root: classes.divider,
                    }}
                  />
                )}
                <ListItem
                  classes={{
                    root: classes.listItem,
                  }}
                  role={undefined}
                  dense
                  button
                  disableRipple
                  component="a"
                  href={`#/admin/modifica-utente/${user._id}`}
                >
                  {Object.values(alphabet).indexOf(index) !== -1 && (
                    <div className={classes.circle}>
                      <Typography>{user.email[0].toUpperCase()}</Typography>
                    </div>
                  )}
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                    }}
                    id={labelId}
                  >
                    <Typography
                      classes={{
                        root: classes.listItemTextTypography,
                      }}
                    >
                      {`${user.email}`}
                    </Typography>
                  </ListItemText>
                  {
                    <ChevronRight
                      iconstyle={classes.chevronRight.path}
                      classes={{
                        root: classes.chevronRight,
                      }}
                    />
                  }
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      </Paper>
    </div>
  );
}

AdminUsersList.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminUsersList));
