// ASSETS
import screenshot01 from "assets/img/screenshot_01.png";
import screenshot02 from "assets/img/screenshot_02.png";
import screenshot03 from "assets/img/screenshot_03.png";
import screenshot04 from "assets/img/screenshot_04.png";
import screenshot05 from "assets/img/screenshot_05.png";
import screenshot06 from "assets/img/screenshot_06.png";
import screenshot07 from "assets/img/screenshot_07.png";
// CONSTANT

// REACT
import React, { Fragment } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Grid,
  MuiThemeProvider,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

// REDUX
import { useSelector } from "react-redux";

// LIBS
import cn from "classnames";

// STYLE
import { dark } from "themes/index";
import { getMe } from "redux/getters";
import styles from "./style.js";

function HowItWorks({ classes, t }) {
  const user = useSelector(getMe);

  return (
    <Fragment>
      <MuiThemeProvider theme={dark}>
        <div className={classes.cover}>
          <div
            className={cn(
              classes.wrapper,
              classes.coverContent,
              classes.wrapperWidthLimiter,
            )}
          >
            <Typography color="textPrimary" variant="h2">
              Come funziona
            </Typography>
          </div>
        </div>
      </MuiThemeProvider>
      <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
        <Grid className={classes.container} container spacing={3}>
          <Paper className={classes.paper}>
            <div className={classes.content}>
              <div className={classes.titleContainer}>
                <Typography className={classes.title} variant="body1">
                  1.
                </Typography>
                &nbsp;
                <Typography className={classes.title} variant="body1">
                  Seleziona se profilare un nucleo familiare o cercare
                  direttamente una prestazione
                </Typography>
              </div>
              <img className={classes.image} src={screenshot01} alt="step" />
            </div>
            <div className={classes.content}>
              <div className={classes.titleContainer}>
                <Typography className={classes.title} variant="body1">
                  2.
                </Typography>
                &nbsp;
                <Typography className={classes.title} variant="body1">
                  Raffina i risultati coi filtri a disposizione
                </Typography>
              </div>
              <img className={classes.image} src={screenshot02} alt="step" />
            </div>
            <div className={classes.content}>
              <div className={classes.titleContainer}>
                <Typography className={classes.title} variant="body1">
                  3.
                </Typography>
                &nbsp;
                <Typography className={classes.title} variant="body1">
                  Puoi selezionare una o più schede e stamparle direttamente per
                  l’utente, stampare l’elenco dei titoli per te o se non hai la
                  stampante puoi inviare il link di lettura di una scheda via
                  email
                </Typography>
              </div>
              <img className={classes.image} src={screenshot03} alt="step" />
            </div>
            {user ? (
              <Fragment>
                <div className={classes.content}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="body1">
                      4.
                    </Typography>
                    &nbsp;
                    <Typography className={classes.title} variant="body1">
                      Consulta la scheda trovata e stampa la versione sintetica
                      per l’utente o quella estesa per te, oppure se non hai la
                      stampante puoi inviare il link di lettura via email
                    </Typography>
                  </div>
                  <img
                    className={classes.image}
                    src={screenshot04}
                    alt="step"
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="body1">
                      5.
                    </Typography>
                    &nbsp;
                    <Typography className={classes.title} variant="body1">
                      {'In modalità "Versione per i Servizi" clicca su "Indice dei contenuti"' +
                        "per navigare velocemente tra le diverse sezioni della scheda"}
                    </Typography>
                  </div>
                  <img
                    className={classes.image}
                    src={screenshot05}
                    alt="step"
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="body1">
                      6.
                    </Typography>
                    &nbsp;
                    <Typography className={classes.title} variant="body1">
                      {
                        "Se di fianco ad una prestazione è presente un punto indicativo rosso vuol dire che la data per la richiesta della stessa è stata superata"
                      }
                    </Typography>
                  </div>
                  <img
                    className={classes.image}
                    src={screenshot06}
                    alt="step"
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className={classes.content}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="body1">
                      4.
                    </Typography>
                    &nbsp;
                    <Typography className={classes.title} variant="body1">
                      Consulta la scheda trovata e stampa la versione sintetica
                      oppure se non hai la stampante puoi inviare il link di
                      lettura via email
                    </Typography>
                  </div>
                  <img
                    className={classes.image}
                    src={screenshot07}
                    alt="step"
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="body1">
                      5.
                    </Typography>
                    &nbsp;
                    <Typography className={classes.title} variant="body1">
                      {
                        "Se di fianco ad una prestazione è presente un punto indicativo rosso vuol dire che la data per la richiesta della stessa è stata superata"
                      }
                    </Typography>
                  </div>
                  <img
                    className={classes.image}
                    src={screenshot06}
                    alt="step"
                  />
                </div>
              </Fragment>
            )}
          </Paper>
        </Grid>
      </div>
    </Fragment>
  );
}

HowItWorks.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(HowItWorks));
