import { createSelector } from "reselect";

export const getFilters = (state) => state.getIn(["schede", "filters"]);
export const getResults = (state) => state.getIn(["schede", "results"]);
export const getScheda = (state) => state.getIn(["schede", "item"]);
export const getEditingScheda = (state) =>
  state.getIn(["schede", "editingItem"]);
export const getSchede = createSelector(
  [(state) => state.getIn(["schede", "list"])],
  (schede) => schede.toJS(),
);
export const getSchedeRecenti = (state) => state.getIn(["schede", "recenti"]).toJS();
export const getTotal = (state) => state.getIn(["schede", "total"]);
export const getIsDeleteSchedaPopoverConfirmButtonEnabled = (state) =>
  state.getIn(["schede", "isDeleteSchedaPopoverConfirmButtonEnabled"]);
export const getIsDeleteSchedaPopoverOpen = (state) =>
  state.getIn(["schede", "isDeleteSchedaPopoverOpen"]);
