import {
  GET_COMUNI_AJAX_SUCCESS,
  RESET_FILTER,
  RESET_COMUNI,
} from 'redux/action-types'
import immutable from 'immutable'
import initialState from '../../initial-states/comuni'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMUNI_AJAX_SUCCESS:
      state = state.set('list', immutable.fromJS(action.data.comuni))
      return state
    case RESET_FILTER:
      state = state.set('list', initialState.get('list'))
      return state
    case RESET_COMUNI:
      state = state.set('list', immutable.fromJS(initialState.get('list')))
      return state
    default:
      return state
  }
}
