/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// CONSTANTS
import chapters from "constants/chapters";

// REACT
import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  memo,
  useMemo,
  useCallback,
} from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Button,
  MuiThemeProvider,
  MenuList,
  Grid,
  ClickAwayListener,
  Popper,
  MenuItem,
  TextField,
  Paper,
  Select,
  Switch,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Editor } from "react-draft-wysiwyg";
import MultipleSelect from "components/multiple-select";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FiltroIsee from "scenes/admin/components/filtro-isee";
import immutable from "immutable";
import FiltroComponenti from "scenes/admin/components/filtro-componenti";
import FormComponent from "components/form-component";
import Form from "components/form";

// REDUX
import { getUserComune } from "redux/getters";
import { useDispatch, useSelector } from "react-redux";
import {
  getAjaxLoading,
  getComuni,
  getIsSuperAdmin,
  getMe,
  getRestUri,
  getTagsList,
} from "redux/getters";
import { GET_SCHEDE_BY__ID_AJAX_SUCCESS } from "redux/action-types";

// LIBS
import cn from "classnames";
import joi from "joi-browser";
import moment from "moment";
// STYLE
import { alternative, common } from "themes/index";
import CustomTextField from "components/text-field";
import {
  getAdminTagsAjax,
  getComuniAjax,
  getComuniAjaxCancel,
  setDeleteSchedaPopoverOpen,
} from "redux/actions";
import { validationStates } from "components/use-form";
import styles from "./style.js";
import { Delete } from "@material-ui/icons";
import { DeleteSchedaPopover } from "../delete-scheda-popover";
import {
  editorToolbarConfig,
  rteTranslations,
} from "../../../../constants/editor-config";

export const editingForm = joi
  .object()
  .keys({
    titolo_prestazione: joi.string().min(3).required(),
    dominio_geografico: joi.string().valid(["n", "r", "c"]).required(),
    indicazione_geografica: joi.allow(null).optional(),
    dipende_da_condizione_economica: joi.string().valid(["s", "n"]).required(),
    bisogno_prioritario: joi
      .array()
      .items(joi.string().valid(["br", "ab", "la", "tr", "di"]))
      .required(),
    area_di_utenza: joi
      .array()
      .items(joi.string().valid(["mi", "ad", "di", "an", "do"]))
      .required(),
    minori: joi.array().items(joi.number().integer()),
    num_componenti_nucleo: joi.array().items(joi.number().integer()).max(2),
    inizio_richiesta: joi.date().allow("", null).optional(),
    fine_richiesta: joi.date().allow("", null).optional(),
    senza_scadenza: joi.boolean().required().default(false),
    iseeFilters: joi
      .array()
      .items(
        joi
          .alternatives()
          .try(
            joi.object().keys({
              iseeOp: joi.string().optional().valid([null]),
              isee: joi.any().valid([null, ""]),
            }),
            joi.object().keys({
              iseeOp: joi.string().valid(["<", "<=", ">", ">=", "=="]),
              isee: joi.number().required(),
            }),
          )
          .optional(),
      )
      .optional(),
    ageMembers: joi
      .array()
      .items(
        joi
          .alternatives()
          .try(
            joi.object().keys({
              ageOp: joi.string().optional().valid([null]),
              age: joi.any().valid([null, ""]),
              members: joi.any().valid([null, ""]),
            }),
            joi.object().keys({
              ageOp: joi.string().valid(["<", "<=", ">", ">=", "=="]),
              age: joi.number().required(),
              members: joi.number().required(),
            }),
          )
          .optional(),
      )
      .optional(),
    publicato: joi.bool().required(),
  })
  .unknown(true)
  .required();

function EditorScheda({
  classes,
  onChange,
  onChangeEvent,
  onResetArray,
  scheda,
  validations,
  showPanels,
  setShowPanels,
}) {
  const chaptersRefs = chapters.map(() => useRef());

  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const user = useSelector(getMe);
  const adminComune = useSelector(getUserComune);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const tagsList = useSelector(getTagsList);
  const comuni = useSelector(getComuni);
  const [comuneText, setComuneText] = useState(
    scheda.get("comuneId") ? scheda.getIn(["comune", "comune"]) : "",
  );
  if (scheda.get("comuneId")) {
    onChange("comuneId", scheda.get("comuneId"));
  }

  const [selected, setSelected] = useState(!!scheda.get("comuneId"));
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const [activePanelId, setActivePanelId] = useState(null);

  const tagsOptions = useMemo(
    () =>
      tagsList
        .filter((tag) => tag.abilitato)
        .reduce((options, tag) => {
          options[tag._id] = tag.name;

          return options;
        }, {}),
    [tagsList],
  );

  const onDeleteSchedaButtonClick = useCallback(() => {
    dispatch(setDeleteSchedaPopoverOpen(true));
  }, [dispatch]);
  const closeDeleteSchedaPopover = useCallback(() => {
    dispatch(setDeleteSchedaPopoverOpen(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getComuniAjaxCancel());
    if (comuneText !== "" && !selected) {
      dispatch(
        getComuniAjax(restUri, {
          query: {
            comune: comuneText,
          },
        }),
      );
    } else {
      setMenuOpen(false);
      setSelected(false);
    }
  }, [comuneText, dispatch]);

  useEffect(() => {
    dispatch(getAdminTagsAjax(restUri));
  }, [dispatch]);

  useEffect(() => {
    if (comuni.length && comuneText !== "" && !selected) {
      setMenuOpen(true);
    }
  }, [comuni]);

  const dominioGeograficoFields = useMemo(
    () => (
      <Fragment>
        <option value="n">Nazionali</option>
        <option value="r">Regionali</option>
        <option value="c">Comunali</option>
      </Fragment>
    ),
    [],
  );

  const renderFiltroIsee = (data) => {
    const arr = [0, 1];
    return (
      <div>
        <Typography variant="h2" className={classes.label}>
          Filtro ISEE
        </Typography>
        {arr.map((i) => (
          <FiltroIsee
            key={i}
            data={
              data.get(i) ||
              immutable.fromJS({
                iseeOp: null,
                isee: null,
              })
            }
            validations={validations.iseeFilters}
            name="iseeFilters"
            index={i}
            onChange={onChange}
            onResetArray={onResetArray}
          />
        ))}
      </div>
    );
  };

  const renderAgeMembers = (data) => {
    const arr = [0, 1, 2, 3, 4];
    return (
      <div>
        <Typography variant="h2" className={classes.label}>
          Età componenti
        </Typography>
        {arr.map((i) => (
          <FiltroComponenti
            key={i}
            data={
              data.get(i) ||
              immutable.fromJS({
                members: null,
                ageOp: null,
                age: null,
              })
            }
            validations={validations.ageMembers}
            name="ageMembers"
            index={i}
            onChange={onChange}
          />
        ))}
      </div>
    );
  };

  const canDelete = useMemo(() => {
    const schedaJS = scheda.toJS();
    const userJS = user.toJS();

    return (
      userJS.role === "SUPERADMIN" ||
      (schedaJS &&
        schedaJS.createdByUser &&
        schedaJS.createdByUser._id === userJS._id)
    );
  }, [scheda, user]);

  const [editorState, setEditorState] = useState("");

  return (
    <Grid item xs={12}>
      <div className={classes.info}>
        {scheda.get("createdByUser") && (
          <Typography variant="subtitle1">
            Creato da: {scheda.getIn(["createdByUser", "email"])} in data{" "}
            {moment(scheda.get("created")).format("DD-MM-YYYY")}
          </Typography>
        )}
        {scheda.get("lastUpdatedByUser") && (
          <Typography variant="subtitle1">
            Ultima modifica effettuata da:{" "}
            {scheda.getIn(["lastUpdatedByUser", "email"])} in data{" "}
            {moment(scheda.get("updated")).format("DD/MM/YYYY")}
          </Typography>
        )}
      </div>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <FormControlLabel
            className={cn(classes.formControlLabel, classes.MT20)}
            control={
              <Switch
                checked={scheda.get("publicato")}
                onChange={() => onChange("publicato", !scheda.get("publicato"))}
                value={scheda.get("publicato")}
                name="publicato"
              />
            }
            label={
              scheda.get("publicato")
                ? "La scheda è publicata e visibile online"
                : "La scheda non sarà visibile online"
            }
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={cn(
              classes.formControlLabel,
              classes.MT20,
              classes.deleteButton,
            )}
            onClick={onDeleteSchedaButtonClick}
            disabled={!canDelete}
          >
            <Delete style={{ marginRight: 5 }} /> Elimina scheda
          </Button>
        </Grid>
      </Grid>
      {!showPanels ? (
        <Fragment>
          {!showPanels && (
            <MuiThemeProvider theme={alternative}>
              <Button
                className={classes.MT20}
                color="secondary"
                onClick={() => setShowPanels(true)}
              >
                MOSTRA CAPITOLI
              </Button>
            </MuiThemeProvider>
          )}
          <div className={classes.column}>
            <Typography variant="h2" className={classes.label}>
              Titolo prestazione
            </Typography>
            <FormComponent
              className={classes.input}
              Component={TextField}
              data={scheda}
              field="titolo_prestazione"
              fullWidth
              onChange={onChangeEvent}
              validations={validations}
              variant="outlined"
            />
            {Object.values(tagsOptions).length > 0 && (
              <MultipleSelect
                className={classes.formControl}
                name="tags"
                checkboxColor="primary"
                label="Tags"
                selectedValues={(
                  scheda.get("tags") || immutable.fromJS([])
                ).toJS()}
                setSelectedValues={(value) => onChange("tags", value)}
                options={tagsOptions}
              />
            )}
            <FormControlLabel
              className={cn(classes.formControlLabel, classes.MT20)}
              control={
                <Switch
                  checked={scheda.get("permesso_lungo_periodo")}
                  onChange={() =>
                    onChange(
                      "permesso_lungo_periodo",
                      !scheda.get("permesso_lungo_periodo"),
                    )
                  }
                  value={scheda.get("permesso_lungo_periodo")}
                  name="permesso_lungo_periodo"
                />
              }
              label="Selezionare se la prestazione può essere erogata quando il richiedente, se è uno straniero extra UE, deve avere un permesso di soggiorno di lungo periodo"
            />
            <Typography variant="h2" className={classes.label}>
              Dominio geografico
            </Typography>
            <FormComponent
              Component={Select}
              data={scheda}
              native
              field="dominio_geografico"
              validations={validations}
              label="Dominio geografico"
              name="dominio_geografico"
              onChange={onChangeEvent}
              disabled={!isSuperAdmin}
            >
              {dominioGeograficoFields}
            </FormComponent>
            {!isSuperAdmin && (
              <>
                <Typography variant="h2" className={classes.label}>
                  Comune
                </Typography>
                <TextField
                  label={adminComune.get("comune")}
                  disabled
                  defaultValue={adminComune.get("_id")}
                />
              </>
            )}
            {scheda.get("dominio_geografico") !== "n" && isSuperAdmin && (
              <MuiThemeProvider theme={common}>
                <CustomTextField
                  classes={{
                    notchedOutline: classes.textfield,
                    input: classes.inputAutoComplete,
                    formControl: classes.customTextField,
                  }}
                  label="Comune di erogazione del servizio"
                  placeholder="Inizia a scrivere il comune e selezionalo dalla lista"
                  onChange={(ev) => {
                    setComuneText(ev.target.value);
                    setAnchor(ev.currentTarget);
                  }}
                  error={comuneText === ""}
                  value={comuneText}
                  helperText={
                    comuneText === ""
                      ? "Inserire il comune di riferimento"
                      : undefined
                  }
                />
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                  <Popper
                    anchorEl={anchor}
                    className={classes.menuPopover}
                    open={menuOpen}
                    transition
                    disablePortal
                    placement="bottom-start"
                  >
                    <Paper className={classes.menuPaper}>
                      <MenuList>
                        {comuni.map((c) => (
                          <MenuItem
                            key={c.id}
                            onClick={() => {
                              setMenuOpen(false);
                              setComuneText(c.comune);
                              setSelected(true);
                              onChange("comuneId", c.id);
                            }}
                          >
                            {c.comune}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Paper>
                  </Popper>
                </ClickAwayListener>
              </MuiThemeProvider>
            )}
            <Typography variant="h2" className={classes.label}>
              Dipende da condizione economica
            </Typography>
            <Select
              label="Dipende da condizione economica"
              name="dipende_da_condizione_economica"
              value={scheda.get("dipende_da_condizione_economica")}
              onChange={onChangeEvent}
              error={
                validations.dipende_da_condizione_economica.state ===
                Form.validationStates.WRONG
              }
            >
              <MenuItem value="s">Sì</MenuItem>
              <MenuItem value="n">No</MenuItem>
            </Select>
            <Typography variant="h2" className={classes.label}>
              Utenza
            </Typography>
            <div className={classes.multiple}>
              <MultipleSelect
                className={classes.formControl}
                name="bisogno_prioritario"
                checkboxColor="primary"
                label="Bisogno affrontato"
                selectedValues={scheda.get("bisogno_prioritario").toJS()}
                setSelectedValues={(value) =>
                  onChange("bisogno_prioritario", value)
                }
                options={{
                  br: "Basso reddito",
                  ab: "Abitazione",
                  la: "Lavoro",
                  tr: "Trasporti",
                  di: "Disabilità / Non autosufficienza",
                }}
                error={
                  validations.bisogno_prioritario.state ===
                  Form.validationStates.WRONG
                }
              />
              <MultipleSelect
                className={classes.formControl}
                name="area_di_utenza"
                checkboxColor="primary"
                label="Area di utenza"
                selectedValues={scheda.get("area_di_utenza").toJS()}
                setSelectedValues={(value) => onChange("area_di_utenza", value)}
                options={{
                  mi: "Minori",
                  ad: "Adulti",
                  di: "Disabili / Non autosufficienti",
                  an: "Anziani",
                  do: "Donne",
                }}
                error={
                  validations.area_di_utenza.state ===
                  Form.validationStates.WRONG
                }
              />
            </div>
            {/*<ExpansionPanel
              TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
              }}
              className={classes.label}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h2">Campo locale</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Editor
                  editorState={scheda.get("campo_locale") || ""}
                  onEditorStateChange={(editorState) =>
                    onChange("campo_locale", editorState)
                  }
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>*/}
            {/*
            <NumeroComponenti
              name="minori"
              label="Minori"
              data={scheda.get("minori")}
              validations={validations.minori}
              onChange={onChange}
            />
            <NumeroComponenti
              name="num_componenti_nucleo"
              label="Numero componenti nucleo"
              data={scheda.get("num_componenti_nucleo")}
              validations={validations.num_componenti_nucleo}
              onChange={onChange}
            />
               */}
            <div className={cn(classes.multiple, classes.jsI)}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.marginRight}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Inizio richiesta"
                  value={scheda.get("inizio_richiesta")}
                  onChange={(value) => onChange("inizio_richiesta", value)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

                {scheda.get("senza_scadenza") ? (
                  <div />
                ) : (
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Fine richiesta"
                    value={scheda.get("fine_richiesta")}
                    onChange={(value) => {
                      onChange("fine_richiesta", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                )}
                <FormControlLabel
                  className={cn(classes.formControlLabel, classes.MT20)}
                  control={
                    <Switch
                      checked={scheda.get("senza_scadenza")}
                      onChange={() =>
                        onChange(
                          "senza_scadenza",
                          !scheda.get("senza_scadenza"),
                        )
                      }
                      value={scheda.get("senza_scadenza")}
                      name="senza_scadenza"
                    />
                  }
                  label={
                    scheda.get("senza_scadenza")
                      ? "La scheda non ha scadenza"
                      : "La scheda ha una scadenza"
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            {renderFiltroIsee(scheda.get("iseeFilters"))}
            {renderAgeMembers(scheda.get("ageMembers"))}
          </div>
        </Fragment>
      ) : (
        <Button
          color="secondary"
          className={classes.MT20}
          onClick={() => {
            console.log("activePanelId", activePanelId, editorState);
            onChange(activePanelId, editorState);
            setShowPanels(false);
            setActivePanelId(null);
          }}
          variant="contained"
        >
          CHIUDI CAPITOLI
        </Button>
      )}

      {activePanelId !== null ? (
        <Button
          color="secondary"
          className={cn(classes.MT20, classes.ML20)}
          onClick={() => {
            onChange(activePanelId, editorState);
            setActivePanelId(null);
          }}
          variant="contained"
        >
          TORNA A TUTTI I CAPITOLI
        </Button>
      ) : null}
      <div className={classes.columnChapters}>
        {showPanels &&
          [
            {
              textId: "scheda_utente",
              title: "Scheda utente",
              level: 0,
              number: "0",
            },
            ...chapters,
          ].map((chapter, index) => {
            if (activePanelId !== null && activePanelId !== chapter.textId) {
              return null;
            }
            return (
              <ExpansionPanel
                TransitionProps={{
                  mountOnEnter: true,
                  unmountOnExit: true,
                }}
                className={classes.label}
                key={chapter.textId}
                expanded={activePanelId === chapter.textId}
                onChange={(ev, expanded) => {
                  setActivePanelId(expanded ? chapter.textId : null);
                  if (expanded) {
                    setEditorState(scheda.get(chapter.textId) || "");
                  } else {
                    console.log("onChange", chapter.textId, editorState);
                    onChange(chapter.textId, editorState);
                  }
                }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    className={
                      chapter.level === 0
                        ? classes.title
                        : cn(classes.title, classes.subtitle)
                    }
                    variant="h2"
                    ref={chaptersRefs[index]}
                  >
                    {`${chapter.number}. ${chapter.title}`}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={cn(classes.panel, classes.rteContainer)}
                >
                  {activePanelId === chapter.textId ? (
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={(editorState) =>
                        setEditorState(editorState)
                      }
                      toolbar={editorToolbarConfig}
                      localization={{
                        locale: "en",
                        translations: rteTranslations,
                      }}
                    />
                  ) : null}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
      </div>
      {scheda && scheda.get("_id") && (
        <DeleteSchedaPopover
          idScheda={scheda.get("_id")}
          onClose={closeDeleteSchedaPopover}
        />
      )}
    </Grid>
  );
}

EditorScheda.propTypes = {
  classes: shape({}).isRequired,
  onChange: func.isRequired,
  onChangeEvent: func.isRequired,
  scheda: shape({}).isRequired,
  validations: shape({}).isRequired,
};

export default withStyles(styles)(translate()(memo(EditorScheda)));
