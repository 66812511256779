// REACT
import React, { useRef } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { withStyles, Paper } from "@material-ui/core";
import { useForm } from "components/use-form";
import EditorUser, {
  createUser,
} from "scenes/admin/components/editor-user";
import Button from "components/button";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { getRestUri, getMe, getIsSuperAdmin } from "redux/getters";
import { createAdminUsersAjax } from "redux/actions";

// LIBS
import cn from "classnames";
import joi from "joi-browser";
import immutable from "immutable";

// STYLE
import styles from "./style.js";

function AdminCreaUser({ classes, t }) {
  const user = useSelector(getMe);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);

  const initial = useRef(
    immutable.fromJS({
      email: "",
      password: "",
      confirmPassword: "",
      comuneId: isSuperAdmin ? 0 : user && user.getIn(["comune", "id"]),
      role: "OPERATORE",
      attivo: true,
    }),
  );

  const { data, onChange, onChangeEvent, isValid, validations, onSubmit } =
    useForm({
      initial: initial.current,
      joiValidationSchema: createUser,
      onSubmit: () => {
        const payload = data.toJS();
        if (payload.role === "SUPERADMIN") {
          delete payload.comuneId;
        }
        delete payload.confirmPassword;
        dispatch(createAdminUsersAjax(restUri, payload));
      },
    });

  return (
    <div className={classes.wrapperWidthLimiter}>
      <Paper
        classes={{
          root: cn(classes.paper),
        }}
      >
        <form onSubmit={onSubmit}>
          <EditorUser
            isSuperAdmin={isSuperAdmin}
            user={data}
            onChange={onChange}
            onChangeEvent={onChangeEvent}
            validations={validations}
          />
          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Conferma
          </Button>
        </form>
      </Paper>
    </div>
  );
}

AdminCreaUser.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminCreaUser));
