// LIBS
import immutable from 'immutable'

export default immutable.fromJS({
  isLogged: null,
  me: null,
  list: [],
  filters: {
    comune: null,
    comuneLabel: '',
  },
  item: null,
})
