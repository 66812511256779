/* eslint-disable camelcase */
import {
  SET_FILTER,
  RESET_FILTER,
  SET_DELETE_SCHEDE_POPOVER_OPEN,
} from "redux/action-types/schede";
import { getSchedeAjax } from "redux/actions/ajax";

export const setFilter = (param, value) => ({
  type: SET_FILTER,
  param,
  value,
});

export const resetFilter = () => ({
  type: RESET_FILTER,
});

export const findSchede = (restUri, filters, isCatalogo) => {
  let bisogni = filters.get("bisogni").toJS();
  if (bisogni.length === 0) {
    bisogni = ["br", "ab", "la", "tr", "di"];
  }
  const bisogno_prioritario = bisogni.length === 0 ? ["null"] : bisogni;
  let utenza = filters.get("utenza").toJS();
  if (utenza.length === 0) {
    utenza = ["mi", "ad", "di", "an", "do"];
  }
  const area_di_utenza = utenza.length === 0 ? ["null"] : utenza;
  const dipende_da_condizione_economica = filters.get("condizioni");
  const dominio_geografico = filters.get("norme");
  const comune = ["r", "l", "c"].includes(dominio_geografico)
    ? filters.get("comune")
    : undefined;
  const members = isCatalogo ? [] : filters
    .get("members")
    .toJS()
    .filter((member) => member && member !== "");
  const permesso_lungo_periodo =
    isCatalogo ? null : filters.get("allEuropean") || filters.get("residencyPermit");
  const isee = isCatalogo ? null : filters.get("isee");
  const text = filters.get("text");
  const indicazione_geografica = filters.get("comune");
  const tags = filters.get("tags").toJS();

  return getSchedeAjax(restUri, {
    query: {
      comune,
      bisogno_prioritario,
      area_di_utenza,
      dipende_da_condizione_economica,
      dominio_geografico,
      members,
      permesso_lungo_periodo,
      isee,
      text,
      indicazione_geografica,
      tags,
    },
  });
};

export const setDeleteSchedaPopoverOpen = (isOpen) => ({
  type: SET_DELETE_SCHEDE_POPOVER_OPEN,
  isOpen,
});
