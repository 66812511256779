// CONSTANTS
// REACT
import React from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import HashRouter, { Route } from "react-redux-hash-router";
import { withStyles } from "@material-ui/core";
import NucleoRicercaComponenti from "scenes/nucleo/scenes/nucleo-ricerca-componenti";
import NucleoRicercaIsee from "scenes/nucleo/scenes/nucleo-ricerca-isee";
import NucleoRicercaCittadinanza from "scenes/nucleo/scenes/nucleo-ricerca-cittadinanza";
import NucleoRicercaRisultati from "scenes/nucleo/scenes/nucleo-ricerca-risultati";
import SwitchRicerca from "components/switch-ricerca";

// STYLE
import styles from "./style.js";
import cn from "classnames";

function Nucleo({ classes }) {
  return (
    <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
      <SwitchRicerca />
      <HashRouter
        routerStatePath="routing.nucleoRouter"
        loaderComponent={<div>Loading...</div>}
      >
        <Route hash="#/admin/nucleo" initAccess routeKey="componenti">
          <NucleoRicercaComponenti />
        </Route>
        <Route
          hash="#/admin/nucleo/cittadinanza"
          initAccess
          routeKey="cittadinanza"
        >
          <NucleoRicercaCittadinanza />
        </Route>
        <Route hash="#/admin/nucleo/isee" initAccess routeKey="isee">
          <NucleoRicercaIsee />
        </Route>
        <Route hash="#/admin/nucleo/risultati" initAccess routeKey="risultati">
          <NucleoRicercaRisultati />
        </Route>
      </HashRouter>
    </div>
  );
}

Nucleo.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Nucleo));
