/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// REACT
import React, { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Button, Grid, Paper, withStyles } from "@material-ui/core";
import BackButton from "components/back-button";
import EditorScheda, {
  editingForm,
} from "scenes/admin/components/editor-scheda";
import { useForm } from "components/use-form";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getSchedeBy_idAjax, updateAdminSchedeBy_idAjax } from "redux/actions";
import {
  getAdminRouteParams,
  getIsSuperAdmin,
  getRestUri,
  getScheda,
} from "redux/getters";
// LIBS
import immutable from "immutable";
import moment from "moment";

// STYLE
import styles from "./style.js";
import cn from "classnames";

function AdminModificaScheda({ classes, t }) {
  const restUri = useSelector(getRestUri);
  const _id = useSelector(getAdminRouteParams).get("_id");
  const scheda = useSelector(getScheda);
  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const populateInitial = () => {
    if (scheda) {
      return immutable.fromJS(
        Object.fromEntries(
          Object.entries(scheda.toJS()).map(([key, value]) => {
            if (
              key.indexOf("capitolo") !== -1 ||
              key.indexOf("scheda_utente") !== -1 ||
              key.indexOf("campo_locale") !== -1
            ) {
              const blocksFromHtml = htmlToDraft(value || "");
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap,
              );
              value = EditorState.createWithContent(contentState);
            }
            if (key === "bisogno_prioritario" || key === "area_di_utenza") {
              value = value.map((s) => s.toLowerCase());
            }
            return [key, value];
          }),
        ),
      );
    }
    return null;
  };
  const dispatch = useDispatch();

  const {
    data,
    setInitial,
    onChange,
    onChangeEvent,
    isValid,
    validations,
    onResetArray,
    onSubmit,
  } = useForm({
    initial: populateInitial(),
    joiValidationSchema: editingForm,
    onSubmit: () => {
      const parsedData = {};
      data &&
        Object.entries(data.toJS())
          .filter(([key, value]) => {
            if (key.indexOf("_comp_eta") !== -1) {
              return false;
            }
            return true;
          })
          // eslint-disable-next-line no-use-before-define,array-callback-return
          .map(([key, value]) => {
            if (
              key.indexOf("capitolo") !== -1 ||
              key.indexOf("scheda_utente") !== -1 ||
              key.indexOf("campo_locale") !== -1
            ) {
              parsedData[key] = draftToHtml(
                convertToRaw(data.get(key).getCurrentContent()),
              );
              if (parsedData[key] === "<p></p>\n") {
                parsedData[key] = "";
              }
            } else if (
              key.indexOf("inizio_richiesta") !== -1 ||
              key.indexOf("fine_richiesta") !== -1
            ) {
              parsedData[key] =
                data.get(key) !== null
                  ? moment(data.get(key)).format("DD/MM/YYYY")
                  : null;
            } else {
              parsedData[key] = value;
            }
          });
      // eslint-disable-next-line no-use-before-define
      if (!isSuperAdmin) {
        delete parsedData.dominio_geografico;
      }

      dispatch(updateAdminSchedeBy_idAjax(restUri, _id, parsedData));
    },
  });

  useEffect(() => {
    setInitial(populateInitial());
  }, [scheda]);

  useEffect(() => {
    dispatch(getSchedeBy_idAjax(restUri, _id));
  }, [_id]);

  const [showPanels, setShowPanels] = useState(false);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <form onSubmit={onSubmit}>
        <Paper className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <BackButton
                onClick={() => window.history.back()}
                text="Torna alla lista schede"
              />
            </Grid>
          </Grid>
          {data && (
            <EditorScheda
              scheda={data}
              onChange={onChange}
              onChangeEvent={onChangeEvent}
              validations={validations}
              onResetArray={onResetArray}
              showPanels={showPanels}
              setShowPanels={setShowPanels}
            />
          )}
          {
            !showPanels && (
              <Button
                color="primary"
                className={classes.MT20}
                disabled={!isValid}
                type="submit"
                variant="contained"
              >
                Salva modifiche
              </Button>
          )}
        </Paper>
      </form>
    </div>
  );
}

AdminModificaScheda.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminModificaScheda));
