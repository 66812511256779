import _ from 'lodash'
import sg from 'styles'
import background1 from 'assets/img/background1.png'

export default (theme) => _.merge(
  {
    cover: {
      height: 'auto',
      backgroundImage: `url(${background1})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      padding: '40px 0 50px',
      '@media print': {
        height: 'auto',
        background: 'none',
        padding: '20px',
        '& h1': {
          fontSize: '20px',
        }
      },
    },
    coverContent: {
      textAlign: 'center',
    },
    errorContainer: {
      '@media print': {
        display: 'none',
      },
    },
    coverTitle: {
      '@media print': {
        color: '#000 !important',
      },
    },
    errorMessage: {
      backgroundColor: theme.palette.error.main,
      alignItems: 'center',
      minHeight: 45,
      display: 'flex',
      padding: 16,
      justifyContent: 'center',
      '@media (min-width: 600px)': {
        textAlign: 'center',
      },
    },
    errorOutline: {
      display: 'none',
      '@media (min-width: 960px)': {
        display: 'block',
        color: theme.palette.error.contrastText,
        marginRight: 10,
      },
    },
    errorTitle: {
      color: theme.palette.error.contrastText,
    },
    box: {
      position: 'relative',
      padding: '40px 170px',
      marginTop: -20,
      '@media (max-width: 959px)': {
        padding: 20,
      },
      '@media print': {
        display: 'none',
      },
    },
    actionsContainerSticky: {
      top: 0,
    },
    actionsContainer: {
      position: 'relative',
      left: -20,
      '@media (min-width: 960px)': {
        position: 'absolute',
        left: 0,
        top: 0,
      },
      '@media print': {
        display: 'none',
      },
    },
    actionsSticky: {
      position: 'relative',
      '@media (min-width: 960px)': {
        position: 'fixed',
        top: 0,
      },
    },
    actions: {
      display: 'flex',
      width: '85%',
      height: 50,
      justifyContent: 'space-evenly',
      backgroundColor: 'rgba(83, 120, 178, 0.15)',
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      '@media (min-width: 960px)': {
        height: 127,
        width: 145,
        marginTop: 88,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: 'rgba(83, 120, 178, 0.15)',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 15,
      },
    },
    progress: {
      width: 320,
      maxWidth: '90%',
      margin: '0 auto',
    },
    action: {
      display: 'flex',
      margin: 'auto 0',
      '@media (min-width: 960px)': {
        margin: '10px 0',
      },
    },
    image: {
      marginRight: 10,
    },
    infoScheda: {
      '@media print': {
        display: 'none',
      },
    },
    infoSchedaContainer: {
      display: 'flex',
      margin: '10px 0',
    },
    divider: {
      width: '100%',
      '&:not(:first-child)': {
        marginTop: 15,
      },
      '&:not(:last-child)': {
        marginBottom: 15,
      },
    },
    segmentedControlRoot: {
      marginTop: 10,
      marginBottom: 10,
    },
    link: {
      display: 'flex',
      paddingTop: 25,
      paddingBottom: 20,
      '@media (min-width: 600px)': {
        paddingBottom: 0,
      },
    },
    segmentedControlContainer: {
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      padding: '5px 10px 10px',
      backgroundImage:
          'linear-gradient(white, white, white, white, white, white, white, white, transparent)',
      '@media print': {
        display: 'none',
      },
    },
    segmentedControlLabel: {
      color: theme.palette.secondary.main,
      textTransform: 'none',
      lineHeight: 1.25,
    },
    segmentedControlButtonSelected: {
      '&>span': {
        color: '#fff',
        transition: 'color ease-in .1s',
      },
    },
    listIcon: {
      marginRight: 10,
    },
    actionTypography: {
      textTransform: 'none',
    },
    buttonText: {},
    printContainer: {
      display: 'none',
      '@media print': {
        display: 'block',
        '& h2': {
          fontSize: '18px !important',
        },
        '& p': {
          fontSize: '16px !important',
        },
        '& span': {
          fontSize: '16px !important',
        }
      },
    },
    sendLink: {
      color: 'black',
    },
    popover: {
      backgroundColor: '#F2F2F2',
      padding: '5px 10px',
      marginTop: 5,
    },
  },
  sg,
)
