/* eslint camelcase: 0 */
/* eslint no-underscore-dangle: 0 */
// CONSTANTS
import chapters from "constants/chapters";

// REACT
import React, { Fragment, useEffect, useState } from "react";
import { bool, func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import FormComponent from "components/form-component";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  getAjaxLoading,
  getComuni,
  getMe,
  getRestUri,
  getUsersFilters,
} from "redux/getters";
import { GET_SCHEDE_BY__ID_AJAX_SUCCESS } from "redux/action-types";

// LIBS
import cn from "classnames";
import joi from "joi-browser";

// STYLE
import { validationStates } from "components/use-form";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { common } from "themes/index";
import CustomTextField from "components/text-field";
import {
  getComuniAjax,
  getComuniAjaxCancel,
  setUsersFilter,
  resetUsersFilters,
  resetUsersItem,
  resetComuni,
} from "redux/actions";
import styles from "./style.js";

const roles = ["OPERATORE", "REDATTORE", "SUPERADMIN"];

const createUser = joi.object().keys({
  email: joi.string().email().required(),
  password: joi.string().min(8).required(),
  confirmPassword: joi
    .string()
    .valid(joi.ref("password"))
    .required()
    .options({
      language: {
        any: {
          required: "è obbligatorio",
          allowOnly: "non coincide con la password",
        },
        string: { allowOnly: "non coincide con la password" },
      },
    }),
  role: joi
    .string()
    .valid(...roles)
    .required(),
  attivo: joi.bool().required(),
  comuneId: joi.when("role", {
    is: joi.string().valid("SUPERADMIN").required(),
    then: joi.optional(),
    otherwise: joi.number().min(1).required(),
  }),
});

const editUser = joi.object().keys({
  email: joi.string().email().required(),
  password: joi.string().min(8).optional().allow(""),
  confirmPassword: joi
    .string()
    .valid(joi.ref("password"))
    .optional()
    .options({
      language: {
        any: {
          required: "è obbligatorio",
          allowOnly: "non coincide con la password",
        },
        string: { allowOnly: "non coincide con la password" },
      },
    }),
  role: joi
    .string()
    .valid(...roles)
    .required(),
  attivo: joi.bool().required(),
  comuneId: joi.when("role", {
    is: joi.string().valid("SUPERADMIN").required(),
    then: joi.optional(),
    otherwise: joi.number().min(1).required(),
  }),
  comune: joi.string().allow("").optional(),
});

function EditorUser({
  classes,
  isSuperAdmin,
  onChange,
  onChangeEvent,
  t,
  edit,
  user,
  validations,
}) {
  const filters = useSelector(getUsersFilters);
  const [anchor, setAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const comuni = useSelector(getComuni);
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const comuneLabel = filters.get("comuneLabel") || user.get("comune");
  const [comuneText, setComuneText] = useState(comuneLabel);

  useEffect(() => {
    dispatch(getComuniAjaxCancel());

    if (comuneText !== "" && comuneText !== comuneLabel) {
      dispatch(
        getComuniAjax(restUri, {
          query: {
            comune: comuneText,
          },
        }),
      );
    } else {
      setMenuOpen(false);
    }
  }, [comuneText]);

  useEffect(() => {
    setComuneText(comuneLabel);
  }, [comuneLabel]);

  useEffect(() => {
    if (comuni.length && comuneText !== "" && isSuperAdmin) {
      setMenuOpen(true);
    }
  }, [comuni]);

  useEffect(
    () => () => {
      dispatch(resetUsersItem());
      dispatch(resetUsersFilters());
      dispatch(resetComuni());
    },
    [],
  );

  const onRoleChanged = (event) => {
    const newRole = event.target.value;
    onChange("role", newRole);
  };

  return (
    <Fragment>
      <FormComponent
        Component={TextField}
        data={user}
        field="email"
        fullWidth
        onChange={onChangeEvent}
        validations={validations}
        variant="outlined"
      />
      {/* {!edit && ( */}
      <React.Fragment>
        <FormComponent
          Component={TextField}
          data={user}
          field="password"
          fullWidth
          onChange={onChangeEvent}
          type="password"
          validations={validations}
          variant="outlined"
          label="Password"
        />
        <FormComponent
          Component={TextField}
          data={user}
          field="confirmPassword"
          fullWidth
          onChange={onChangeEvent}
          type="password"
          validations={validations}
          variant="outlined"
          label="Conferma password"
        />
      </React.Fragment>
      {/* )} */}
      {isSuperAdmin && (
        <div style={{ margin: "30px 0" }}>
          <InputLabel shrink id="role-select-label">
            Ruolo
          </InputLabel>
          <Select
            labelId="role-select-label"
            value={user.get("role")}
            onChange={onRoleChanged}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="OPERATORE">Operatore</MenuItem>
            <MenuItem value="REDATTORE">Redattore</MenuItem>
            <MenuItem value="SUPERADMIN">Super Admin</MenuItem>
          </Select>
        </div>
      )}
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Switch
            checked={user.get("attivo")}
            onChange={() => onChange("attivo", !user.get("attivo"))}
            value={user.get("attivo")}
            name="attivo"
          />
        }
        label="Utente attivo"
      />
      {isSuperAdmin && user.get("role") !== "SUPERADMIN" && (
        <MuiThemeProvider theme={common}>
          <CustomTextField
            classes={{
              notchedOutline: classes.textfield,
              input: classes.input,
            }}
            style={{ marginBottom: 30 }}
            label="Comune di residenza dell'utente"
            placeholder="Inizia a scrivere il comune e selezionalo dalla lista"
            onChange={(ev) => {
              setComuneText(ev.target.value);
              dispatch(setUsersFilter("comune", null));
              dispatch(setUsersFilter("comuneLabel", ""));
              setAnchor(ev.currentTarget);
              onChange("comuneId", 0);
            }}
            value={comuneText}
          />
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <Popper
              anchorEl={anchor}
              className={classes.menuPopover}
              open={menuOpen}
              transition
              disablePortal
              placement="bottom-start"
            >
              <Paper className={classes.menuPaper}>
                <MenuList>
                  {comuni.map((c) => (
                    <MenuItem
                      key={c.id}
                      onClick={() => {
                        setMenuOpen(false);
                        dispatch(setUsersFilter("comune", c.id));
                        dispatch(setUsersFilter("comuneLabel", c.comune));
                        setComuneText(c.comune);
                        onChange("comuneId", c.id);
                      }}
                    >
                      {c.comune}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Popper>
          </ClickAwayListener>
        </MuiThemeProvider>
      )}
    </Fragment>
  );
}

EditorUser.propTypes = {
  classes: shape({}).isRequired,
  edit: bool.isRequired,
  isSuperAdmin: bool.isRequired,
  onChange: func.isRequired,
  onChangeEvent: func.isRequired,
  t: func.isRequired,
  user: shape({}).isRequired,
  validations: shape({}).isRequired,
};

export default withStyles(styles)(translate()(EditorUser));

export { createUser, editUser };
