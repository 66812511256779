import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      content: {
        marginTop: 30,
        marginBottom: 20,
        "@media print": {
          display: "table",
          pageBreakInside: "unset",
          marginTop: 10,
        },
      },
      contentIndirizzi: {
        marginTop: 30,
        marginBottom: 20,
        "@media print": {
          display: "none",
          pageBreakInside: "unset",
          marginTop: 10,
        },
      },
      contentIndirizziPrint: {
        display: "none",
        "@media print": {
          display: "table",
          pageBreakInside: "unset",
          marginTop: 10,
        },
      },
      coverTitle: {
        "@media print": {
          color: "#000 !important",
        },
      },
      errorContainer: {
        display: "none",
        "@media print": {
          display: "block",
        },
      },
      errorMessage: {
        backgroundColor: theme.palette.error.main,
        alignItems: "center",
        minHeight: 45,
        display: "flex",
        padding: 16,
        justifyContent: "center",
        "@media (min-width: 600px)": {
          textAlign: "center",
        },
      },
      errorOutline: {
        color: "#FFF",
        display: "block",
        marginRight: 10,
      },
      errorTitle: {
        color: theme.palette.error.contrastText,
      },
      rteContainer: {
        "& span, & p, & span *, & p *": {
          fontSize: 16,
        },
        "& h1, & h1 *": {
          fontSize: 26,
        },
        "& h2, & h2 *": {
          fontSize: 22,
        },
        "& h3, & h3 *": {
          fontSize: 18,
        },
      },
    },
    sg,
  );
