import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    aggiungiComponente: {
      marginTop: 9,
      textTransform: 'none',
      padding: 0,
    },
    aggiungiComponenteIcon: {
      marginRight: 12,
    },
  },
  sg,
)
