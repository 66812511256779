// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Link,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Button from "components/button";
import { useForm, validationStates } from "components/use-form";
import FormComponent from "components/form-component";
import { createUser } from "scenes/admin/components/editor-user";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  createAdminUsersAjax,
  createUsersTokensAjax,
} from "redux/actions";
import { getRestUri } from "redux/getters";

// LIBS
import cn from "classnames";
import joi from "joi-browser";
import immutable from "immutable";

// STYLE
import styles from "./style.js";

const login = joi.object().keys({
  email: joi.string().email().required(),
  password: joi.string().min(4).required(),
});

const initial = immutable.fromJS({
  email: "",
  password: "",
});

function Login({ classes, t }) {
  const restUri = useSelector(getRestUri);
  const dispatch = useDispatch();

  const { data, onChange, onChangeEvent, isValid, validations, onSubmit } =
    useForm({
      initial,
      joiValidationSchema: login,
      onSubmit: () => dispatch(createUsersTokensAjax(restUri, data.toJS())),
    });

  return (
    <div className={classes.wrapperWidthLimiter}>
      <Paper className={classes.paper}>
        <Typography
          variant="h2"
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          Entra nell'area riservata
        </Typography>
        <form onSubmit={onSubmit}>
          <FormComponent
            Component={TextField}
            data={data}
            field="email"
            fullWidth
            onChange={onChangeEvent}
            validations={validations}
            variant="outlined"
          />
          <FormComponent
            Component={TextField}
            data={data}
            field="password"
            fullWidth
            onChange={onChangeEvent}
            type="password"
            validations={validations}
            variant="outlined"
          />
          <div style={{ marginBottom: "1em" }}>
            <Link href="#/admin/recovery-password">Password dimenticata?</Link>
          </div>
          <Button
            color="primary"
            type="submit"
            disabled={!isValid}
            variant="contained"
          >
            ACCEDI
          </Button>
        </form>
      </Paper>
    </div>
  );
}

Login.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Login));
