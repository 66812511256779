import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    box: {
      position: 'relative',
      padding: 40,
      marginTop: 40,
      '@media (max-width: 959px)': {
        padding: 20,
      },
      '@media print': {
        display: 'none',
      },
    },
    input: {
      width: '100%',
    },
    label: {
      marginTop: 30,
    },
    row: {
      width: '100%',
    },
  },
  sg,
)
