// CONSTANTS
// REACT
import React, { Fragment } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Divider, Grid, withStyles } from "@material-ui/core";
import FormContainer from "scenes/nucleo/components/form-container";
import NavigationBar from "scenes/nucleo/components/navigation-bar";
import CustomSwitch from "components/custom-switch";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import { getFilters } from "redux/getters/schede";
import { setFilter } from "redux/actions/schede";
import styles from "./style.js";
import ue from "assets/img/UE_si.svg";
import permesso from "assets/img/permesso.svg";

function NucleoRicercaCittadinanza({ classes }) {
  const dispatch = useDispatch();
  const allEuropean = useSelector(getFilters).get("allEuropean");
  const residencyPermit = useSelector(getFilters).get("residencyPermit");

  return (
    <Fragment>
      <FormContainer
        step="2"
        title="Cittadinanza"
        images={!allEuropean ? [ue, permesso] : [ue]}
      >
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={8} md={8}>
            <CustomSwitch
              checked={allEuropean}
              onChange={() => dispatch(setFilter("allEuropean", !allEuropean))}
              label="C’è almeno un componente dell’Unione Europea?"
            />
          </Grid>

          {!allEuropean && <Divider className={classes.divider} />}
          {!allEuropean && (
            <Grid item container xs={11} sm={8} md={8}>
              <CustomSwitch
                checked={residencyPermit}
                onChange={() =>
                  dispatch(setFilter("residencyPermit", !residencyPermit))
                }
                label="C'è almeno un componente con permesso di soggiorno di lungo periodo?"
              />
            </Grid>
          )}
        </Grid>
      </FormContainer>
      <NavigationBar backHref="#/admin/nucleo" nextHref="#/admin/nucleo/isee" />
    </Fragment>
  );
}

NucleoRicercaCittadinanza.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(NucleoRicercaCittadinanza));
