import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      title: {
        fontSize: 28,
      },
      subtitle: {
        fontSize: 20,
      },
      progress: {
        width: "100%",
        margin: "0 auto",
      },
      column: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      columnChapters: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& .rdw-editor-main": {
          height: 190,
          border: "1px solid rgba(0, 0, 0, 0.42)",
        },
      },
      input: {
        marginTop: 20,
      },
      formControl: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        width: "49%",
      },
      label: {
        marginTop: 20,
        marginBottom: 10,
      },
      multiple: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 20,
        width: "100%",
      },
      marginRight: {
        marginRight: 20,
      },
      menuPopover: {
        zIndex: 100,
      },
      menuPaper: {
        background: "#fff",
      },
      textfield: {
        background: "#fff",
      },
      inputAutoComplete: {
        color: "#000",
        zIndex: 1,
      },
      panel: {
        height: 300,
        overflow: "hidden",
      },
      customTextField: {
        marginTop: 20,
      },
      info: {
        marginTop: 10,
      },
      jsI: {
        justifyContent: "inherit",
      },
      formControlLabel: {
        width: "100%",
      },
      deleteButton: {
        marginTop: 20,
        backgroundColor: "#F00",
        color: "#fff",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "#e14d3d",
        },
      },
      rteContainer: {
        "& span, & p, & span *, & p *": {
          fontSize: 16,
        },
        "& h1, & h1 *": {
          fontSize: 26,
        },
        "& h2, & h2 *": {
          fontSize: 22,
        },
        "& h3, & h3 *": {
          fontSize: 18,
        },
      },
    },
    sg,
  );
