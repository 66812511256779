// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { Fragment, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { getUserLoggedPermissions } from "redux/getters/users";

// LIBS
import cn from "classnames";

// STYLE
import { ChevronRight } from "@material-ui/icons";
import styles from "./style.js";
import WidgetPraticheRecenti from "../../components/widget-pratiche-recenti";

const links = [
  {
    href: "#/admin/search",
    label: "Effettua una ricerca",
    permission: "CAN_SEARCH_SCHEDE",
  },
  {
    href: "#/admin/glossary-edit",
    label: "Modifica il glossario",
    permission: "CAN_CRUD_STATIC_CONTENTS",
  },
  {
    href: "#/admin/tags",
    label: "Gestisci i tag",
    permission: "CAN_CRUD_TAGS",
  },
  {
    href: "#/admin/schede",
    label: "Lista Schede",
    permission: "CAN_CRUD_SCHEDE",
  },
  {
    href: "#/admin/crea-scheda",
    label: "Crea una nuova scheda",
    permission: "CAN_CRUD_SCHEDE",
  },
  {
    href: "#/admin/utenti",
    label: "Lista Utenti",
    permission: "CAN_CRUD_USERS",
  },
  {
    href: "#/admin/crea-utente",
    label: "Crea un nuovo utente",
    permission: "CAN_CRUD_USERS",
  },
  /*{
    href: "#/admin/schede-indirizzi-locali",
    label: "Aggiungi/modifica indirizzo locale",
    permission: "CAN_CRUD_SCHEDE",
  },*/
];

function Dashboard({ classes, t }) {
  const permissions = useSelector(getUserLoggedPermissions);

  return (
    <React.Fragment>
      <Paper
        classes={{
          root: cn(classes.paper, classes.wrapperWidthLimiter),
        }}
        style={{ marginTop: "1em" }}
      >
        <WidgetPraticheRecenti classes={classes} />
      </Paper>
      <Paper
        classes={{
          root: cn(classes.paper, classes.wrapperWidthLimiter),
        }}
      >
        <List
          dense
          classes={{
            root: classes.list,
          }}
        >
          {links.map((link, index) => {
            if (
              permissions.indexOf("SUPERADMIN") !== -1 ||
              permissions.indexOf(link.permission) !== -1
            ) {
              return (
                <Fragment key={link.label}>
                  <Divider
                    classes={{
                      root: classes.divider,
                    }}
                  />
                  <ListItem
                    classes={{
                      root: classes.listItem,
                    }}
                    role={undefined}
                    dense
                    button
                    disableRipple
                    component="a"
                    href={link.href}
                  >
                    <ListItemText
                      classes={{
                        root: classes.listItemText,
                      }}
                    >
                      <Typography
                        classes={{
                          root: classes.listItemTextTypography,
                        }}
                      >
                        {link.label}
                      </Typography>
                    </ListItemText>
                    <ChevronRight
                      iconstyle={classes.chevronRight.path}
                      classes={{
                        root: classes.chevronRight,
                      }}
                    />
                  </ListItem>
                </Fragment>
              );
            }
            return null;
          })}
        </List>
      </Paper>

      {
        permissions[0] === "CAN_SEARCH_SCHEDE" && (
          <div className={classes.tutorial}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/SZcYZK0gkTw" title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
          </div>
      )}
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Dashboard));
