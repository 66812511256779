import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@material-ui/core";
import { useDeleteSchedaPopover } from "./index.hooks";

export const DeleteSchedaPopover = ({ idScheda, onClose }) => {
  const {
    onDeletionCancel,
    onDeletionConfirm,
    isDeleteSchedaButtonEnabled,
    isDeleteSchedaPopoverOpen,
  } = useDeleteSchedaPopover(idScheda, onClose);

  return (
    <Dialog open={isDeleteSchedaPopoverOpen} onClose={onClose}>
      <DialogTitle style={{ color: "#F00" }}>
        Sei sicuro di voler eliminare questa scheda?
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Questa operazione è irreversibile.
        </Typography>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            disabled={!isDeleteSchedaButtonEnabled}
            onClick={onDeletionConfirm}
          >
            Conferma
          </Button>
          <Button onClick={onDeletionCancel} variant="contained">
            Annulla
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
