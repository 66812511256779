// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, number, bool, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Button,
  Divider,
  Grid,
  MuiThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  DeleteForever,
} from '@material-ui/icons'
import TextField from 'components/text-field'

// REDUX
import {
  useSelector, useDispatch,
} from 'react-redux'
import {
  setFilter,
} from 'redux/actions/schede'
import {
  getFilters,
} from 'redux/getters/schede'

// STYLE
import styles from './style.js'

function Componente({
  classes, numeroComponente, age, error, helperText,
}) {
  const dispatch = useDispatch()
  const members = useSelector(getFilters).get('members')

  const handleDeleteMember = (deleteIndex) => {
    dispatch(
      setFilter(
        'members',
        members.filter((e, index) => index !== deleteIndex),
      ),
    )
  }

  return (
    <div className={classes.componenteContainer}>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item container xs={12} sm={6}>
          <Typography className={classes.numeroComponenteLabel}>
            Componente {numeroComponente + 1}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item container xs={12} sm={6}>
          <TextField
            error={error}
            helperText={helperText}
            label="Qual è la sua età?"
            placeholder="Inserisci l'età del componente"
            value={age}
            onChange={(event) => dispatch(
              setFilter(
                'members',
                members.setIn([numeroComponente], event.target.value),
              ),
            )
            }
          />
        </Grid>
        <Grid
          className={classes.buttonContainer}
          item
          container
          xs={12}
          sm={12}
          md={6}
        >
          {numeroComponente !== 0 && (
            <MuiThemeProvider theme={error}>
              <Button
                onClick={() => handleDeleteMember(numeroComponente)}
                color="secondary"
                className={classes.button}
              >
                <DeleteForever className={classes.deleteForever} />
                Cancella
              </Button>
            </MuiThemeProvider>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

Componente.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
  numeroComponente: number.isRequired,
  age: number.isRequired,
  error: bool.isRequired,
  helperText: string.isRequired,
}

export default withStyles(styles)(translate()(Componente))
