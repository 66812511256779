// ASSETS
import iseeImage from "assets/img/isee.svg";

// LIBS
import joi from "joi-browser";
import immutable from "immutable";

// REACT
import React, { Fragment, useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Grid, InputAdornment, withStyles } from "@material-ui/core";
import TextField from "components/text-field";
import FormContainer from "scenes/nucleo/components/form-container";
import NavigationBar from "scenes/nucleo/components/navigation-bar";
import { useForm, validationStates } from "components/use-form";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "redux/actions/schede";
import { getFilters } from "redux/getters/schede";

// STYLE
import styles from "./style.js";

const iseeSchema = joi.object().keys({
  isee: joi.number().min(0),
});

function NucleoRicercaIsee() {
  const dispatch = useDispatch();
  const isee = useSelector(getFilters).get("isee");
  const { setInitial, isValid, validations } = useForm({
    initial: immutable.fromJS({
      isee: isee.toString().replace(",", "."),
    }),
    joiValidationSchema: iseeSchema,
    onSubmit: () => null,
  });

  useEffect(() => {
    setInitial(
      immutable.fromJS({
        isee: isee.toString().replace(",", "."),
      }),
    );
  }, [isee]);

  return (
    <Fragment>
      <FormContainer step="3" title="ISEE" images={[iseeImage]}>
        <Grid container>
          <Grid item container xs={12} sm={6}>
            <TextField
              placeholder="0"
              error={validations.isee.state === validationStates.WRONG}
              helperText={
                validations.isee.state === validationStates.WRONG
                  ? "Valore ISEE non corretto"
                  : undefined
              }
              label="Qual è l’ISEE del nucleo familiare?"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              onChange={(event) =>
                dispatch(
                  setFilter(
                    "isee",
                    event.target.value ? event.target.value : 0,
                  ),
                )
              }
              value={isee || null}
            />
          </Grid>
        </Grid>
      </FormContainer>
      <NavigationBar
        backHref="#/admin/nucleo/cittadinanza"
        disabled={!isValid}
        nextHref="#/admin/nucleo/risultati"
        nextText="Vedi i risultati"
      />
    </Fragment>
  );
}

NucleoRicercaIsee.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(NucleoRicercaIsee));
