// CONSTANTS
// REACT
import React, { Fragment, useMemo } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { LinearProgress, Typography, withStyles } from "@material-ui/core";

// REDUX
import { useSelector } from "react-redux";
import { GET_SCHEDE_BY__ID_AJAX_SUCCESS } from "redux/action-types";
import { getAjaxLoading, getIndirizzo, getScheda } from "redux/getters";

// STYLE
import styles from "./style.js";
import moment from "moment";
import { ErrorOutline } from "@material-ui/icons";
import cn from "classnames";

function VersioneUtente({ classes }) {
  const scheda = useSelector(getScheda);
  const indirizzo = useSelector(getIndirizzo);
  const isLoading =
    useSelector(getAjaxLoading(GET_SCHEDE_BY__ID_AJAX_SUCCESS)) || !scheda;

  const isPresentabile = useMemo(() => {
    if (!scheda || isLoading) {
      return false;
    }

    const schedaJS = scheda.toJS();

    const isAfterStartingDate = moment(
      schedaJS.inizio_richiesta || moment().subtract(1, "day"),
    ).isBefore(moment());
    const isBeforeEndingDate = moment(
      schedaJS.fine_richiesta || moment().add(1, "day"),
    ).isAfter(moment());
    const isSenzaScadenza = Boolean(schedaJS.senza_scadenza);

    return (
      isAfterStartingDate &&
      (isSenzaScadenza || (!isSenzaScadenza && isBeforeEndingDate))
    );
  }, [scheda]);

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Fragment>
      {!isPresentabile && (
        <div className={classes.errorContainer}>
          <div className={classes.errorMessage}>
            <ErrorOutline
              classes={{
                root: classes.errorOutline,
              }}
            />
            <Typography
              className={classes.errorTitle}
              color="contrastText"
              variant="caption"
            >
              In questo momento le richieste non si possono più presentare per
              questa prestazione.
            </Typography>
          </div>
        </div>
      )}
      <Typography
        className={cn(classes.content, classes.rteContainer)}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: scheda.get("scheda_utente"),
        }}
      />
      {/*<Typography className={classes.title} variant="h2">
        {"Indirizzi locali dove fare la richiesta"}
      </Typography>
      <Typography
        className={classes.contentIndirizzi}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html:
            indirizzo.get("text") ||
            'Se non lo hai già fatto torna nella schermata precedente e seleziona il comune di residenza del richiedente in "Raffina la tua ricerca".',
        }}
      />
      <Typography
        className={classes.contentIndirizziPrint}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html:
            indirizzo.get("text") ||
            "Indirizzi non inseriti nel sistema.",
        }}
      />*/}
    </Fragment>
  );
}

VersioneUtente.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(VersioneUtente));
