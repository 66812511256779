/* eslint-disable no-nested-ternary */
// ASSETS
import "assets/css/reset.css";
import base from "assets/css/base.css";
import error from "assets/img/error.svg";
import success from "assets/img/success.svg";

// CONSTANTS
import resources from "constants/translations";

// LIBS
import ReactDOM from "react-dom";

// REACT
import React, { useEffect } from "react";
import { shape } from "prop-types";
import { I18nextProvider } from "react-i18next";
import HashRouter, { Route } from "react-redux-hash-router"; //eslint-disable-line
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Snackbar, Typography } from "@material-ui/core";
import MadeByGetapper from "react-made-by-getapper";
import Home from "scenes/home";
import Header from "components/header";
import Footer from "components/footer";

// REDUX
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "redux/store";
import { appStartup, closeFeedback } from "redux/actions";
import { getFeedback } from "redux/getters";

// SERVICES
import createI18n from "services/createI18n";
import lng from "services/language";
import SchedaPrestazione from "scenes/scheda-prestazione";

// STYLES
import styles from "styles/app";
import cn from "classnames";
import Admin from "./scenes/admin";
import HowItWorks from "./scenes/how-it-works";
import { common } from "./themes";
import "styles/editor.css";

const AppContent = ({ classes }) => {
  const dispatch = useDispatch();
  const feedback = useSelector(getFeedback);

  useEffect(() => {
    dispatch(appStartup());
  }, []);

  return (
    <div className={base.root}>
      <Header />
      <Snackbar
        className={cn(classes.wrapper, classes.snackbarContainer)}
        ContentProps={{
          className: cn(
            classes.snackbar,
            feedback.get("type") === "error"
              ? classes.feedbackError
              : feedback.get("type") === "success"
              ? classes.feedbackSuccess
              : undefined,
          ),
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        message={
          <div className={classes.message}>
            {feedback.get("type") === "success" ? (
              <img src={success} alt="success" />
            ) : (
              <img src={error} alt="error" />
            )}
            <Typography className={classes.snackbarTypography}>
              {feedback.get("message")}
            </Typography>
          </div>
        }
        open={feedback.get("isOpen")}
        onClose={() => dispatch(closeFeedback())}
      />
      <HashRouter
        routerStatePath="routing.mainRouter"
        loaderComponent={<div>Loading...</div>}
      >
        <Route hash="#" initAccess routeKey="home">
          <Home />
        </Route>

        <Route hash="#/scheda/{_id}" initAccess routeKey="schedaPrestazione">
          <SchedaPrestazione />
        </Route>
        <Route
          hash="#/scheda/{_id}/{comuneATS}"
          initAccess
          routeKey="schedaPrestazione"
        >
          <SchedaPrestazione />
        </Route>

        <Route hash="#/come-funziona" initAccess routeKey="howItWorks">
          <HowItWorks />
        </Route>
        <Route hash="#/admin" initAccess routeKey="admin">
          <Admin />
        </Route>
      </HashRouter>
      <Footer />
      <MadeByGetapper
        classes={{
          text: classes.getapperText,
        }}
        className={classes.getapperWrapper}
      />
    </div>
  );
};

AppContent.propTypes = {
  classes: shape({}).isRequired,
};

const AppContentStyled = withStyles(styles)(AppContent);

const App = () => (
  <Provider store={store}>
    <I18nextProvider
      i18n={createI18n({
        resources,
        lng,
      })}
    >
      <MuiThemeProvider theme={common}>
        <AppContentStyled />
      </MuiThemeProvider>
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
