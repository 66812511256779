import { useDeleteTagDialog } from "./index.hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

export const DeleteTagDialog = ({ onDeleteTagModalClose, tag }) => {
  const { isDeleteTagModalOpen, onDeletionConfirmed } = useDeleteTagDialog(tag);

  if (!tag) return null;

  return (
    <Dialog
      open={isDeleteTagModalOpen}
      onClose={onDeleteTagModalClose}
      fullWidth
      PaperProps={{
        style: {
          padding: "1em",
        },
      }}
    >
      <DialogTitle>
        <Typography color="textPrimary" variant="h2">
          Sei sicuro di voler eliminare il TAG: "{tag.name}"?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color="textPrimary" variant="caption">
          Questa operazione non è reversibile
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={onDeletionConfirmed}
          variant="text"
          style={{ color: "#FF0000" }}
        >
          Elimina
        </Button>
        <Button
          fullWidth
          onClick={onDeleteTagModalClose}
          variant="contained"
          color="primary"
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
};
