import React from "react";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import BackButton from "../../../../components/back-button";
import styles from "./style.js";
import { useAdminGlossary } from "./index.hooks";

const AdminGlossary = ({ classes }) => {
  const { glossaryContent } = useAdminGlossary();

  const renderHeading = () => (
    <div>
      <div className={classes.title}>
        <Typography variant="h2">Consultazione glossario</Typography>
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <BackButton
            onClick={() => window.history.back()}
            text="Torna alla home"
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.wrapperWidthLimiter}>
      {renderHeading()}
      <Paper style={{ marginTop: "2em", padding: "2em" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: glossaryContent,
          }}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(AdminGlossary);
