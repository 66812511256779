// REDUX
import {
  AUTHENTICATION_EXPIRED,
  USER_LOGGED,
  USER_NOT_LOGGED,
} from '../../action-types'

export const authenticationExpired = () => ({
  type: AUTHENTICATION_EXPIRED,
})

export const userLogged = () => ({
  type: USER_LOGGED,
})

export const userNotLogged = () => ({
  type: USER_NOT_LOGGED,
})
