import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    paper: {
      bordeRadius: 5,
      padding: 20,
      marginTop: 50,
    },
  },
  sg,
)
