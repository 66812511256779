import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    container: {
      width: '100%',
      marginTop: '30px',
      marginBottom: '45px',
    },
    buttonLeft: {
      width: '50%',
      borderRadius: '30px 0 0 30px',
    },
    buttonRight: {
      width: '50%',
      borderRadius: '0 30px 30px 0',
    },
    paperLeft: {
      width: '100%',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '30px 0 0 30px',
    },
    paperRight: {
      width: '100%',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '0 30px 30px 0',
    },
    paperUnselected: {
      backgroundColor: '#E5E5E8',
      color: theme.palette.secondary.main,
    },
    paperLeftSelected: {
      zIndex: 1,
      boxShadow: '3px 0 6px rgba(0,0,0,.16)',
    },
    paperRightSelected: {
      zIndex: 1,
      boxShadow: '-3px 0 6px rgba(0,0,0,.16)',
    },
    title: {
      fontSize: 14,
      padding: 15,
      lineHeight: 1.25,
    },
  },
  sg,
)
