// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useMemo, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import { Paper, TextField, Typography, withStyles } from "@material-ui/core";
import Button from "components/button";
import { useForm, validationStates } from "components/use-form";
import FormComponent from "components/form-component";
import { createUser } from "scenes/admin/components/editor-user";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  createUsersResetCodeAjax,
  updateUsersPasswordAjax,
} from "redux/actions";
import { getRestUri } from "redux/getters";

// LIBS
import joi from "joi-browser";
import immutable from "immutable";

// STYLE
import styles from "./style.js";

const passwordRecoverySchema = joi.object().keys({
  email: joi.string().email().required(),
});

const changePasswordSchema = joi.object().keys({
  code: joi
    .string()
    .required()
    .length(6)
    .options({
      language: {
        any: { required: "è obbligatorio" },
        string: { length: "deve essere lungo 6 caratteri" },
      },
    }),
  newPassword: joi.string().min(8).required(),
  confirmPassword: joi
    .string()
    .valid(joi.ref("newPassword"))
    .required()
    .options({
      language: {
        any: {
          required: "è obbligatorio",
          allowOnly: "non coincide con la nuova password",
        },
        string: { allowOnly: "non coincide con la nuova password" },
      },
    }),
});

const passwordRecoveryInitial = immutable.fromJS({
  email: "",
});

const changePasswordInitial = immutable.fromJS({
  code: "",
  newPassword: "",
  confirmPassword: "",
});

function PasswordRecovery({ classes, t }) {
  const restUri = useSelector(getRestUri);
  const dispatch = useDispatch();

  const [recoveryState, setRecoveryState] = useState("requestCode");
  const [email, setEmail] = useState("");

  const submitButtonText = useMemo(
    () =>
      recoveryState === "requestCode" ? "INVIA CODICE" : "CAMBIA PASSWORD",
    [recoveryState],
  );

  const {
    data: requestCodeData,
    onChangeEvent: requestCodeOnChangeEvent,
    isValid: requestCodeIsValid,
    validations: requestCodeValidations,
    onSubmit: requestCodeOnSubmit,
  } = useForm({
    initial: passwordRecoveryInitial,
    joiValidationSchema: passwordRecoverySchema,
    onSubmit: () => {
      dispatch(createUsersResetCodeAjax(restUri, requestCodeData.toJS()));
      setRecoveryState("changePassword");
      setEmail(requestCodeData.get("email"));
    },
  });

  const {
    data: changePasswordData,
    onChangeEvent: changePasswordOnChangeEvent,
    isValid: changePasswordIsValid,
    validations: changePasswordValidations,
    onSubmit: changePasswordOnSubmit,
  } = useForm({
    initial: changePasswordInitial,
    joiValidationSchema: changePasswordSchema,
    onSubmit: () => {
      const { confirmPassword, ...others } = changePasswordData.toJS();
      const payload = { ...others, email };

      dispatch(updateUsersPasswordAjax(restUri, payload));
    },
  });

  const isSubmitButtonDisabled = useMemo(() => {
    if (recoveryState === "requestCode") {
      return !requestCodeIsValid;
    }
    if (recoveryState === "changePassword") {
      return !changePasswordIsValid;
    }
  }, [recoveryState, requestCodeIsValid, changePasswordIsValid]);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <Paper className={classes.paper}>
        <Typography
          variant="h2"
          color="primary"
          style={{ marginBottom: "1em" }}
        >
          Recupera password
        </Typography>
        {recoveryState === "changePassword" && (
          <Typography
            variant="h4"
            color="secondary"
            style={{ marginBottom: "1em", fontWeight: "normal" }}
          >
            Se la tua mail esiste, ti invieremo un codice. Inseriscilo qui sotto
            e imposta la tua nuova password
          </Typography>
        )}
        <form
          onSubmit={
            recoveryState === "requestCode"
              ? requestCodeOnSubmit
              : changePasswordOnSubmit
          }
        >
          {recoveryState === "requestCode" && (
            <FormComponent
              Component={TextField}
              data={requestCodeData}
              field="email"
              fullWidth
              onChange={requestCodeOnChangeEvent}
              validations={requestCodeValidations}
              variant="outlined"
            />
          )}
          {recoveryState === "changePassword" && (
            <React.Fragment>
              <FormComponent
                Component={TextField}
                data={changePasswordData}
                field="code"
                fullWidth
                onChange={changePasswordOnChangeEvent}
                validations={changePasswordValidations}
                variant="outlined"
                label="Codice"
              />
              <FormComponent
                Component={TextField}
                data={changePasswordData}
                field="newPassword"
                fullWidth
                onChange={changePasswordOnChangeEvent}
                validations={changePasswordValidations}
                type="password"
                variant="outlined"
                label="Nuova password"
              />
              <FormComponent
                Component={TextField}
                data={changePasswordData}
                field="confirmPassword"
                fullWidth
                onChange={changePasswordOnChangeEvent}
                validations={changePasswordValidations}
                type="password"
                variant="outlined"
                label="Conferma password"
              />
            </React.Fragment>
          )}
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitButtonDisabled}
            variant="contained"
          >
            {submitButtonText}
          </Button>
        </form>
      </Paper>
    </div>
  );
}

PasswordRecovery.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(PasswordRecovery));
