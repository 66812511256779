import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      wrapperWidthLimiter: {
        width: "95%",
        maxWidth: 1000,
        margin: "0 auto",
      },
      circle: {
        display: "none",
        "@media (min-width: 600px)": {
          height: 46,
          width: 46,
          borderRadius: 30,
          position: "absolute",
          left: -53,
          backgroundColor: "#5378B2",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        },
        "@media print": {
          display: "none",
        },
      },
      chevronRight: {
        position: "absolute",
        right: 10,
        width: 24,
        height: 24,
        color: theme.palette.secondary.main,
        "@media (min-width: 600px)": {
          right: 40,
        },
      },
      divider: {
        color: theme.palette.secondary.main,
        "@media (min-width: 600px)": {
          margin: "0 30px",
        },
      },
      list: {
        marginTop: 50,
        borderRadius: 5,
        padding: "0 0 0 20px",
        backgroundColor: "white",
        "@media (min-width: 600px)": {
          padding: "0 0 0 30px",
        },
      },
      listItem: {
        minHeight: 70,
        "&:hover": {
          backgroundColor: "rgba(83,120,178, 0.05)",
        },
        "@media (max-width: 600px)": {
          minHeight: 60,
          padding: 0,
        },
        "@media print": {
          pageBreakInside: "avoid",
        },
      },
      listItemText: {
        marginLeft: 23,
      },
      listItemTextTypography: {
        color: theme.palette.primary.main,
        paddingRight: 60,
      },
      paper: {
        borderRadius: 5,
      },
      searchOutlined: {
        fill: "#E2E2E2",
        marginLeft: 10,
      },
      textFieldInput: {
        background: "white",
      },
      title: {
        marginTop: 40,
        marginBottom: 20,
      },
      noResults: {
        margin: "40px 0",
      },
    },
    sg,
  );
