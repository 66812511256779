// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  ButtonBase, Paper, Typography, withStyles,
} from '@material-ui/core'

// REDUX
import {
  useDispatch,
} from 'react-redux'
import {
  resetFilter,
} from 'redux/actions/schede'

// STYLE
import styles from './style.js'

function ChoiceBox({
  classes, href, image, title, subtitle, iconClass
}) {
  const dispatch = useDispatch()

  return (
    <ButtonBase
      className={classes.container}
      href={href}
      onClick={() => dispatch(resetFilter())}
    >
      <Paper className={classes.box} elevation={12}>
        <img className={iconClass} src={image} alt="icon" />
        <div className={classes.text}>
          <Typography variant="h3">{title}</Typography>
          <Typography color="secondary" variant="subtitle1">
            {subtitle}
          </Typography>
        </div>
      </Paper>
    </ButtonBase>
  )
}

ChoiceBox.propTypes = {
  classes: shape({}).isRequired,
  href: string.isRequired,
  image: string.isRequired,
  t: func.isRequired,
  title: string.isRequired,
  subtitle: string.isRequired,
}

export default withStyles(styles)(translate()(ChoiceBox))
