// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  SvgIcon, withStyles,
} from '@material-ui/core'

// STYLE
import styles from './style.js'

function FilterIcon({
  classes,
}) {
  return (
    <SvgIcon className={classes.svg}>
      <path fill="none" strokeLinecap="round" d="M2.503.5v7" />
      <g fill="none" transform="translate(0 7.5)">
        <circle stroke="none" cx="2.5" cy="2.5" r="2.5" />
        <circle className="d" cx="2.5" cy="2.5" r="2" />
      </g>
      <path fill="none" strokeLinecap="round" d="M10.503 4.993v15.913" />
      <g fill="none" transform="translate(8 .5)">
        <circle stroke="none" cx="2.5" cy="2.5" r="2.5" />
        <circle className="d" cx="2.5" cy="2.5" r="2" />
      </g>
      <path fill="none" strokeLinecap="round" d="M18.503 1v14.8" />
      <g fill="none" transform="translate(16 15.5)">
        <circle stroke="none" cx="2.5" cy="2.5" r="2.5" />
        <circle className="d" cx="2.5" cy="2.5" r="2" />
      </g>
      <path fill="none" strokeLinecap="round" d="M2.503 12.5v8" />
    </SvgIcon>
  )
}

FilterIcon.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
}

export default withStyles(styles)(translate()(FilterIcon))
