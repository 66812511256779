// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useState, useEffect, Fragment } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  Button,
  Paper,
  Grid,
  ClickAwayListener,
  MuiThemeProvider,
  MenuList,
  Typography,
  MenuItem,
  Popper,
  withStyles,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

// LIBS
import cn from "classnames";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import immutable from "immutable";
import joi from "joi-browser";

// STYLE
import { common } from "themes/index";
import CustomTextField from "components/text-field";
import {
  createAdminIndirizziAjax,
  getComuniAjax,
  getComuniAjaxCancel,
  getIndirizziSchedeAjax,
} from "redux/actions";
import {
  getComuni,
  getMe,
  getUsersFilters,
  getRestUri,
  getMainRouteParams,
  getAdminRouteParams,
  getIndirizzo,
  getIsSuperAdmin,
} from "redux/getters";
import { useForm } from "components/use-form";
import chapters from "constants/chapters";
import styles from "./style.js";
import {editorToolbarConfig, rteTranslations} from "../../../../constants/editor-config";

const editingForm = joi.object().keys({}).unknown(true);
function InserisciModificaIndirizzoLocale({ classes, t }) {
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const user = useSelector(getMe);

  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const comuni = useSelector(getComuni);
  const [comuneText, setComuneText] = useState("");
  const [comuneATS, setComuneATS] = useState(
    isSuperAdmin ? null : user && user.getIn(["comune", "ATS"]),
  );
  const [selected, setSelected] = useState(!isSuperAdmin);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const indirizzo = useSelector(getIndirizzo);
  // eslint-disable-next-line no-underscore-dangle
  const _id = useSelector(getAdminRouteParams).get("_id");
  const populateInitial = () => {
    if (indirizzo) {
      return immutable.fromJS(
        Object.fromEntries(
          Object.entries(indirizzo.toJS()).map(([key, value]) => {
            if (key.indexOf("text") !== -1) {
              const blocksFromHtml = htmlToDraft(value || "");
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap,
              );
              value = EditorState.createWithContent(contentState);
            }
            return [key, value];
          }),
        ),
      );
    }
    return null;
  };

  const onSubmitForm = () => {
    const parsedData = {};

    // eslint-disable-next-line no-use-before-define
    data &&
      // eslint-disable-next-line no-use-before-define,array-callback-return
      Object.entries(data.toJS()).map(([key, value]) => {
        if (key.indexOf("text") !== -1) {
          // eslint-disable-next-line no-use-before-define
          parsedData[key] = draftToHtml(
            convertToRaw(data.get(key).getCurrentContent()),
          );
        } else {
          parsedData[key] = value;
        }
      });
    // eslint-disable-next-line no-use-before-define
    dispatch(
      createAdminIndirizziAjax(restUri, {
        ATS: comuneATS,
        // eslint-disable-next-line no-use-before-define
        text: parsedData.text,
        schedaId: _id,
      }),
    );
  };

  const {
    data,
    setInitial,
    onChange,
    onChangeEvent,
    isValid,
    validations,
    onSubmit,
  } = useForm({
    initial: populateInitial(),
    joiValidationSchema: editingForm,
    onSubmit: () => onSubmitForm(),
  });

  useEffect(() => {
    setInitial(populateInitial());
  }, [indirizzo]);

  useEffect(() => {
    if (_id && comuneATS) {
      dispatch(
        getIndirizziSchedeAjax(restUri, {
          query: {
            schedaId: _id,
            ATS: comuneATS,
          },
        }),
      );
    }
  }, [_id, comuneATS]);

  useEffect(() => {
    dispatch(getComuniAjaxCancel());
    if (comuneText !== "" && !selected) {
      dispatch(
        getComuniAjax(restUri, {
          query: {
            comune: comuneText,
          },
        }),
      );
    } else {
      setMenuOpen(false);
    }
  }, [comuneText]);

  useEffect(() => {
    if (comuni.length && comuneText !== "") {
      setMenuOpen(true);
    }
  }, [comuni]);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <Grid item container xs={12}>
        <Paper className={classes.paper}>
          <form onSubmit={onSubmit}>
            <Grid item xs={12}>
              {isSuperAdmin && (
                <MuiThemeProvider theme={common}>
                  <CustomTextField
                    className={classes.customTextField}
                    classes={{
                      notchedOutline: classes.textfield,
                      input: classes.input,
                    }}
                    label="Seleziona comune indirizzo locale"
                    placeholder="Inizia a scrivere il comune e selezionalo dalla lista"
                    onChange={(ev) => {
                      setComuneText(ev.target.value);
                      setAnchor(ev.currentTarget);
                    }}
                    value={comuneText}
                  />
                  <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <Popper
                      anchorEl={anchor}
                      className={classes.menuPopover}
                      open={menuOpen}
                      transition
                      disablePortal
                      placement="bottom-start"
                    >
                      <Paper className={classes.menuPaper}>
                        <MenuList>
                          {comuni.map((c) => (
                            <MenuItem
                              key={c.id}
                              onClick={() => {
                                setMenuOpen(false);
                                setComuneText(c.comune);
                                setComuneATS(c.ATS);
                                setSelected(true);
                              }}
                            >
                              {c.comune}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Paper>
                    </Popper>
                  </ClickAwayListener>
                </MuiThemeProvider>
              )}
            </Grid>
            <Grid item xs={12}>
              {data && (!isSuperAdmin || selected) && (
                <div
                  className={cn(classes.editorContainer, classes.rteContainer)}
                >
                  <Editor
                    editorState={data.get("text")}
                    onEditorStateChange={(es) => {
                      onChange("text", es);
                    }}
                    toolbar={editorToolbarConfig}
                    localization={{
                      locale: "en",
                      translations: rteTranslations,
                    }}
                  />
                </div>
              )}
            </Grid>
            <Button
              className={classes.MT20}
              color="primary"
              disabled={!isValid}
              type="submit"
              variant="contained"
            >
              conferma
            </Button>
          </form>
        </Paper>
      </Grid>
    </div>
  );
}

InserisciModificaIndirizzoLocale.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(
  translate()(InserisciModificaIndirizzoLocale),
);
