import {
  EDIT_CHAPTER,
} from 'redux/action-types/admin'
import {
  ADMIN_STARTUP,
} from 'redux/action-types'

export const editChapter = (textId, editorContent) => ({
  type: EDIT_CHAPTER,
  textId,
  editorContent,
})

export const adminStartup = () => ({
  type: ADMIN_STARTUP,
})
