export const GET_ADMIN_INDIRIZZI_AJAX_REQUEST =
  "GET_ADMIN_INDIRIZZI_AJAX_REQUEST";
export const GET_ADMIN_INDIRIZZI_AJAX_SUCCESS =
  "GET_ADMIN_INDIRIZZI_AJAX_SUCCESS";
export const GET_ADMIN_INDIRIZZI_AJAX_FAILED =
  "GET_ADMIN_INDIRIZZI_AJAX_FAILED";

export const CREATE_ADMIN_INDIRIZZI_AJAX_REQUEST =
  "CREATE_ADMIN_INDIRIZZI_AJAX_REQUEST";
export const CREATE_ADMIN_INDIRIZZI_AJAX_SUCCESS =
  "CREATE_ADMIN_INDIRIZZI_AJAX_SUCCESS";
export const CREATE_ADMIN_INDIRIZZI_AJAX_FAILED =
  "CREATE_ADMIN_INDIRIZZI_AJAX_FAILED";

export const GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_REQUEST =
  "GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_REQUEST";
export const GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_SUCCESS =
  "GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_SUCCESS";
export const GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_FAILED =
  "GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_FAILED";

export const GET_ADMIN_SCHEDE_AJAX_REQUEST = "GET_ADMIN_SCHEDE_AJAX_REQUEST";
export const GET_ADMIN_SCHEDE_AJAX_SUCCESS = "GET_ADMIN_SCHEDE_AJAX_SUCCESS";
export const GET_ADMIN_SCHEDE_AJAX_FAILED = "GET_ADMIN_SCHEDE_AJAX_FAILED";

export const CREATE_ADMIN_SCHEDE_AJAX_REQUEST =
  "CREATE_ADMIN_SCHEDE_AJAX_REQUEST";
export const CREATE_ADMIN_SCHEDE_AJAX_SUCCESS =
  "CREATE_ADMIN_SCHEDE_AJAX_SUCCESS";
export const CREATE_ADMIN_SCHEDE_AJAX_FAILED =
  "CREATE_ADMIN_SCHEDE_AJAX_FAILED";

export const GET_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST =
  "GET_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST";
export const GET_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS =
  "GET_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS";
export const GET_ADMIN_SCHEDE_BY__ID_AJAX_FAILED =
  "GET_ADMIN_SCHEDE_BY__ID_AJAX_FAILED";

export const GET_ADMIN_STATIC_CONTENTS_AJAX_REQUEST =
  "GET_ADMIN_STATIC_CONTENTS_AJAX_REQUEST";
export const GET_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS =
  "GET_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS";
export const GET_ADMIN_STATIC_CONTENTS_AJAX_FAILED =
  "GET_ADMIN_STATIC_CONTENTS_AJAX_FAILED";

export const UPDATE_ADMIN_STATIC_CONTENTS_AJAX_REQUEST =
  "UPDATE_ADMIN_STATIC_CONTENTS_AJAX_REQUEST";
export const UPDATE_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS =
  "UPDATE_ADMIN_STATIC_CONTENTS_AJAX_SUCCESS";
export const UPDATE_ADMIN_STATIC_CONTENTS_AJAX_FAILED =
  "UPDATE_ADMIN_STATIC_CONTENTS_AJAX_FAILED";

export const GET_ADMIN_TAGS_AJAX_REQUEST = "GET_ADMIN_TAGS_AJAX_REQUEST";
export const GET_ADMIN_TAGS_AJAX_SUCCESS = "GET_ADMIN_TAGS_AJAX_SUCCESS";
export const GET_ADMIN_TAGS_AJAX_FAILED = "GET_ADMIN_TAGS_AJAX_FAILED";

export const CREATE_ADMIN_TAGS_AJAX_REQUEST = "CREATE_ADMIN_TAGS_AJAX_REQUEST";
export const CREATE_ADMIN_TAGS_AJAX_SUCCESS = "CREATE_ADMIN_TAGS_AJAX_SUCCESS";
export const CREATE_ADMIN_TAGS_AJAX_FAILED = "CREATE_ADMIN_TAGS_AJAX_FAILED";

export const UPDATE_ADMIN_TAGS_BY__ID_AJAX_REQUEST =
  "UPDATE_ADMIN_TAGS_BY__ID_AJAX_REQUEST";
export const UPDATE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS =
  "UPDATE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS";
export const UPDATE_ADMIN_TAGS_BY__ID_AJAX_FAILED =
  "UPDATE_ADMIN_TAGS_BY__ID_AJAX_FAILED";

export const DELETE_ADMIN_TAGS_BY__ID_AJAX_REQUEST =
  "DELETE_ADMIN_TAGS_BY__ID_AJAX_REQUEST";
export const DELETE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS =
  "DELETE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS";
export const DELETE_ADMIN_TAGS_BY__ID_AJAX_FAILED =
  "DELETE_ADMIN_TAGS_BY__ID_AJAX_FAILED";

export const UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST =
  "UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST";
export const UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS =
  "UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS";
export const UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED =
  "UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED";

export const DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST =
  "DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST";
export const DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS =
  "DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS";
export const DELETE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED =
  "DELETE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED";

export const GET_ADMIN_USERS_AJAX_REQUEST = "GET_ADMIN_USERS_AJAX_REQUEST";
export const GET_ADMIN_USERS_AJAX_SUCCESS = "GET_ADMIN_USERS_AJAX_SUCCESS";
export const GET_ADMIN_USERS_AJAX_FAILED = "GET_ADMIN_USERS_AJAX_FAILED";

export const CREATE_ADMIN_USERS_AJAX_REQUEST =
  "CREATE_ADMIN_USERS_AJAX_REQUEST";
export const CREATE_ADMIN_USERS_AJAX_SUCCESS =
  "CREATE_ADMIN_USERS_AJAX_SUCCESS";
export const CREATE_ADMIN_USERS_AJAX_FAILED = "CREATE_ADMIN_USERS_AJAX_FAILED";

export const GET_ADMIN_USERS_BY__ID_AJAX_REQUEST =
  "GET_ADMIN_USERS_BY__ID_AJAX_REQUEST";
export const GET_ADMIN_USERS_BY__ID_AJAX_SUCCESS =
  "GET_ADMIN_USERS_BY__ID_AJAX_SUCCESS";
export const GET_ADMIN_USERS_BY__ID_AJAX_FAILED =
  "GET_ADMIN_USERS_BY__ID_AJAX_FAILED";

export const UPDATE_ADMIN_USERS_BY__ID_AJAX_REQUEST =
  "UPDATE_ADMIN_USERS_BY__ID_AJAX_REQUEST";
export const UPDATE_ADMIN_USERS_BY__ID_AJAX_SUCCESS =
  "UPDATE_ADMIN_USERS_BY__ID_AJAX_SUCCESS";
export const UPDATE_ADMIN_USERS_BY__ID_AJAX_FAILED =
  "UPDATE_ADMIN_USERS_BY__ID_AJAX_FAILED";

export const GET_COMUNI_AJAX_REQUEST = "GET_COMUNI_AJAX_REQUEST";
export const GET_COMUNI_AJAX_SUCCESS = "GET_COMUNI_AJAX_SUCCESS";
export const GET_COMUNI_AJAX_FAILED = "GET_COMUNI_AJAX_FAILED";

export const GET_INDIRIZZI_SCHEDE_AJAX_REQUEST =
  "GET_INDIRIZZI_SCHEDE_AJAX_REQUEST";
export const GET_INDIRIZZI_SCHEDE_AJAX_SUCCESS =
  "GET_INDIRIZZI_SCHEDE_AJAX_SUCCESS";
export const GET_INDIRIZZI_SCHEDE_AJAX_FAILED =
  "GET_INDIRIZZI_SCHEDE_AJAX_FAILED";

export const GET_SCHEDE_AJAX_REQUEST = "GET_SCHEDE_AJAX_REQUEST";
export const GET_SCHEDE_AJAX_SUCCESS = "GET_SCHEDE_AJAX_SUCCESS";
export const GET_SCHEDE_AJAX_FAILED = "GET_SCHEDE_AJAX_FAILED";

export const GET_SCHEDE_RECENTI_AJAX_REQUEST = "GET_SCHEDE_RECENTI_AJAX_REQUEST";
export const GET_SCHEDE_RECENTI_AJAX_SUCCESS = "GET_SCHEDE_RECENTI_AJAX_SUCCESS";
export const GET_SCHEDE_RECENTI_AJAX_FAILED = "GET_SCHEDE_RECENTI_AJAX_FAILED";

export const GET_SCHEDE_BY__ID_AJAX_REQUEST = "GET_SCHEDE_BY__ID_AJAX_REQUEST";
export const GET_SCHEDE_BY__ID_AJAX_SUCCESS = "GET_SCHEDE_BY__ID_AJAX_SUCCESS";
export const GET_SCHEDE_BY__ID_AJAX_FAILED = "GET_SCHEDE_BY__ID_AJAX_FAILED";

export const GET_SCHEDE__IDS_AJAX_REQUEST = "GET_SCHEDE__IDS_AJAX_REQUEST";
export const GET_SCHEDE__IDS_AJAX_SUCCESS = "GET_SCHEDE__IDS_AJAX_SUCCESS";
export const GET_SCHEDE__IDS_AJAX_FAILED = "GET_SCHEDE__IDS_AJAX_FAILED";

export const GET_USERS_ME_AJAX_REQUEST = "GET_USERS_ME_AJAX_REQUEST";
export const GET_USERS_ME_AJAX_SUCCESS = "GET_USERS_ME_AJAX_SUCCESS";
export const GET_USERS_ME_AJAX_FAILED = "GET_USERS_ME_AJAX_FAILED";

export const DELETE_USERS_TOKENS_AJAX_REQUEST =
  "DELETE_USERS_TOKENS_AJAX_REQUEST";
export const DELETE_USERS_TOKENS_AJAX_SUCCESS =
  "DELETE_USERS_TOKENS_AJAX_SUCCESS";
export const DELETE_USERS_TOKENS_AJAX_FAILED =
  "DELETE_USERS_TOKENS_AJAX_FAILED";

export const CREATE_USERS_TOKENS_AJAX_REQUEST =
  "CREATE_USERS_TOKENS_AJAX_REQUEST";
export const CREATE_USERS_TOKENS_AJAX_SUCCESS =
  "CREATE_USERS_TOKENS_AJAX_SUCCESS";
export const CREATE_USERS_TOKENS_AJAX_FAILED =
  "CREATE_USERS_TOKENS_AJAX_FAILED";

export const UPDATE_USERS_PASSWORD_AJAX_REQUEST =
  "UPDATE_USERS_PASSWORD_AJAX_REQUEST";
export const UPDATE_USERS_PASSWORD_AJAX_SUCCESS =
  "UPDATE_USERS_PASSWORD_AJAX_SUCCESS";
export const UPDATE_USERS_PASSWORD_AJAX_FAILED =
  "UPDATE_USERS_PASSWORD_AJAX_FAILED";
