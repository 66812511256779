// LIBS
import immutable from 'immutable'

export default immutable.fromJS({
  mainRouter: null,
  operatoreRouter: null,
  nucleoRouter: null,
  testRouter: null,
  nucleoRicercaRouter: null,
  adminRouter: null,
})
