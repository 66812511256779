/* eslint-disable camelcase */

import {
  RESET_USERS_FILTERS,
  RESET_USERS_ITEM,
  SET_USERS_FILTER,
} from 'redux/action-types'

export const setUsersFilter = (param, value) => ({
  type: SET_USERS_FILTER,
  param,
  value,
})

export const resetUsersItem = () => ({
  type: RESET_USERS_ITEM,
})

export const resetUsersFilters = () => ({
  type: RESET_USERS_FILTERS,
})
