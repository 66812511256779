// CONSTANTS
// REACT
import React from 'react'
import {
  bool, func, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  withStyles, Typography, Switch,
} from '@material-ui/core'

// STYLE
import styles from './style.js'

function CustomSwitch({
  checked, classes, label, onChange,
}) {
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <div className={classes.switchContainer}>
        <Typography>NO</Typography>
        <Switch
          color="primary"
          onChange={onChange}
          checked={checked}
          classes={{
            root: classes.switch,
            thumb: classes.thumb,
            track: classes.track,
            switchBase: classes.switchBase,
            checked: classes.checked,
          }}
        />
        <Typography>SI</Typography>
      </div>
    </div>
  )
}

CustomSwitch.propTypes = {
  checked: bool.isRequired,
  classes: shape({}).isRequired,
  t: func.isRequired,
  label: string.isRequired,
  onChange: func.isRequired,
}

export default withStyles(styles)(translate()(CustomSwitch))
