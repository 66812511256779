// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useState, useEffect } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import HashRouter, { Route } from "react-redux-hash-router";
import { withStyles } from "@material-ui/core";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { adminStartup } from "redux/actions";

// LIBS
import cn from "classnames";
import AdminSearch from "./scenes/admin-search";
import AdminInserisciModificaIndirizzoLocale from "./scenes/admin-inserisci-modifica-indirizzo-locale";
import AdminSchedeIndirizzoLocale from "./scenes/admin-schede-indirizzi-locale";
import AdminCreaScheda from "./scenes/admin-crea-scheda";
import AdminModificaUser from "./scenes/admin-modifica-user";
import AdminCreaUser from "./scenes/admin-crea-user";
import EditorScheda from "./components/editor-scheda";
import AdminModificaScheda from "./scenes/admin-modifica-scheda";
import AdminUsersList from "./scenes/admin-users-list";
import Login from "./scenes/login";
import AdminSchedeList from "./scenes/admin-schede-list";
import Dashboard from "./scenes/dashboard";

// STYLE
import styles from "./style.js";
import Catalogo from "../catalogo";
import Nucleo from "../nucleo";
import PasswordRecovey from "./scenes/recovery-password";
import AdminTags from "./scenes/admin-tags";
import AdminGlossaryEditor from "./scenes/admin-glossary-editor";
import AdminGlossary from "./scenes/admin-glossary";

function Admin({ classes, t }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminStartup());
  }, []);

  return (
    <div className={classes.wrapper}>
      <HashRouter
        routerStatePath="routing.adminRouter"
        loaderComponent={<div>Loading...</div>}
      >
        <Route hash="#/admin" initAccess routeKey="dashboard">
          <Dashboard />
        </Route>
        <Route
          hash="#/admin/glossary-edit"
          initAccess
          routeKey="adminGlossaryEdit"
        >
          <AdminGlossaryEditor />
        </Route>
        <Route hash="#/admin/glossary" initAccess routeKey="adminGlossary">
          <AdminGlossary />
        </Route>
        <Route hash="#/admin/tags" initAccess routeKey="adminTags">
          <AdminTags />
        </Route>
        <Route hash="#/admin/search" initAccess routeKey="adminSearch">
          <AdminSearch />
        </Route>
        <Route hash="#/admin/schede" initAccess routeKey="adminSchedeList">
          <AdminSchedeList />
        </Route>
        <Route
          hash="#/admin/modifica-scheda/{_id}"
          initAccess
          routeKey="adminModificaScheda"
        >
          <AdminModificaScheda />
        </Route>
        <Route hash="#/admin/login" initAccess routeKey="adminLogin">
          <Login />
        </Route>
        <Route
          hash="#/admin/recovery-password"
          initAccess
          routeKey="adminRecoveryPassword"
        >
          <PasswordRecovey />
        </Route>
        <Route hash="#/admin/nucleo" initAccess routeKey="adminNucleo">
          <Nucleo />
        </Route>
        <Route hash="#/admin/catalogo" initAccess routeKey="adminCatalogo">
          <Catalogo />
        </Route>
        <Route hash="#/admin/utenti" initAccess routeKey="adminUsersList">
          <AdminUsersList />
        </Route>
        <Route hash="#/admin/crea-utente" initAccess routeKey="adminCreateUser">
          <AdminCreaUser />
        </Route>
        <Route
          hash="#/admin/modifica-utente/{_id}"
          initAccess
          routeKey="adminModificaUser"
        >
          <AdminModificaUser />
        </Route>
        <Route hash="#/admin/crea-scheda" initAccess routeKey="adminCreaScheda">
          <AdminCreaScheda />
        </Route>
        <Route
          hash="#/admin/schede-indirizzi-locali"
          initAccess
          routeKey="adminSchedeIndirizziLocali"
        >
          <AdminSchedeIndirizzoLocale />
        </Route>
        <Route
          hash="#/admin/schede/{_id}/inserisci-modifica-indirizzi-locali/"
          initAccess
          routeKey="adminInserisciModificaIndirizzoLocale"
        >
          <AdminInserisciModificaIndirizzoLocale />
        </Route>
      </HashRouter>
    </div>
  );
}

Admin.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Admin));
