import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    svg: {
      stroke: 'currentColor',
    },
  },
  sg,
)
