import React from "react";
import { Button, Grid, Paper, Typography, withStyles } from "@material-ui/core";
import BackButton from "../../../../components/back-button";
import styles from "./style.js";
import { useAdminGlossaryEditor } from "./index.hooks";
import { Editor } from "react-draft-wysiwyg";

const AdminGlossaryEditor = ({ classes }) => {
  const {
    isFetching,
    defaultEditorState,
    isSaveButtonDisabled,
    onEditorChange,
    onSaveGlossaryClick,
  } = useAdminGlossaryEditor();

  const renderHeading = () => (
    <div>
      <div className={classes.title}>
        <Typography variant="h2">Modifica il glossario</Typography>
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <BackButton
            onClick={() => window.history.back()}
            text="Torna alla home"
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.wrapperWidthLimiter}>
      {renderHeading()}
      <Button
        disabled={isSaveButtonDisabled}
        onClick={onSaveGlossaryClick}
        variant="contained"
        color="primary"
        style={{ marginTop: "2em" }}
      >
        Salva glossario
      </Button>
      <Paper style={{ marginTop: "2em", padding: "2em" }}>
        {isFetching ? (
          <Typography>Loading...</Typography>
        ) : (
          <Editor
            defaultEditorState={defaultEditorState}
            onEditorStateChange={onEditorChange}
          />
        )}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(AdminGlossaryEditor);
