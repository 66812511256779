// CONSTANTS

// LIBS
import joi from "joi-browser";

// REACT
import React, { Fragment, useEffect } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Button, MuiThemeProvider, withStyles } from "@material-ui/core";
import FormContainer from "scenes/nucleo/components/form-container";
import NavigationBar from "scenes/nucleo/components/navigation-bar";
import { useForm, validationStates } from "components/use-form";

// REDUX
import { useSelector, useDispatch } from "react-redux";

// STYLE
import { AddCircleOutline } from "@material-ui/icons";
import { setFilter } from "redux/actions/schede";
import { getFilters } from "redux/getters/schede";
import styles from "./style.js";
import Componente from "./components/componente";
import nucleo from "assets/img/nucleo.svg";
import { alternative } from "../../../../themes";

const membersSchema = joi
  .array()
  .items(joi.number().integer().min(0).max(150).allow(""));

function NucleoRicercaComponenti({ classes }) {
  const dispatch = useDispatch();
  const members = useSelector(getFilters).get("members");
  const { data, setInitial, isValid, validations } = useForm({
    initial: members,
    joiValidationSchema: membersSchema,
    onSubmit: () => null,
  });

  useEffect(() => setInitial(members), [members]);

  return (
    <Fragment>
      <FormContainer step="1" title="Componenti" images={[nucleo]}>
        {data.map((componente, index) => (
          <Componente
            key={`${index + 1}`}
            numeroComponente={index}
            age={componente}
            error={validations[index].state === validationStates.WRONG}
            helperText={
              validations[index].state === validationStates.WRONG
                ? "Valore di età non corretto"
                : undefined
            }
          />
        ))}
        <MuiThemeProvider theme={alternative}>
          <Button
            onClick={() => dispatch(setFilter("members", members.push("")))}
            className={classes.aggiungiComponente}
            color="secondary"
          >
            <AddCircleOutline className={classes.aggiungiComponenteIcon} />
            Aggiungi componente
          </Button>
        </MuiThemeProvider>
      </FormContainer>
      <NavigationBar
        disabled={!isValid}
        nextHref="#/admin/nucleo/cittadinanza"
      />
    </Fragment>
  );
}

NucleoRicercaComponenti.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(NucleoRicercaComponenti));
