import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography,
  withStyles,
} from "@material-ui/core";
import styles from "./style.js";
import BackButton from "../../../../components/back-button";
import { useAdminTags } from "./index.hooks";
import { CreateTagDialog } from "../../../../components/create-tag-dialog";
import { EditRounded, DeleteRounded } from "@material-ui/icons";
import { EditTagDialog } from "../../../../components/edit-tag-dialog";
import { DeleteTagDialog } from "../../../../components/delete-tag-dialog";

const AdminTags = ({ classes }) => {
  const {
    tagsList,
    onCreateTagClick,
    onCreateTagModalClose,
    tagToEdit,
    onEditTagClick,
    onEditTagModalClose,
    tagToDelete,
    onDeleteTagClick,
    onDeleteTagModalClose,
    onTagActiveSwitchChange,
  } = useAdminTags();

  const renderHeading = () => (
    <div>
      <div className={classes.title}>
        <Typography variant="h2">Gestisci i tag</Typography>
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <BackButton
            onClick={() => window.history.back()}
            text="Torna alla home"
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.wrapperWidthLimiter}>
      {renderHeading()}
      <Button
        style={{ marginTop: "2em" }}
        color="primary"
        variant="contained"
        onClick={onCreateTagClick}
      >
        Crea tag
      </Button>
      <div style={{ marginTop: "2em", paddingLeft: "2em" }}>
        <Typography variant="caption" color="textPrimary">
          Disattivando un tag non sarà più assegnabile a delle schede ma sarà
          comunque utilizzabile per filtrare.
        </Typography>
      </div>
      <Paper style={{ marginTop: "2em", padding: "2em" }}>
        {tagsList.length > 0 ? (
          tagsList.map((tag, index) => (
            <div key={tag._id}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ padding: "0 1em" }}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  style={{ width: "fit-content" }}
                >
                  <IconButton
                    onClick={() => onEditTagClick(tag)}
                    style={{
                      display: "inline",
                      color: "#000000",
                      marginRight: "1em",
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                  <Typography display="inline" variant="h3">
                    {tag.name}
                  </Typography>
                </Grid>
                <Grid item xs />
                <Grid
                  item
                  container
                  alignItems="center"
                  style={{ width: "fit-content" }}
                >
                  <Typography
                    variant="caption"
                    display="inline"
                    style={{ color: "#C5C5C5" }}
                  >
                    Attualmente utilizzato da {tag.matchingSchedeCount} sched
                    {tag.matchingSchedeCount === 1 ? "a" : "e"}
                  </Typography>
                  <Switch
                    checked={tag.abilitato}
                    onChange={(ev) =>
                      onTagActiveSwitchChange(tag, ev.target.checked)
                    }
                  />
                  <IconButton
                    disabled={tag.matchingSchedeCount !== 0}
                    style={{
                      color: tag.matchingSchedeCount !== 0 ? "#DDD" : "#FF0000",
                    }}
                    onClick={() => onDeleteTagClick(tag)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </Grid>
              </Grid>
              {index !== tagsList.length - 1 && (
                <Divider style={{ marginTop: "1em", marginBottom: "1em" }} />
              )}
            </div>
          ))
        ) : (
          <Typography color="textPrimary" variant="caption">
            Non è stato trovato nessun tag o non disponi dei permessi necessari
          </Typography>
        )}
      </Paper>
      <CreateTagDialog onCreateTagModalClose={onCreateTagModalClose} />
      <EditTagDialog
        tag={tagToEdit}
        onEditTagModalClose={onEditTagModalClose}
      />
      <DeleteTagDialog
        tag={tagToDelete}
        onDeleteTagModalClose={onDeleteTagModalClose}
      />
    </div>
  );
};

export default withStyles(styles)(AdminTags);
