// ASSETS
import send from "assets/img/send.svg";
import stampa from "assets/img/stampa.svg";

// CONSTANTS
import {
  areaUtenza,
  bisognoAffrontato,
  condizioneEconomica,
  norme,
} from "constants/labels";

// REACT
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Link,
  MuiThemeProvider,
  Paper,
  Popover,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import SegmentedControl from "mui-segmented-control";
import List from "@material-ui/icons/List";
import BackButton from "components/back-button";
import VersioneOperatore from "scenes/scheda-prestazione/components/versione-operatore";
import VersioneUtente from "scenes/scheda-prestazione/components/versione-utente";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { GET_SCHEDE_BY__ID_AJAX_SUCCESS } from "redux/action-types";
import { getIndirizziSchedeAjax, getSchedeBy_idAjax } from "redux/actions";
import {
  getAjaxLoading,
  getMainRouteParams,
  getMe,
  getRestUri,
  getResults,
  getScheda,
} from "redux/getters";

// LIBS
import cn from "classnames";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
// STYLE
import styles from "./style.js";
import { alternative, dark } from "../../themes";

function SchedaPrestazione({ classes }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const restUri = useSelector(getRestUri);
  const _id = useSelector(getMainRouteParams).get("_id");
  const comuneATS = useSelector(getMainRouteParams).get("comuneATS");
  const scheda = useSelector(getScheda);
  const schede = useSelector(getResults);
  const isLoading =
    useSelector(getAjaxLoading(GET_SCHEDE_BY__ID_AJAX_SUCCESS)) || !scheda;
  const dispatch = useDispatch();
  const actionsContainerRef = useRef();
  const [shouldStick, setShouldStick] = useState(false);
  const popoverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const user = useSelector(getMe);
  const [tipo, setTipo] = useState(user ? 1 : 2);

  useEffect(() => {
    if (_id && comuneATS) {
      dispatch(
        getIndirizziSchedeAjax(restUri, {
          query: {
            schedaId: _id,
            ATS: comuneATS,
          },
        }),
      );
    }
  }, [_id, comuneATS]);

  useEffect(() => {
    dispatch(getSchedeBy_idAjax(restUri, _id));
  }, [_id]);

  useEffect(() => {
    setTipo(user ? 1 : 2);
  }, [user]);

  const registerScroll = () => {
    if (actionsContainerRef && actionsContainerRef.current) {
      setShouldStick(
        window.matchMedia("(min-width: 960px)").matches &&
          actionsContainerRef.current.getBoundingClientRect().y < 0,
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", registerScroll);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("scroll", registerScroll);
    };
  }, []);

  const isPresentabile = useMemo(() => {
    if (!scheda) {
      return false;
    }

    const schedaJS = scheda.toJS();

    const isAfterStartingDate = moment(
      schedaJS.inizio_richiesta || moment().subtract(1, "day"),
    ).isBefore(moment());
    const isBeforeEndingDate = moment(
      schedaJS.fine_richiesta || moment().add(1, "day"),
    ).isAfter(moment());
    const isSenzaScadenza = Boolean(schedaJS.senza_scadenza);

    return (
      isAfterStartingDate &&
      (isSenzaScadenza || (!isSenzaScadenza && isBeforeEndingDate))
    );
  }, [scheda]);

  return (
    <Fragment>
      {!isPresentabile && (
        <div className={classes.errorContainer}>
          <div className={classes.errorMessage}>
            <ErrorOutline
              classes={{
                root: classes.errorOutline,
              }}
            />
            <Typography
              className={classes.errorTitle}
              color="contrastText"
              variant="caption"
            >
              In questo momento le richieste non si possono più presentare per
              questa prestazione.
            </Typography>
          </div>
        </div>
      )}
      <div className={classes.cover}>
        <div className={cn(classes.wrapper, classes.coverContent)}>
          {isLoading ? (
            <LinearProgress className={classes.progress} />
          ) : (
            <MuiThemeProvider theme={dark}>
              <Typography
                className={classes.coverTitle}
                color="textPrimary"
                variant="h1"
              >
                {scheda.get("titolo_prestazione")}
              </Typography>
            </MuiThemeProvider>
          )}
        </div>
      </div>
      <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
        <Paper className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <BackButton
                onClick={() => {
                  if (schede.size) {
                    window.history.back();
                  } else {
                    window.location.href = "#/admin/catalogo";
                  }
                }}
                text="Torna ai risultati"
              />
            </Grid>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Grid className={classes.infoScheda} item container xs={12}>
                <Divider className={classes.divider} />
                <Grid item xs={12} md={3}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">N° Scheda: &nbsp;</Typography>
                    <Typography>{scheda.get("id")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">Norme: &nbsp;</Typography>
                    <Typography>
                      {norme[scheda.get("dominio_geografico")]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">
                      Dipende dalla condizione economica: &nbsp;
                    </Typography>
                    <Typography>
                      {
                        condizioneEconomica[
                          scheda.get("dipende_da_condizione_economica")
                        ]
                      }
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">
                      Bisogno affrontato: &nbsp;
                    </Typography>
                    <Typography>
                      {scheda
                        .get("bisogno_prioritario")
                        .map((bp, index) =>
                          index === 0
                            ? bisognoAffrontato[bp.toLowerCase()]
                            : `, ${bisognoAffrontato[bp.toLowerCase()]}`,
                        )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">
                      Area d&#39;utenza: &nbsp;
                    </Typography>
                    <Typography>
                      {scheda
                        .get("area_di_utenza")
                        .map((au, index) =>
                          index === 0
                            ? areaUtenza[au.toLowerCase()]
                            : `, ${areaUtenza[au.toLowerCase()]}`,
                        )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className={classes.infoSchedaContainer}>
                    <Typography variant="caption">
                      Data ultimo aggiornamento:&nbsp;
                    </Typography>
                    <Typography>
                      {moment(scheda.get("updated")).format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                </Grid>
                <Divider className={classes.divider} />
              </Grid>
            )}
            <div className={classes.segmentedControlContainer}>
              {user && (
                <SegmentedControl
                  color="primary"
                  options={[
                    {
                      label: "Versione per i Servizi",
                      value: 1,
                    },
                    {
                      label: "Versione per l'utente",
                      value: 2,
                    },
                  ]}
                  value={tipo}
                  onChange={setTipo}
                  classes={{
                    root: classes.segmentedControlRoot,
                    buttonLabel: classes.segmentedControlLabel,
                    buttonSelected: classes.segmentedControlButtonSelected,
                  }}
                />
              )}
              {tipo === 1 && (
                <MuiThemeProvider theme={alternative}>
                  <Link
                    className={classes.link}
                    color="secondary"
                    component="button"
                    onClick={setOpen}
                  >
                    <List className={classes.listIcon} />
                    <Typography
                      className={classes.buttonText}
                      variant="caption"
                    >
                      Indice dei contenuti
                    </Typography>
                  </Link>
                </MuiThemeProvider>
              )}
            </div>
            <Grid item xs={12}>
              <div
                ref={actionsContainerRef}
                className={
                  shouldStick
                    ? cn(
                        classes.actionsContainerSticky,
                        classes.actionsContainer,
                      )
                    : classes.actionsContainer
                }
              >
                <div
                  className={
                    shouldStick
                      ? cn(classes.actionsSticky, classes.actions)
                      : classes.actions
                  }
                  style={{
                    position: shouldStick ? "fixed" : "relative",
                  }}
                >
                  <Button
                    className={classes.action}
                    onClick={() => window.print()}
                  >
                    <img className={classes.image} src={stampa} alt="step" />
                    <Typography
                      className={classes.actionTypography}
                      variant="caption"
                    >
                      Stampa
                    </Typography>
                  </Button>
                  {scheda && (
                    <CopyToClipboard
                      text={`www.welfareinforma.it/#/scheda/${scheda.get(
                        "_id",
                      )}`}
                    >
                      <Button
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={classes.action}
                        // href={`mailto:?subject=Link a prestazione - ${scheda.get('titolo_pre
                        // stazione')}&body=www.welfareinforma.it/#/scheda/${scheda.get('_id')}`}
                      >
                        <img className={classes.image} src={send} alt="step" />
                        <Typography
                          className={classes.actionTypography}
                          variant="caption"
                        >
                          Copia Link
                        </Typography>
                      </Button>
                    </CopyToClipboard>
                  )}
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    classes={{
                      paper: classes.popover,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.typography}
                    >
                      {
                        "Link copiato. Ora puoi incollarlo in un'email o mandarlo via messaggio."
                      }
                    </Typography>
                  </Popover>
                </div>
              </div>
              {tipo === 1 ? (
                <VersioneOperatore setOpen={setOpen} open={open} />
              ) : (
                <VersioneUtente />
              )}
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.printContainer}>
          {tipo === 1 ? (
            <VersioneOperatore setOpen={() => {}} open={false} />
          ) : (
            <VersioneUtente />
          )}
        </div>
      </div>
    </Fragment>
  );
}

SchedaPrestazione.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(SchedaPrestazione));
