import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    buttonRoot: {
      height: 50,
      minWidth: 300,
      width: '100%',
      marginBottom: 20,
      '@media (min-width: 600px)': {
        width: 300,
      },
    },
  },
  sg,
)
