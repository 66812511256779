import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    buttonText: {
      textTransform: 'none',
    },
    link: {
      display: 'flex',
      cursor: 'pointer',
      '@media print': {
        display: 'none',
      },
    },
  },
  sg,
)
