// LIBS
import immutable from "immutable";

export default immutable.fromJS({
  filters: {
    norme: "t",
    comune: null,
    comuneLabel: "",
    condizioni: "t",
    bisogni: [],
    utenza: [],
    members: [""],
    allEuropean: true,
    residencyPermit: true,
    isee: 0,
    text: "",
    tags: [],
  },
  results: [],
  list: [],
  recenti: [],
  editingItem: null,
  item: null,
  total: 0,
  isDeleteSchedaPopoverOpen: false,
  isDeleteSchedaPopoverConfirmButtonEnabled: true,
});
