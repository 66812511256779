/* eslint-disable */
// REDUX
import { all } from "redux-saga/effects";
import requestSaga from "redux/sagas/ajax/request";
import adminStartupSaga from "redux/sagas/users/admin-startup";
import loginSaga from "redux/sagas/users/login";
import logoutSaga from "redux/sagas/users/logout";
import startupSaga from "redux/sagas/startup";
import feedbackSaga from "redux/sagas/feedback";
import successFeedbackSaga from "redux/sagas/feedback/success";
import timeoutFeedbackSaga from "redux/sagas/feedback/timeout";

export default function* rootSaga() {
  yield all([
    requestSaga(),
    adminStartupSaga(),
    loginSaga(),
    logoutSaga(),
    startupSaga(),
    feedbackSaga(),
    successFeedbackSaga(),
    timeoutFeedbackSaga(),
  ]);
}
