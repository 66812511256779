import {
  GET_USERS_ME_AJAX_SUCCESS,
  GET_ADMIN_USERS_AJAX_SUCCESS,
  SET_USERS_FILTER,
  USER_NOT_LOGGED,
  GET_ADMIN_USERS_BY__ID_AJAX_SUCCESS,
  RESET_USERS_ITEM,
  RESET_USERS_FILTERS,
  AUTHENTICATION_EXPIRED,
} from 'redux/action-types'
import immutable from 'immutable'
import initialState from '../../initial-states/users'

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_FILTER:
      state = state.setIn(
        ['filters', action.param],
        immutable.fromJS(action.value),
      )
      return state
    case GET_USERS_ME_AJAX_SUCCESS:
      state = state.set('isLogged', true)
      state = state.set('me', immutable.fromJS(action.data.user))
      return state
    case GET_ADMIN_USERS_AJAX_SUCCESS:
      state = state.set('list', immutable.fromJS(action.data.users))
      return state
    case GET_ADMIN_USERS_BY__ID_AJAX_SUCCESS:
      state = state.set('item', immutable.fromJS(action.data.user))
      return state
    case USER_NOT_LOGGED:
    case AUTHENTICATION_EXPIRED:
      state = state.set('isLogged', false)
      state = state.set('me', initialState.get('me'))
      return state
    case RESET_USERS_ITEM:
      state = state.set('item', immutable.fromJS(initialState.get('item')))
      return state
    case RESET_USERS_FILTERS:
      state = state.set(
        'filters',
        immutable.fromJS(initialState.get('filters')),
      )
      return state
    default:
      return state
  }
}
