// LIBS
import immutable from 'immutable'

// SERVICES
import {
  getRestUri,
} from 'services/env'

export default immutable.fromJS({
  ajaxLoaders: {},
  restUri: getRestUri(),
  printMode: null,
  feedback: {
    isOpen: false,
    message: null,
    type: null,
  },
})
