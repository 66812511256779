export const editorToolbarConfig = {
  options: ["inline", "blockType", "list", "textAlign", "colorPicker", "link"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
  },
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right"],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_blank",
    options: ["link"],
    linkCallback: undefined,
  },
};

export const rteTranslations = {
  // Generic
  "generic.add": "Inserisci",
  "generic.cancel": "Annulla",

  // BlockType
  "components.controls.blocktype.h1": "Titolo 1",
  "components.controls.blocktype.h2": "Titolo 2",
  "components.controls.blocktype.h3": "Titolo 3",
  "components.controls.blocktype.h4": "Titolo 4",
  "components.controls.blocktype.h5": "Titolo 5",
  "components.controls.blocktype.h6": "Titolo 6",
  "components.controls.blocktype.blockquote": "Citazione",
  "components.controls.blocktype.code": "Codice",
  "components.controls.blocktype.blocktype": "Tipo di testo",
  "components.controls.blocktype.normal": "Normale",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "Color Picker",
  "components.controls.colorpicker.text": "Testo",
  "components.controls.colorpicker.background": "Evidenziatore",

  // Inline
  "components.controls.inline.bold": "Grassetto",
  "components.controls.inline.italic": "Corsivo",
  "components.controls.inline.underline": "Sottolineato",
  "components.controls.inline.strikethrough": "Barrato",

  // List
  "components.controls.list.list": "Lista",
  "components.controls.list.unordered": "Lista non ordinata",
  "components.controls.list.ordered": "Lista ordinata",
  "components.controls.list.indent": "Rientra",
  "components.controls.list.outdent": "Rimuovi rientro",

  // Link
  "components.controls.link.linkTitle": "Titolo del link",
  "components.controls.link.linkTarget": "Indirizzo",
  "components.controls.link.linkTargetOption": "Apri in una nuova scheda",
  "components.controls.link.link": "Link",
  "components.controls.link.unlink": "Rimuovi link",

  // Remove
  "components.controls.remove.remove": "Rimuovi",

  // TextAlign
  "components.controls.textalign.textalign": "Allineamento",
  "components.controls.textalign.left": "Sinistra",
  "components.controls.textalign.center": "Centro",
  "components.controls.textalign.right": "Destra",
};
