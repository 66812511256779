import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    container: {
      marginBottom: 20,
    },
  },
  sg,
)
