// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useCallback, useMemo, useState } from "react";
import { func, number, shape, string } from "prop-types";
import { Trans, translate } from "react-i18next";
import {
  MenuItem,
  TextField,
  Select,
  withStyles,
  Typography,
  Button,
} from "@material-ui/core";
import Form from "components/form";

// REDUX
import { useSelector, useDispatch } from "react-redux";

// LIBS
import cn from "classnames";

// STYLE
import immutable from "immutable";
import styles from "./style.js";
import { DeleteOutlined } from "@material-ui/icons";

function FiltroIsee({
  classes,
  data,
  index,
  name,
  onChange,
  onResetArray,
  validations,
  t,
}) {
  const updateFilter = (value, key) => {
    if (key === "isee") {
      if (value) {
        data = data && data.set(key, value);
        onChange(name, data, index);
      } else {
        data = data && data.set("isee", null);
        data = data && data.set(key, value);
        onChange(name, data, index);
      }
    } else if (key === "iseeOp") {
      if (value) {
        data = data && data.set(key, value);
        onChange(name, data, index);
      } else {
        onResetArray(name);
      }
    }
  };

  const isFilterPresent = useMemo(() => {
    return data && (data.get("iseeOp") || data.get("isee"));
  }, [data]);

  const onRemoveFilterClick = useCallback(() => {
    if (data) {
      data = data.set("isee", "");
      data = data.set("iseeOp", null);

      onChange(name, data, index);
    }
  }, [data]);

  return (
    <div>
      {/*
      <Typography variant="caption">Numero componenti</Typography>
      <TextField
        className={cn(classes.marginLeft, classes.number)}
        value={data ? data.get('members') : ''}
        onChange={(ev) => updateFilter(ev.target.value, 'members')}
        error={validations.state === Form.validationStates.WRONG}
      />
      <Typography variant="caption">di età</Typography>
      <Select
        className={classes.marginLeft}
        value={data ? data.get('ageOp') : ''}
        onChange={(ev) => updateFilter(ev.target.value, 'ageOp')}
        error={validations.state === Form.validationStates.WRONG}
      >
        <MenuItem value=">">maggiore</MenuItem>
        <MenuItem value=">=">maggiore o uguale</MenuItem>
        <MenuItem value="<">minore</MenuItem>
        <MenuItem value="<=">minore o uguale</MenuItem>
        <MenuItem value="==">uguale</MenuItem>
      </Select>
      <Typography variant="caption">di</Typography>
      <TextField
        className={classes.marginLeft}
        value={data ? data.get('age') : ''}
        onChange={(ev) => updateFilter(ev.target.value, 'age')}
        error={validations.state === Form.validationStates.WRONG}
      />
         */}
      <Typography variant="caption">ISEE</Typography>
      <Select
        className={classes.marginLeft}
        value={data ? data.get("iseeOp") : ""}
        onChange={(ev) => updateFilter(ev.target.value, "iseeOp")}
        error={validations.state === Form.validationStates.WRONG}
      >
        <MenuItem value={null}>{"-"}</MenuItem>
        <MenuItem value=">">{">"}</MenuItem>
        <MenuItem value=">=">{">="}</MenuItem>
        <MenuItem value="<">{"<"}</MenuItem>
        <MenuItem value="<=">{"<="}</MenuItem>
        <MenuItem value="==">=</MenuItem>
      </Select>
      <Typography variant="caption">di</Typography>
      <TextField
        className={classes.marginLeft}
        value={data ? data.get("isee") : ""}
        error={validations.state === Form.validationStates.WRONG}
        onChange={(ev) => updateFilter(ev.target.value, "isee")}
      />
      {isFilterPresent && (
        <Button onClick={onRemoveFilterClick}>
          <DeleteOutlined style={{ color: "#F00" }} />
        </Button>
      )}
    </div>
  );
}

FiltroIsee.propTypes = {
  classes: shape({}).isRequired,
  data: shape({}).isRequired,
  index: number.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  t: func.isRequired,
  validations: shape({}).isRequired,
};

export default withStyles(styles)(translate()(FiltroIsee));
