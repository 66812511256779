import _ from 'lodash'
import sg from 'styles'
import background2 from 'assets/img/background2.png'

export default () => _.merge(
  {
    header: {
      height: 140,
      padding: 40,
      alignItems: 'center',
      backgroundImage: `url(${background2})`,
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    container: {
      backgroundColor: 'rgba(23, 21, 54, 0.9)',
    },
    list: {
      color: '#fff',
      paddingLeft: 40,
    },
    listItem: {
      maxWidth: 500,
    },
    listItemLevel1: {
      marginLeft: 20,
    },
    title: {
      fontWeight: 400,
    },
    link: {
      display: 'flex',
      alignItems: 'baseline',
      textAlign: 'left',
    },
  },
  sg,
)
