import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      paper: {
        width: "100%",
        borderRadius: 5,
        padding: "10px 20px",
        marginTop: 10,
      },
      menuPopover: {
        zIndex: 100,
      },
      menuPaper: {
        background: "#fff",
      },
      textfield: {
        background: "#fff",
      },
      input: {
        color: "#000",
        zIndex: 1,
      },
      customTextField: {
        marginBottom: 30,
      },
      editorContainer: {
        "& .rdw-editor-main": {
          height: 190,
          border: "1px solid rgba(0, 0, 0, 0.42)",
        },
      },
      rteContainer: {
        "& span, & p, & span *, & p *": {
          fontSize: 16,
        },
        "& h1, & h1 *": {
          fontSize: 26,
        },
        "& h2, & h2 *": {
          fontSize: 22,
        },
        "& h3, & h3 *": {
          fontSize: 18,
        },
      },
    },
    sg,
  );
