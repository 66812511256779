// REDUX
import { put, select, take, takeEvery } from "redux-saga/effects";
import { userLogged, userNotLogged } from "redux/actions/auth";
import { getUsersMeAjax } from "../../actions/ajax";
import { getRestUri } from "../../getters/global";

import {
  APP_STARTUP,
  AUTHENTICATION_EXPIRED,
  GET_USERS_ME_AJAX_SUCCESS,
  GET_USERS_ME_AJAX_FAILED,
} from "../../action-types";

export function* startup() {
  const state = yield select();
  const restUri = getRestUri(state);
  yield put(getUsersMeAjax(restUri));
  const action = yield take([
    AUTHENTICATION_EXPIRED,
    GET_USERS_ME_AJAX_SUCCESS,
    GET_USERS_ME_AJAX_FAILED,
  ]);
  if (action.type === GET_USERS_ME_AJAX_SUCCESS) {
    yield put(userLogged());
  }
}

export default function* startupSaga() {
  yield takeEvery(APP_STARTUP, startup);
}
