import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    buttonsContainer: {
      width: '100%',
      display: 'block',
      justifyContent: 'space-between',
      marginTop: 30,
      '@media (min-width: 600px)': {
        display: 'flex',
      },
    },
    singleButton: {
      justifyContent: 'flex-end',
      width: '100%',
    },
    icon: {
      width: '1.7em',
      height: '1.7em',
      marginLeft: 5,
    },
  },
  sg,
)
