/* eslint-disable */
// REACT
import React, { useState, useEffect } from "react";
import { array, object, func } from "prop-types";

// LIBS
import immutable from "immutable";
import joi from "joi-browser";

const validationStates = {
  CORRECT: "correct",
  WRONG: "wrong",
  EMPTY: "empty",
};

const Form = (props) => {
  const [data, setData] = useState(
    immutable.Iterable.isIterable(props.initial)
      ? props.initial
      : immutable.fromJS(props.initial) || immutable.Map(),
  );
  const getInitialValidations = () => {
    const tmpValidations = {};
    data
      .keySeq()
      .toArray()
      .map(
        (field) =>
          (tmpValidations[field] = {
            state: validationStates.EMPTY,
            error: null,
          }),
      );
    return tmpValidations;
  };
  const [validations, setValidations] = useState([
    false,
    getInitialValidations(),
  ]);

  const joiValidate = () =>
    new Promise((resolve, reject) => {
      const tempValidations = getInitialValidations();
      joi.validate(
        data.toJS(),
        props.joiValidationSchema,
        {
          abortEarly: false,
        },
        (err, data) => {
          if (err) {
            err.details.map((item) => {
              const { object, field } = getChild(data, item.path);
              if (typeof object[field] === "undefined") {
                return reject(`Error: field not found. ${field}`);
              }
              if (object[field].toString().length === 0) {
                tempValidations[item.path] = {
                  state: validationStates.EMPTY,
                  error: null,
                };
              } else {
                tempValidations[item.path] = {
                  state: validationStates.WRONG,
                  error: item.message,
                };
              }
            });
          }
          return resolve({
            tempIsValid: !err,
            tempValidations,
          });
        },
      );
    });

  const getChild = (object, path) => {
    let item;
    if (path.length > 1) {
      item = object[path[0]];
      for (let i = 1; i < path.length - 1; i++) {
        item = item[path[i]];
      }
      return {
        object: item,
        field: path.pop(),
      };
    }
    return {
      object,
      field: path[0],
    };
  };

  const onChange = (...args) => {
    const [name, value, index] = args;
    if (index >= 0) {
      setData(data.setIn([name, index], immutable.fromJS(value)));
    } else {
      setData(data.set(name, immutable.fromJS(value)));
    }
  };

  const onChangeEvent = (...args) => {
    const [event] = args;
    const { name, type, checked, value } = event.target;
    setData(data.set(name, type === "checkbox" ? checked : value));
  };

  useEffect(() => {
    if (props.joiValidationSchema) {
      joiValidate()
        .then(({ tempIsValid, tempValidations }) => {
          setValidations([tempIsValid, tempValidations]);
        })
        .catch((ex) => {
          throw ex;
        });
    }
  }, [data]);

  const reset = () => setData(immutable.fromJS(props.initial || {}));

  const onSubmit = (ev) => props.onSubmit(data, ev);
  return (
    <form onSubmit={onSubmit}>
      {props.children(
        data,
        onChange,
        onChangeEvent,
        reset,
        validations[0],
        validations[1],
      )}
    </form>
  );
};

Form.propTypes = {
  children: func,
  initial: object,
  joiValidationSchema: object,
  onSubmit: func,
};

Form.validationStates = validationStates;

export default Form;
