import {
  GET_ADMIN_SCHEDE_AJAX_SUCCESS,
  GET_SCHEDE__IDS_AJAX_SUCCESS,
  GET_SCHEDE_AJAX_SUCCESS,
  GET_SCHEDE_BY__ID_AJAX_SUCCESS,
  RESET_FILTER,
  SET_FILTER,
  USER_NOT_LOGGED,
  GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_SUCCESS,
  DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS,
  DELETE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED,
  DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST,
  SET_DELETE_SCHEDE_POPOVER_OPEN,
  GET_SCHEDE_RECENTI_AJAX_SUCCESS,
} from "redux/action-types";
import immutable from "immutable";
import { EDIT_CHAPTER } from "redux/action-types/admin";
import initialState from "../../initial-states/schede";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      state = state.setIn(
        ["filters", action.param],
        immutable.fromJS(action.value),
      );
      return state;
    case GET_ADMIN_INDIRIZZI_SCHEDE_AJAX_SUCCESS:
    case GET_ADMIN_SCHEDE_AJAX_SUCCESS:
    case GET_SCHEDE_AJAX_SUCCESS:
      state = state.set("results", immutable.fromJS(action.data.schede));
      state = state.set("total", immutable.fromJS(action.data.count));

      if (action.data.schede.length === 0) {
        state = state.set("list", initialState.get("list"));
      }
      return state;
    case GET_SCHEDE_RECENTI_AJAX_SUCCESS:
      state = state.set(
        "recenti",
        immutable.fromJS(action.data.schedeRecenti || []),
      );

      return state;
    case GET_SCHEDE__IDS_AJAX_SUCCESS:
      state = state.set("list", immutable.fromJS(action.data.schede));
      return state;
    case GET_SCHEDE_BY__ID_AJAX_SUCCESS:
      state = state.set("item", immutable.fromJS(action.data.scheda));
      return state;
    case EDIT_CHAPTER:
      state = state.setIn(["editingItem", action.textId], action.editorContent);
      return state;
    case RESET_FILTER:
      state = state.set("filters", initialState.get("filters"));
      return state;
    case USER_NOT_LOGGED:
      state = state.set("results", initialState.get("results"));
      state = state.set("list", initialState.get("list"));
      return state;
    case DELETE_ADMIN_SCHEDE_BY__ID_AJAX_REQUEST:
      state = state.set("isDeleteSchedaPopoverConfirmButtonEnabled", true);
      return state;
    case DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS:
      state = state.set("isDeleteSchedaPopoverOpen", false);
      state = state.set("isDeleteSchedaPopoverConfirmButtonEnabled", true);
      return state;
    case DELETE_ADMIN_SCHEDE_BY__ID_AJAX_FAILED:
      state = state.set("isDeleteSchedaPopoverConfirmButtonEnabled", true);
      return state;
    case SET_DELETE_SCHEDE_POPOVER_OPEN:
      state = state.set("isDeleteSchedaPopoverOpen", action.isOpen);
      return state;
    default:
      return state;
  }
};
