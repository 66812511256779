// REDUX
/* eslint-disable */

import { all, put, select, take, takeEvery } from "redux-saga/effects";
import {
  AUTHENTICATION_EXPIRED,
  CREATE_USERS_TOKENS_AJAX_SUCCESS,
  GET_USERS_ME_AJAX_SUCCESS,
  GET_USERS_ME_AJAX_FAILED,
} from "../../action-types";
import { getUsersMeAjax } from "../../actions/ajax";
import { getRestUri } from "redux/getters";

export function* loginSaga() {
  yield takeEvery(CREATE_USERS_TOKENS_AJAX_SUCCESS, function* () {
    const state = yield select();
    const restUri = getRestUri(state);
    yield put(getUsersMeAjax(restUri));
    const action = yield take([
      AUTHENTICATION_EXPIRED,
      GET_USERS_ME_AJAX_SUCCESS,
      GET_USERS_ME_AJAX_FAILED,
    ]);

    if (action.type === GET_USERS_ME_AJAX_SUCCESS) {
      setTimeout(() => (window.location.href = "#/admin"));
    }
  });
}

export default function* rootSaga() {
  yield all([loginSaga()]);
}
