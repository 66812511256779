import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    container: {
      width: '100%',
      background: '#FFF',
      position: 'absolute',
      bottom: 0,
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: 20,
      '@media print': {
        display: 'none',
        position: 'fixed',
        bottom: 0,
        paddingBottom: 0,
        height: 100,
      },
    },
    firstRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      marginBottom: 15,
    },
    thirdRow: {
      marginTop: 15,
      alignItems: 'center !important',
      fontSize: '14px !important',
    },
    logoActionAid: {
      height: 40,
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '&>p': {
        fontSize: '14px !important',
      },
      '@media (min-width: 600px)': {},
    },
    loghi: {
      display: 'block',
      '@media print': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    firstRowLoghi: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    row: {
      width: 130,
    },
    logoQuadrato: {
      height: 50,
      margin: '5px 0',
      '@media print': {
        width: 30,
        margin: '5px 15px',
      },
    },
    footerWrapper: {
      width: '95%',
      maxWidth: 1000,
      margin: '0 auto',
      '@media print': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  sg,
)
