// REDUX
/* eslint-disable */

import { all, put, select, take, takeEvery } from "redux-saga/effects";
import {
  ADMIN_STARTUP,
  USER_LOGGED,
  USER_NOT_LOGGED,
} from "redux/action-types";
import { getUsersMeAjax, updateAccessToAdminRouter } from "redux/actions";
import { getIsLogged } from "redux/getters";

export function* adminStartupSaga() {
  yield takeEvery(ADMIN_STARTUP, function* () {
    let state = yield select();
    let isLogged = getIsLogged(state);
    if (isLogged === null) {
      const action = yield take([USER_LOGGED, USER_NOT_LOGGED]);
      if (action.type === USER_NOT_LOGGED) {
        isLogged = false;
      } else {
        isLogged = true;
      }
    }

    if (!isLogged) {
      setTimeout(() => (window.location.href = "#/admin/login"));
    }
  });
}

export default function* rootSaga() {
  yield all([adminStartupSaga()]);
}
