import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      background: {
        backgroundColor: "rgba(23, 21, 54, 0.8)",
        color: "#fff",
        padding: "40px 85px 40px 85px",
        width: "100%",
        minHeight: 313,
        marginTop: 30,
        marginBottom: 0,
        "@media (max-width: 600px)": {
          padding: "40px 29px 40px 29px",
        },
      },
      formControl: {
        width: "100%",
      },
      norme: {
        "@media (max-width: 600px)": {
          display: "none",
        },
      },
      normeMobile: {
        "@media (min-width: 600px)": {
          display: "none",
        },
      },
      segmentedControlRoot: {
        marginTop: 15,
        background: "#fff",
        border: "0",
      },
      segmentedControlLabel: {
        color: theme.palette.secondary.main,
        textTransform: "none",
      },
      segmentedControlButtonSelected: {
        "&>span": {
          color: "#fff",
          transition: "color ease-in .1s",
        },
      },
      segmentedControlSelector: {
        transition: "all ease-in .1s",
      },
      menuPopover: {
        zIndex: 100,
      },
      menuPaper: {
        background: "#fff",
      },
      textfield: {
        background: "#fff",
      },
      input: {
        color: "#000",
        zIndex: 1,
      },
      disabledInput: {
        color: "#AAA",
        zIndex: 1,
      },
      chip: {
        marginTop: 10,
      },
    },
    sg,
  );
