// LIBS
import _ from 'lodash'
// REDUX
import {
  HASH_ROUTING_SWITCH_ROUTE,
} from 'react-redux-hash-router'
import initialState from '../../initial-states/global'
import {
  SET_PRINT_MODE,
  UPDATE_AJAX_LOADING,
  SHOW_FEEDBACK,
  CLOSE_FEEDBACK,
} from '../../action-types/global'

export default (state = initialState, action) => {
  let api
  let isLoading
  let uid
  let feedback

  switch (action.type) {
    case UPDATE_AJAX_LOADING:
      ({
        api, isLoading, uid,
      } = action)
      state = state.setIn(['ajaxLoaders', `${api}_${uid}`], isLoading)
      return state
    case SET_PRINT_MODE:
      state = state.setIn(['printMode'], action.printMode)
      return state
    case HASH_ROUTING_SWITCH_ROUTE:
      state = state.setIn(['printMode'], initialState.get('printMode'))
      return state
    case SHOW_FEEDBACK:
      feedback = _.get(action, 'feedback', null)
      if (feedback) {
        state = state.setIn(['feedback', 'isOpen'], true)
        state = state.setIn(['feedback', 'message'], feedback.message)
        state = state.setIn(['feedback', 'type'], feedback.type)
      }
      return state
    case CLOSE_FEEDBACK:
      state = state.setIn(['feedback', 'isOpen'], false)
      return state
    default:
      return state
  }
}
