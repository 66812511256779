import { useDispatch, useSelector } from "react-redux";
import {
  getIsCreateTagDialogOpen,
  getIsDeleteTagDialogOpen,
  getIsEditTagDialogOpen,
  getRestUri,
  getTagsList,
} from "../../../../redux/getters";
import { useCallback, useEffect, useState } from "react";
import {
  getAdminTagsAjax,
  updateAdminTagsBy_idAjax,
} from "../../../../redux/actions";
import {
  setIsCreateTagDialogOpen,
  setIsDeleteTagDialogOpen,
  setIsEditTagDialogOpen,
} from "../../../../redux/actions/tags";

export const useAdminTags = () => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const tagsList = useSelector(getTagsList);

  const [tagToEdit, setTagToEdit] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null);

  const onCreateTagClick = useCallback(
    () => dispatch(setIsCreateTagDialogOpen(true)),
    [dispatch],
  );
  const onCreateTagModalClose = useCallback(
    () => dispatch(setIsCreateTagDialogOpen(false)),
    [dispatch],
  );

  const onEditTagClick = useCallback((tag) => {
    setTagToEdit(tag);
    dispatch(setIsEditTagDialogOpen(true));
  }, []);
  const onEditTagModalClose = useCallback(() => {
    setTagToEdit(null);
    dispatch(setIsEditTagDialogOpen(false));
  }, []);

  const onDeleteTagClick = useCallback((tag) => {
    setTagToDelete(tag);
    dispatch(setIsDeleteTagDialogOpen(true));
  }, []);
  const onDeleteTagModalClose = useCallback(() => {
    setTagToDelete(null);
    dispatch(setIsDeleteTagDialogOpen(false));
  }, []);

  const onTagActiveSwitchChange = useCallback(
    (tag, checked) =>
      dispatch(
        updateAdminTagsBy_idAjax(restUri, tag._id, { abilitato: checked }),
      ),
    [],
  );

  useEffect(() => {
    dispatch(getAdminTagsAjax(restUri));
  }, []);

  return {
    tagsList,
    onCreateTagClick,
    onCreateTagModalClose,
    tagToEdit,
    onEditTagClick,
    onEditTagModalClose,
    tagToDelete,
    onDeleteTagClick,
    onDeleteTagModalClose,
    onTagActiveSwitchChange,
  };
};
