import { useDispatch, useSelector } from "react-redux";
import { getIsDeleteTagDialogOpen, getRestUri } from "../../redux/getters";
import { useCallback } from "react";
import { deleteAdminTagsBy_idAjax } from "../../redux/actions";

export const useDeleteTagDialog = (tag) => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const isDeleteTagModalOpen = useSelector(getIsDeleteTagDialogOpen);

  const onDeletionConfirmed = useCallback(
    () => dispatch(deleteAdminTagsBy_idAjax(restUri, tag._id)),
    [dispatch, tag],
  );

  return { isDeleteTagModalOpen, onDeletionConfirmed };
};
