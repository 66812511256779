import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    componenteContainer: {
      '&:not(:first-child)': {
        marginTop: 20,
      },
    },
    numeroComponenteLabel: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.secondary.main,
      marginBottom: 9,
    },
    divider: {
      width: '100%',
    },
    deleteForever: {
      width: 24,
      height: 24,
      marginRight: 5,
    },
    button: {
      textTransform: 'none',
    },
    buttonContainer: {
      padding: '0 0 12px !important',
      '@media (min-width: 600px)': {
        marginBottom: 10,
        padding: '12px 0 !important',
      },
    },
  },
  sg,
)
