import _ from 'lodash'
import sg from 'styles'
import background1 from 'assets/img/background1.png'

export default (theme) => _.merge(
  {
    cover: {
      height: 120,
      backgroundImage: `url(${background1})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      '@media (min-width: 600px)': {
        height: 180,
      },
    },
    coverContent: {
      textAlign: 'center',
    },
    container: {
      '@media (min-width: 600px)': {
        marginTop: -40,
      },
    },
    paper: {
      width: '100%',
      padding: '50px 70px 50px',
    },
    content: {
      '&:not(:last-child)': {
        marginBottom: 45,
      },
    },
    image: {
      width: '100%',
    },
    titleContainer: {
      display: 'flex',
      marginBottom: 30,
      fontSize: '18px !important',
    },
    title: {
      lineHeight: 1.25,
    },
  },
  sg,
)
