import {
  GET_ADMIN_TAGS_AJAX_SUCCESS,
  CREATE_ADMIN_TAGS_AJAX_SUCCESS,
  UPDATE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS,
  DELETE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS,
  SET_IS_CREATE_TAG_DIALOG_OPEN,
  SET_IS_DELETE_TAG_DIALOG_OPEN,
  SET_IS_EDIT_TAG_DIALOG_OPEN,
} from "redux/action-types";
import immutable from "immutable";
import initialState from "../../initial-states/tags";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_TAGS_AJAX_SUCCESS:
      state = state.set("list", immutable.fromJS(action.data.tags));
      return state;
    case CREATE_ADMIN_TAGS_AJAX_SUCCESS:
      state = state.updateIn(["list"], (list) =>
        list.push(immutable.fromJS(action.data.tag)),
      );
      state = state.set("isCreateTagDialogOpen", false);
      return state;
    case UPDATE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS:
      state = state.updateIn(["list"], (list) =>
        list.map((item) => {
          if (item.get("_id") === action.data.tag._id) {
            return immutable.fromJS(action.data.tag);
          }
          return item;
        }),
      );
      state = state.set("isEditTagDialogOpen", false);
      return state;
    case DELETE_ADMIN_TAGS_BY__ID_AJAX_SUCCESS:
      state = state.updateIn(["list"], (list) =>
        list.filter((item) => item.get("_id") !== action.data.deletedId),
      );
      state = state.set("isDeleteTagDialogOpen", false);
      return state;
    case SET_IS_CREATE_TAG_DIALOG_OPEN:
      state = state.set("isCreateTagDialogOpen", action.payload);
      return state;
    case SET_IS_EDIT_TAG_DIALOG_OPEN:
      state = state.set("isEditTagDialogOpen", action.payload);
      return state;
    case SET_IS_DELETE_TAG_DIALOG_OPEN:
      state = state.set("isDeleteTagDialogOpen", action.payload);
      return state;
    default:
      return state;
  }
};
