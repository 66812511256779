import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    getapperWrapper: {
      '@media (max-width: 600px)': {
        position: 'absolute !important',
      },
      '@media print': {
        display: 'none !important',
      },
      '&>span': {
        padding: '0px !important',
      },
    },
    snackbarContainer: {
      top: 0,
      marginTop: '81px !important',
    },
    snackbar: {
      width: '100%',
      borderRadius: 0,
      top: 0,
    },
    message: {
      display: 'flex',
      alignItems: 'center',

      '&>img': {
        marginLeft: 40,
        marginRight: 30,

        '@media (max-width: 600px)': {
          marginLeft: 20,
          marginRight: 15,
        },
      },
    },
    snackbarTypography: {
      fontFamily: '"Jost*", sans-serif',
      fontSize: 21,
      fontWeight: 600,

      '@media (max-width: 600px)': {
        fontSize: 16,
      },
    },
    feedbackError: {
      'background-color': '#BF6126',
    },
    feedbackSuccess: {
      'background-color': '#26BF31',
    },
  },
  sg,
)
