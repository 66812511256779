/* eslint-disable */
// REDUX
import { all, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_ADMIN_USERS_AJAX_SUCCESS,
  UPDATE_ADMIN_USERS_BY__ID_AJAX_SUCCESS,
  CREATE_ADMIN_SCHEDE_AJAX_SUCCESS,
  UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS,
  CREATE_ADMIN_INDIRIZZI_AJAX_SUCCESS,
  UPDATE_USERS_PASSWORD_AJAX_SUCCESS,
  DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS,
} from "../../action-types";
import { showFeedback } from "../../actions";

export function* success() {
  yield takeEvery(
    [
      UPDATE_ADMIN_USERS_BY__ID_AJAX_SUCCESS,
      CREATE_ADMIN_USERS_AJAX_SUCCESS,
      CREATE_ADMIN_SCHEDE_AJAX_SUCCESS,
      UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS,
      DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS,
      CREATE_ADMIN_INDIRIZZI_AJAX_SUCCESS,
      UPDATE_USERS_PASSWORD_AJAX_SUCCESS,
    ],
    function* (action) {
      let message = "";
      let href = null;
      switch (action.type) {
        case CREATE_ADMIN_USERS_AJAX_SUCCESS:
          message = "Utente creato correttamente";
          href = "#/admin/utenti";
          break;
        case UPDATE_ADMIN_USERS_BY__ID_AJAX_SUCCESS:
          message = "Utente modificato correttamente";
          href = "#/admin/utenti";
          break;
        case CREATE_ADMIN_SCHEDE_AJAX_SUCCESS:
          message = "Scheda creata con successo";
          href = "#/admin/schede";
          break;
        case UPDATE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS:
          message = "Scheda modificata con successo";
          href = "javascript:";
          break;
        case UPDATE_USERS_PASSWORD_AJAX_SUCCESS:
          message = "Password modificata correttamente";
          href = "#/admin/login";
          break;
        case DELETE_ADMIN_SCHEDE_BY__ID_AJAX_SUCCESS:
          message = "Scheda eliminata con successo";
          href = "#/admin/schede";
          break;
        default:
          message = "Azione compiuta con successo";
      }

      yield put(
        showFeedback({
          message,
          type: "success",
        }),
      );

      if (!href) {
        setTimeout(() => (window.location.href = "#/admin"));
      } else {
        setTimeout(() => (window.location.href = href));
      }
    },
  );
}

export default function* rootSaga() {
  yield all([success()]);
}
