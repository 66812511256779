import _ from "lodash";
import sg from "styles";
import background1 from "assets/img/background1.png";

export default () =>
  _.merge(
    {
      cover: {
        height: 405,
        backgroundImage: `url(${background1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 40,
        "@media (min-width: 600px)": {
          height: 180,
          display: "flex",
          alignItems: "center",
          paddingTop: 0,
        },
      },
      coverContent: {
        textAlign: "center",
      },
      subheader: {
        backgroundColor: "#fff",
      },
      subtitle: {
        marginTop: 10,
      },
      warning: {
        marginTop: 40,
        padding: "31px 59px",
        minHeight: 200,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: "#fff",
        borderRadius: 10,
      },
      warningTitle: {
        marginBottom: 20,
      },
      container: {
        marginTop: -340,
        "@media (min-width: 600px)": {
          marginTop: -40,
        },
      },
      infoText: {
        marginTop: 60,
        lineHeight: "1.3em",
        fontSize: 20,
        "& p": {
          padding: "10px 0",
        },
        "& li": {
          listStyle: "disc",
        },
      },
      icon: {
        position: 'absolute',
        top: -60,
        width: 200,
        height: "auto",
        '@media (max-width: 600px)': {
          width: 100,
          height: 100,
        },
      },
      icon2: {
        position: 'absolute',
        top: -50,
        '@media (max-width: 600px)': {
          width: 100,
          height: 100,
        },
      },
    },
    sg,
  );
