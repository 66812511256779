import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    paper: {
      marginBottom: 60,
      borderRadius: 5,
      '@media print': {
        borderRadius: 0,
        border: 'none',
        width: '100%',
        boxShadow: 'none',
      },
    },
    paperPrintMode: {
      '@media print': {
        marginBottom: 60,
        marginTop: 20,
      },
    },
    list: {
      borderRadius: 5,
      padding: '0 0 0 20px',
      backgroundColor: 'white',
      '@media (min-width: 600px)': {
        padding: '0 0 0 30px',
      },
    },
    listItem: {
      minHeight: 70,
      padding: '10px 0',
      '&:hover': {
        backgroundColor: 'rgba(83,120,178, 0.05)',
      },
      '@media (max-width: 600px)': {
        minHeight: 60,
      },
      '@media print': {
        pageBreakInside: 'avoid',
      },
    },
    checkBox: {
      height: 30,
      width: 30,
    },
    checkBoxUnchecked: {
      color: '#E7E7EA',
    },
    checkBoxOutlineBlank: {
      height: 30,
      width: 30,
    },
    listItemText: {
      marginLeft: 23,
    },
    listItemTextTypographyChecked: {
      fontWeight: 600,
    },
    listItemIcon: {
      minWidth: 0,
      marginLeft: 10,
      '@media (min-width: 600px)': {
        marginLeft: 30,
      },
    },
    listItemTextTypography: {
      color: theme.palette.primary.main,
      width: 'calc(100% - 90px)',
    },
    circle: {
      display: 'none',
      '@media (min-width: 600px)': {
        height: 46,
        width: 46,
        borderRadius: 30,
        position: 'absolute',
        left: -53,
        backgroundColor: '#5378B2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      },
      '@media print': {
        display: 'none',
      },
    },
    chevronRight: {
      position: 'absolute',
      right: 10,
      width: 24,
      height: 24,
      color: theme.palette.secondary.main,
      '@media (min-width: 600px)': {
        right: 40,
      },
    },
    errorOutline: {
      position: 'absolute',
      right: 40,
      width: 24,
      height: 24,
      color: theme.palette.error.main,
      '@media (min-width: 600px)': {
        right: 70,
      },
    },
    divider: {
      color: theme.palette.secondary.main,
      '@media (min-width: 600px)': {
        margin: '0 30px',
      },
    },
    filterLabel: {
      marginLeft: 10,
      textTransform: 'none',
    },
    icon: {
      marginRight: 10,
    },
    appBar: {
      width: '100%',
      bottom: 0,
      height: 200,
      left: 0,
      top: 'initial',
      zIndex: 9999999999999,
      backgroundColor: '#171536',
      opacity: 0.85,
      '@media (min-width: 600px)': {
        height: 120,
      },
      '@media print': {
        display: 'none',
      },
    },
    toolbar: {
      width: '100%',
      margin: 'auto',
      justifyContent: 'space-evenly',
      display: 'block',
      '@media (min-width: 600px)': {
        display: 'flex',
        height: '100%',
      },
      '@media print': {
        display: 'none',
      },
    },
    textFieldInput: {
      background: 'white',
    },
    searchOutlined: {
      fill: '#E2E2E2',
      marginLeft: 10,
    },
    layer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 90,
      background: 'rgba(0,0,0,.2)',
      '@media print': {
        display: 'none',
      },
    },
    scheda: {
      padding: 20,
      '@media print': {
        '&:not(:last-child)': {
          pageBreakAfter: 'always',
        },
      },
    },
    schedaText: {
      marginTop: 20,
    },
    buttonAction: {
      textTransform: 'none',
      fontSize: 18,
      '@media (max-width: 600px)': {
        padding: 8,
      },
    },
    iconButton: {
      backgroundColor: 'white',
      width: 50,
      height: 50,
      borderRadius: 25,
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    tooltip: {
      backgroundColor: 'rgba(23, 21, 54, 0.9)',
      padding: 20,
    },
    noResults: {
      margin: '40px 0',
    },
  },
  sg,
)
