import _ from "lodash";
import sg from "styles";

export default () =>
  _.merge(
    {
      content: {
        marginTop: 30,
      },
      buttonText: {
        textTransform: "none !important",
        marginLeft: 20,
      },
      title: {
        marginBottom: 30,
        fontSize: 28,
        marginTop: 40,
        "@media (min-width: 600px)": {
          marginTop: 10,
          "&:not(:first-child)": {
            marginTop: 40,
          },
        },
        "@media print": {
          fontSize: 20,
          marginBottom: 10,
          marginTop: 10,
        },
      },
      subtitle: {
        fontSize: 20,
      },
      progress: {
        width: "100%",
        margin: "0 auto",
      },
      contentIndirizzi: {
        "@media print": {
          display: "none",
        },
      },
      contentIndirizziPrint: {
        display: "none",
        "@media print": {
          display: "table",
        },
      },
      rteContainer: {
        "& span, & p, & span *, & p *": {
          fontSize: 16,
        },
        "& h1, & h1 *": {
          fontSize: 26,
        },
        "& h2, & h2 *": {
          fontSize: 22,
        },
        "& h3, & h3 *": {
          fontSize: 18,
        },
      },
    },
    sg,
  );
