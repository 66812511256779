export default {
  wrapper: {
    width: "100%",
    margin: "0 auto",
    "@media print": {
      maxWidth: "100%",
      width: "100%",
    },
  },
  wrapperWidthLimiter: {
    width: "95%",
    maxWidth: 1000,
    margin: "0 auto",
  },
  w100: {
    width: "100%",
  },
  fw400: {
    fontWeight: 400,
  },
  MT20: {
    marginTop: 20,
  },
  ML20: {
    marginLeft: 20,
  },
  M20: {
    margin: 20,
  },
};
