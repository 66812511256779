/* eslint-disable camelcase */
import {
  GET_COMUNI_AJAX_CANCEL,
  RESET_COMUNI,
} from 'redux/action-types/comuni'

export const getComuniAjaxCancel = () => ({
  type: GET_COMUNI_AJAX_CANCEL,
})

export const resetComuni = () => ({
  type: RESET_COMUNI,
})
