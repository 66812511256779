// CONSTANTS
import chapters from 'constants/chapters'
// REACT
import React from 'react'
import {
  func, shape, bool,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Drawer,
  List,
  ListItem,
  Link,
  MuiThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core'

// LIBS
import cn from 'classnames'

// STYLE
import styles from './style.js'
import {
  dark,
} from '../../../../themes'

function IndiceContenuti({
  classes, onChapterSelected, open, setOpen,
}) {
  const toggleDrawer = (op) => (event) => {
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpen(op)
  }

  const onListItemClick = (index) => () => onChapterSelected(index)

  return (
    <MuiThemeProvider theme={dark}>
      <Drawer
        classes={{
          paper: classes.container,
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className={classes.header}>
            <Typography variant="h2">Indice dei contenuti</Typography>
          </div>
          <List className={classes.list}>
            {chapters.map((chapter, index) => (
              <ListItem
                className={
                  chapter.level === 0
                    ? classes.listItem
                    : cn(classes.listItem, classes.listItemLevel1)
                }
                key={chapter.number}
                onClick={onListItemClick(index)}
              >
                <Link
                  color="textPrimary"
                  component="button"
                  className={classes.link}
                >
                  <Typography variant="caption">
                    {`${chapter.number}.`}
                  </Typography>
                  &nbsp;
                  <Typography variant="caption" className={classes.title}>
                    {chapter.title.charAt(0).toUpperCase()
                      + chapter.title.slice(1).toLowerCase()}
                  </Typography>
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </MuiThemeProvider>
  )
}

IndiceContenuti.propTypes = {
  classes: shape({}).isRequired,
  onChapterSelected: func.isRequired,
  open: bool.isRequired,
  setOpen: func.isRequired,
  t: func.isRequired,
}

export default withStyles(styles)(translate()(IndiceContenuti))
