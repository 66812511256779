import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    title: {
      marginBottom: 30,
      fontSize: 28,
      marginTop: 40,
      '@media (min-width: 600px)': {
        marginTop: 10,
        '&:not(:first-child)': {
          marginTop: 40,
        },
      },
    },
    subtitle: {
      fontSize: 20,
    },
    progress: {
      width: '100%',
      margin: '0 auto',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    label: {
      marginTop: 30,
    },
    menuPopover: {
      zIndex: 100,
    },
    menuPaper: {
      background: '#fff',
    },
    textfield: {
      background: '#fff',
    },
    input: {
      color: '#000',
      zIndex: 1,
      width: '100%',
    },
    chip: {
      marginTop: 10,
    },
    formGroup: {
      margin: '20px 0',
      width: '100%',
    },
    formControlLabel: {
      marginBottom: '20px',
      width: '100%',
    },
  },
  sg,
)
