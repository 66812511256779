/* eslint-disable camelcase */

// CONSTANTS
import * as keys from "constants/translations/keys";

// REACT
import React, { useEffect, useState } from "react";
import { func, shape } from "prop-types";
import { Trans, translate } from "react-i18next";
import { Paper, withStyles } from "@material-ui/core";

import Button from "components/button";

// REDUX
import { useSelector, useDispatch } from "react-redux";

// LIBS
import cn from "classnames";
import immutable from "immutable";

// STYLE
import EditorUser, { editUser } from "scenes/admin/components/editor-user";
import { useForm } from "components/use-form";
import {
  updateAdminUsersBy_idAjax,
  getAdminUsersBy_idAjax,
  getSchedeBy_idAjax,
} from "redux/actions";
import {
  getAdminRouteParams,
  getIsSuperAdmin,
  getMe,
  getRestUri,
  getUser,
} from "redux/getters";
import styles from "./style.js";

function AdminModificaUser({ classes, t }) {
  const restUri = useSelector(getRestUri);
  // eslint-disable-next-line no-underscore-dangle
  const _id = useSelector(getAdminRouteParams).get("_id");
  const me = useSelector(getMe);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const {
    data,
    setInitial,
    onChange,
    onChangeEvent,
    isValid,
    validations,
    onSubmit,
  } = useForm({
    initial: immutable.fromJS({
      email: "",
      comuneId: "",
      role: "OPERATORE",
      comune: "",
      attivo: true,
      password: "",
      confirmPassword: "",
    }),
    joiValidationSchema: editUser,
    onSubmit: () => {
      const tempData = data.toJS();
      const payload = {
        email: tempData.email,
        role: tempData.role,
        comuneId: tempData.comuneId,
        attivo: tempData.attivo,
        password: tempData.password,
      };

      if (payload.role === "SUPERADMIN") {
        delete payload.comuneId;
      }
      dispatch(updateAdminUsersBy_idAjax(restUri, _id, payload));
    },
  });

  useEffect(() => {
    if (user) {
      setInitial(
        immutable.fromJS({
          email: user.get("email") || "",
          comuneId: user.getIn(["comune", "id"]) || 0,
          role: user.get("role"),
          comune: user.getIn(["comune", "comune"]) || "",
          attivo: !!user.get("attivo"),
          password: "",
          confirmPassword: "",
        }),
      );
    }
  }, [user]);

  useEffect(() => {
    dispatch(getAdminUsersBy_idAjax(restUri, _id));
  }, [_id]);

  return (
    <div className={classes.wrapperWidthLimiter}>
      <Paper
        classes={{
          root: cn(classes.paper),
        }}
      >
        <form onSubmit={onSubmit}>
          <EditorUser
            isSuperAdmin={isSuperAdmin}
            user={data}
            onChange={onChange}
            edit
            onChangeEvent={onChangeEvent}
            validations={validations}
          />
          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Conferma
          </Button>
        </form>
      </Paper>
    </div>
  );
}

AdminModificaUser.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(AdminModificaUser));
