import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdminSchedeBy_idAjax } from "../../../../redux/actions";
import {
  getIsDeleteSchedaPopoverConfirmButtonEnabled,
  getIsDeleteSchedaPopoverOpen,
  getRestUri
} from "../../../../redux/getters";

export const useDeleteSchedaPopover = (idScheda, onClose) => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const isDeleteSchedaButtonEnabled = useSelector(getIsDeleteSchedaPopoverConfirmButtonEnabled);
  const isDeleteSchedaPopoverOpen = useSelector(getIsDeleteSchedaPopoverOpen)

  const onDeletionConfirm = useCallback(() => {
    dispatch(deleteAdminSchedeBy_idAjax(restUri, idScheda));
  }, [idScheda]);

  const onDeletionCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    onDeletionConfirm,
    onDeletionCancel,
    isDeleteSchedaPopoverOpen,
    isDeleteSchedaButtonEnabled,
  };
};
