// ASSETS
import ueSi from "assets/img/UE_si.svg";
import ueNo from "assets/img/UE_no.svg";
import nucleo from "assets/img/nucleo.svg";
import permesso from "assets/img/permesso.svg";
import isee from "assets/img/isee.svg";

// CONSTANTS

// REACT
import React, { Fragment } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Paper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getFilters, getPrintMode } from "redux/getters";

// REDUX

// LIBS

// STYLE
import styles from "./style.js";

function SearchResults({ classes }) {
  const filters = useSelector(getFilters);
  const printMode = useSelector(getPrintMode);

  if (printMode) {
    return null;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <Tooltip
          title={
            <Typography variant="h4" color="inherit">
              {"Membri nel nucleo familiare"}
            </Typography>
          }
        >
          <img className={classes.image} src={nucleo} alt="nucleo" />
        </Tooltip>
        <Typography className={classes.containerTypography}>
          {filters.get("members").filter((m) => m !== "").size}
        </Typography>
      </div>
      <div className={classes.container}>
        {filters.get("allEuropean") ? (
          <Fragment>
            <Tooltip
              title={
                <Typography variant="h4" color="inherit">
                  {"Ci sono membri nell'Unione Europea"}
                </Typography>
              }
            >
              <img className={classes.image} src={ueSi} alt="ueSi" />
            </Tooltip>
            <Typography className={classes.containerTypography}>Sì</Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Tooltip
              title={
                <Typography variant="h4" color="inherit">
                  {"Non ci sono membri nell'Unione Europea"}
                </Typography>
              }
            >
              <img className={classes.image} src={ueNo} alt="ueNo" />
            </Tooltip>
            <Typography className={classes.containerTypography}>No</Typography>
          </Fragment>
        )}
      </div>
      <div className={classes.container}>
        <Tooltip
          title={
            <Typography variant="h4" color="inherit">
              {"Ci sono membri con permesso di soggiorno a lungo periodo?"}
            </Typography>
          }
        >
          <img className={classes.image} src={permesso} alt="permesso" />
        </Tooltip>
        <Typography className={classes.containerTypography}>
          {" "}
          {filters.get("allEuropean")
            ? "-"
            : filters.get("residencyPermit")
            ? "Sì"
            : "No"}
        </Typography>
      </div>
      <div className={classes.container}>
        <Tooltip
          title={
            <Typography variant="h4" color="inherit">
              {"ISEE"}
            </Typography>
          }
        >
          <img className={classes.image} src={isee} alt="isee" />
        </Tooltip>
        <Typography className={classes.containerTypography}>
          {`${filters.get("isee")} €`}
        </Typography>
      </div>
    </Paper>
  );
}

SearchResults.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(SearchResults));
