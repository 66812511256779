import { useCreateTagDialog } from "./index.hooks";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import FormComponent from "../form-component";

export const CreateTagDialog = ({ onCreateTagModalClose }) => {
  const { isCreateTagModalOpen, data, onSubmit, validations, onChangeEvent } =
    useCreateTagDialog();

  return (
    <Dialog
      open={isCreateTagModalOpen}
      onClose={onCreateTagModalClose}
      fullWidth
      PaperProps={{
        style: {
          padding: "1em",
        },
      }}
    >
      <DialogTitle>
        <Typography color="textPrimary" variant="h2">
          Crea un nuovo TAG
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormComponent
            Component={TextField}
            field="name"
            data={data}
            fullWidth
            onChange={onChangeEvent}
            validations={validations}
            variant="outlined"
            label="Nome"
          />
          <Button fullWidth type="submit" variant="contained" color="primary">
            Conferma e crea
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
