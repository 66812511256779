// CONSTANTS
// REACT
import React from 'react'
import {
  func, node, oneOfType, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Button, withStyles,
} from '@material-ui/core'

// STYLE
import styles from './style.js'

function CustomButton({
  children, classes, className, t, ...props
}) {
  return (
    <Button
      className={className}
      classes={{
        root: classes.buttonRoot,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

CustomButton.propTypes = {
  children: oneOfType([node, string]),
  className: string,
  classes: shape({}).isRequired,
  t: func.isRequired,
}

CustomButton.defaultProps = {
  children: null,
  className: null,
}

export default withStyles(styles)(translate()(CustomButton))
