// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, arrayOf, oneOf, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Select,
  MenuItem,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  Checkbox,
  FormControl,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  CheckBox, CheckBoxOutlineBlank,
} from '@material-ui/icons'

// LIBS
import cn from 'classnames'

// STYLE
import styles from './style.js'

function MultipleSelect({
  checkboxColor,
  className,
  classes,
  options,
  selectedValues,
  setSelectedValues,
  label,
}) {
  const handleChange = (newValue) => {
    if (selectedValues.indexOf(newValue) === -1) {
      setSelectedValues([...selectedValues, newValue])
    } else {
      setSelectedValues(
        selectedValues.filter((_, i) => i !== selectedValues.indexOf(newValue)),
      )
    }
  }

  return (
    <FormControl className={cn(className, classes.formControl)}>
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
      <Select
        classes={{
          root: classes.select,
          icon: classes.icon,
        }}
        multiple
        displayEmpty
        value={[]}
        renderValue={() => {
          if (
            selectedValues.length === Object.keys(options).length
            || selectedValues.length === 0
          ) {
            return 'Tutti'
          }
          return `${selectedValues.length} selezionati`
        }}
        input={(
          <InputBase
            classes={{
              root: classes.inputBase,
            }}
          />
)}
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {Object.keys(options).map((key) => (
          <MenuItem
            button
            key={key}
            classes={{
              root: classes.menuItem,
            }}
          >
            <ListItemText
              id={key}
              onClick={() => handleChange(key)}
              classes={{
                root:
                  selectedValues.indexOf(key) !== -1
                    ? cn(classes.listItemText, classes.listItemTextChecked)
                    : classes.listItemText,
              }}
            >
              <Typography
                className={
                  selectedValues.indexOf(key) !== -1
                  && classes.listItemTextTypographyChecked
                }
              >
                {options[key]}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Checkbox
                color={checkboxColor}
                edge="end"
                onChange={() => handleChange(key)}
                checked={selectedValues.indexOf(key) !== -1}
                inputProps={{
                  'aria-labelledby': options[key],
                }}
                icon={(
                  <CheckBoxOutlineBlank
                    classes={{
                      root:
                        selectedValues.indexOf(key) === -1
                          ? cn(classes.checkBoxUnchecked, classes.checkBox)
                          : classes.checkBox,
                    }}
                  />
)}
                checkedIcon={(
                  <CheckBox
                    classes={{
                      root: classes.checkBox,
                    }}
                  />
)}
              />
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultipleSelect.propTypes = {
  checkboxColor: oneOf(['default', 'primary', 'secondary']),
  className: string,
  classes: shape({}).isRequired,
  t: func.isRequired,
  selectedValues: arrayOf(string).isRequired,
  setSelectedValues: func.isRequired,
  options: shape({}).isRequired,
  label: string.isRequired,
}

MultipleSelect.defaultProps = {
  checkboxColor: 'default',
  className: null,
}

export default withStyles(styles)(translate()(MultipleSelect))
