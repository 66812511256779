// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  withStyles,
  FormControl,
  OutlinedInput,
  Typography,
} from '@material-ui/core'

// STYLE
import styles from './style.js'

function TextField({
  classes, t, label, helperText, ...props
}) {
  return (
    <FormControl
      classes={{
        root: classes.formControl,
      }}
    >
      {label && (
        <Typography className={classes.textFieldTypography} variant="caption">
          {label}
        </Typography>
      )}
      <OutlinedInput
        classes={{
          root: classes.outlinedInput,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        }}
        {...props}
      />
      {helperText && (
        <Typography className={classes.helper} variant="caption">
          {helperText}
        </Typography>
      )}
    </FormControl>
  )
}

TextField.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
  label: string,
  helperText: string,
}

TextField.defaultProps = {
  label: null,
  helperText: null,
}

export default withStyles(styles)(translate()(TextField))
