import { createSelector } from "reselect";

export const getStaticContentsList = (state) =>
  state.getIn(["staticContents", "list"]).toJS();
export const getStaticContentsGlossary = createSelector(
  getStaticContentsList,
  (staticContentsList) => {
    return staticContentsList.find(
      (staticContent) => staticContent.type === "glossary",
    );
  },
);
