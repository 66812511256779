// CONSTANTS
// REACT
import React from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { ButtonBase, Paper, Typography, withStyles } from "@material-ui/core";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { getPrintMode, getAdminRoute } from "redux/getters";
import { resetFilter } from "redux/actions/schede";

// LIBS
import cn from "classnames";

// STYLE
import styles from "./style.js";

function SwitchRicerca({ classes }) {
  const printMode = useSelector(getPrintMode);
  const activeRoute = useSelector(getAdminRoute).toString();
  const dispatch = useDispatch();

  if (printMode) {
    return null;
  }

  return (
    <div className={classes.container}>
      <ButtonBase
        classes={{
          root: classes.buttonLeft,
        }}
        href="#/admin/nucleo"
        onClick={() => dispatch(resetFilter())}
      >
        <Paper
          className={cn(
            classes.paperLeft,
            activeRoute === "adminNucleo"
              ? classes.paperLeftSelected
              : classes.paperUnselected,
          )}
          elevation={activeRoute === "adminNucleo" ? 1 : 0}
        >
          <Typography
            className={classes.title}
            variant={activeRoute === "adminNucleo" ? "caption" : "body1"}
          >
            Profila un nucleo familiare
          </Typography>
        </Paper>
      </ButtonBase>
      <ButtonBase
        classes={{
          root: classes.buttonRight,
        }}
        href="#/admin/catalogo"
        onClick={() => dispatch(resetFilter())}
      >
        <Paper
          className={cn(
            classes.paperRight,
            activeRoute === "adminCatalogo"
              ? classes.paperRightSelected
              : classes.paperUnselected,
          )}
          elevation={activeRoute === "adminCatalogo" ? 1 : 0}
        >
          <Typography
            className={classes.title}
            variant={activeRoute === "adminCatalogo" ? "caption" : "body1"}
          >
            Trova informazioni senza profilare
          </Typography>
        </Paper>
      </ButtonBase>
    </div>
  );
}

SwitchRicerca.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(SwitchRicerca));
