// REDUX
/* eslint-disable */

import { all, put, select, take, takeEvery } from "redux-saga/effects";
import {
  AUTHENTICATION_EXPIRED,
  DELETE_USERS_TOKENS_AJAX_SUCCESS,
} from "../../action-types";

export function* logoutSaga() {
  yield takeEvery([DELETE_USERS_TOKENS_AJAX_SUCCESS], function* () {
    window.location.href = "#/";
    window.location.reload();
  });
}

export default function* rootSaga() {
  yield all([logoutSaga()]);
}
