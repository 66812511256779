import {
  SET_IS_CREATE_TAG_DIALOG_OPEN,
  SET_IS_DELETE_TAG_DIALOG_OPEN,
  SET_IS_EDIT_TAG_DIALOG_OPEN,
} from "../../action-types/tags";

export const setIsCreateTagDialogOpen = (isCreateTagDialogOpen) => ({
  type: SET_IS_CREATE_TAG_DIALOG_OPEN,
  payload: isCreateTagDialogOpen,
});
export const setIsEditTagDialogOpen = (isEditTagDialogOpen) => ({
  type: SET_IS_EDIT_TAG_DIALOG_OPEN,
  payload: isEditTagDialogOpen,
});
export const setIsDeleteTagDialogOpen = (isDeleteTagDialogOpen) => ({
  type: SET_IS_DELETE_TAG_DIALOG_OPEN,
  payload: isDeleteTagDialogOpen,
});
