// REACT
import React, { Fragment } from "react";
import { func, shape, string } from "prop-types";
import { translate } from "react-i18next";
import {
  Divider,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import styles from "./style.js";
import { useWidgetPraticheRecenti } from "./index.hook";
import { ChevronRight } from "@material-ui/icons";

const WidgetPraticheRecenti = ({ classes, t }) => {
  const { orderedSchedeRecenti } = useWidgetPraticheRecenti();

  return (
    <div>
      <div style={{ padding: "1em 2em" }}>
        <Typography color="primary" variant="h1">
          Schede aggiunte di recente
        </Typography>
        {orderedSchedeRecenti.length === 0 && (
          <Typography
            variant="h3"
            color="secondary"
            style={{ fontWeight: "normal", marginTop: "0.5em" }}
          >
            Non sono state aggiunte nuove schede negli ultimi due mesi
          </Typography>
        )}
      </div>
      <div className={classes.recentSchedeContainer}>
        {orderedSchedeRecenti.map((scheda, index) => {
          const labelId = `checkbox-list-secondary-label-${index + 1}`;
          return (
            <Fragment>
              {index !== 0 && (
                <Divider
                  classes={{
                    root: classes.divider,
                  }}
                />
              )}
              <ListItem
                classes={{
                  root: classes.listItem,
                }}
                role={undefined}
                dense
                button
                disableRipple
                component="a"
                href={`#/scheda/${scheda._id}`}
              >
                <ListItemText
                  classes={{
                    root: classes.listItemText,
                  }}
                  id={labelId}
                >
                  <Typography
                    classes={{
                      root: classes.listItemTextTypography,
                    }}
                  >
                    {`${scheda.titolo_prestazione}`}
                  </Typography>
                </ListItemText>
                {
                  <ChevronRight
                    iconstyle={classes.chevronRight.path}
                    classes={{
                      root: classes.chevronRight,
                    }}
                  />
                }
              </ListItem>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

WidgetPraticheRecenti.propTypes = {
  classes: shape({}).isRequired,
};

export default withStyles(styles)(translate()(WidgetPraticheRecenti));
