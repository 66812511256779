import { useForm } from "../use-form";
import { updateAdminTagsBy_idAjax } from "../../redux/actions";
import immutable from "immutable";
import joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditTagDialogOpen, getRestUri } from "../../redux/getters";
import { useMemo } from "react";

const schema = joi
  .object()
  .keys({
    name: joi.string().required(),
  })
  .required();

export const useEditTagDialog = (tag) => {
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);

  const isEditTagModalOpen = useSelector(getIsEditTagDialogOpen);

  const initialState = useMemo(
    () => immutable.fromJS({ name: tag && tag.name ? tag.name : "" }),
    [tag],
  );

  const { data, setInitial, onChangeEvent, validations, onSubmit } = useForm({
    initial: initialState,
    joiValidationSchema: schema,
    onSubmit: () => {
      dispatch(updateAdminTagsBy_idAjax(restUri, tag._id, data));
      setInitial(immutable.fromJS({ name: "" }));
    },
  });

  return { isEditTagModalOpen, data, onSubmit, validations, onChangeEvent };
};
