// CONSTANTS
// REACT
import React, { useEffect, useMemo, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Chip,
  ClickAwayListener,
  FormControl,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Select,
  withStyles,
} from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "components/text-field";
import SegmentedControl from "mui-segmented-control";
import MultipleSelect from "components/multiple-select";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getComuni,
  getFilters,
  getIsSuperAdmin,
  getRestUri,
  getTagsList,
  getUserComune,
} from "redux/getters";
import {
  getAdminTagsAjax,
  getComuniAjax,
  getComuniAjaxCancel,
  setFilter,
} from "redux/actions";

// STYLE
import { common, dark } from "../../themes";
import styles from "./style.js";

function Filters({ classes }) {
  const [anchor, setAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const restUri = useSelector(getRestUri);
  const comuni = useSelector(getComuni);
  const filters = useSelector(getFilters);
  const norme = filters.get("norme");
  const condizioni = filters.get("condizioni");
  const bisogni = filters.get("bisogni");
  const utenza = filters.get("utenza");
  const tags = filters.get("tags");
  const comuneLabel = filters.get("comuneLabel");
  const [comuneText, setComuneText] = useState(comuneLabel);
  const dispatch = useDispatch();
  const tagsList = useSelector(getTagsList);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const userComune = useSelector(getUserComune);

  const tagsOptions = useMemo(
    () =>
      tagsList.reduce((options, tag) => {
        options[tag._id] = tag.name;

        return options;
      }, {}),
    [tagsList],
  );

  useEffect(() => {
    dispatch(getAdminTagsAjax(restUri));
  }, [dispatch, restUri]);

  useEffect(() => {
    dispatch(getComuniAjaxCancel());

    if (comuneText !== "" && comuneText !== comuneLabel) {
      dispatch(
        getComuniAjax(restUri, {
          query: {
            comune: comuneText,
          },
        }),
      );
    } else {
      setMenuOpen(false);
    }
  }, [comuneText]);

  useEffect(() => {
    if (comuni.length && comuneText !== "") {
      setMenuOpen(true);
    }
  }, [comuni]);

  useEffect(() => {
    if (!isSuperAdmin) {
      if (userComune && userComune.get("comune")) {
        setComuneText(userComune.get("comune"));
        dispatch(setFilter("comune", userComune.get("id")));
      }
    }
  }, [isSuperAdmin, userComune]);

  return (
    <MuiThemeProvider theme={dark}>
      <div className={classes.background}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h3">Raffina la tua ricerca</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">
              Dipende dalla condizione economica?
            </Typography>
            <SegmentedControl
              classes={{
                root: classes.segmentedControlRoot,
                buttonLabel: classes.segmentedControlLabel,
                buttonSelected: classes.segmentedControlButtonSelected,
                selector: classes.segmentedControlSelector,
              }}
              color="primary"
              onChange={(value) => dispatch(setFilter("condizioni", value))}
              options={[
                {
                  label: "Tutte",
                  value: "t",
                },
                {
                  label: "Sì",
                  value: "s",
                },
                {
                  label: "No",
                  value: "n",
                },
              ]}
              value={condizioni}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">È definita da norme</Typography>
            <div className={classes.norme}>
              <SegmentedControl
                classes={{
                  root: classes.segmentedControlRoot,
                  buttonLabel: classes.segmentedControlLabel,
                  buttonSelected: classes.segmentedControlButtonSelected,
                  selector: classes.segmentedControlSelector,
                }}
                color="primary"
                options={[
                  {
                    label: "Tutte",
                    value: "t",
                  },
                  {
                    label: "Nazionali",
                    value: "n",
                  },
                  {
                    label: "Regionali",
                    value: "r",
                  },
                  {
                    label: "Comunali",
                    value: "c",
                  },
                ]}
                value={norme}
                onChange={(value) => dispatch(setFilter("norme", value))}
              />
            </div>
            <FormControl className={classes.formControl}>
              <Select
                native
                className={classes.normeMobile}
                onChange={(ev) => dispatch(setFilter("norme", ev.target.value))}
                value={norme}
              >
                <option key="t" value="t">
                  Tutte
                </option>
                <option key="n" value="n">
                  Nazionali
                </option>
                <option key="r" value="r">
                  Regionali
                </option>
                <option key="c" value="c">
                  Comunali
                </option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={common}>
              <TextField
                classes={{
                  notchedOutline: classes.textfield,
                  input: isSuperAdmin ? classes.input : classes.disabledInput,
                }}
                label="Comune di ricerca"
                placeholder="Inizia a scrivere il comune e selezionalo dalla lista"
                disabled={!isSuperAdmin}
                onChange={
                  isSuperAdmin
                    ? (ev) => {
                        setComuneText(ev.target.value);
                        dispatch(setFilter("comune", null));
                        dispatch(setFilter("comuneLabel", ""));
                        dispatch(setFilter("comuneATS", null));
                        setAnchor(ev.currentTarget);
                      }
                    : null
                }
                value={comuneText}
              />
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <Popper
                  anchorEl={anchor}
                  className={classes.menuPopover}
                  open={isSuperAdmin && menuOpen}
                  transition
                  disablePortal
                  placement="bottom-start"
                >
                  <Paper className={classes.menuPaper}>
                    <MenuList>
                      {comuni.map((c) => (
                        <MenuItem
                          key={c.id}
                          onClick={() => {
                            setMenuOpen(false);
                            dispatch(setFilter("comune", c.id));
                            dispatch(setFilter("comuneLabel", c.comune));
                            dispatch(setFilter("comuneATS", c.ATS));
                            setComuneText(c.comune);
                          }}
                        >
                          {c.comune}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Paper>
                </Popper>
              </ClickAwayListener>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiThemeProvider theme={common}>
              <MultipleSelect
                checkboxColor="primary"
                label="Bisogno affrontato"
                selectedValues={bisogni.toJS()}
                setSelectedValues={(values) =>
                  dispatch(setFilter("bisogni", values))
                }
                options={{
                  br: "Basso reddito",
                  ab: "Abitazione",
                  la: "Lavoro",
                  tr: "Trasporti",
                  di: "Disabilità / Non autosufficienza",
                }}
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiThemeProvider theme={common}>
              <MultipleSelect
                checkboxColor="primary"
                label="Area di utenza"
                selectedValues={utenza.toJS()}
                setSelectedValues={(values) =>
                  dispatch(setFilter("utenza", values))
                }
                options={{
                  mi: "Minori",
                  ad: "Adulti / e",
                  di: "Disabili / Non autosufficienti",
                  an: "Anziani / e",
                  do: "Donne",
                }}
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiThemeProvider theme={common}>
              <MultipleSelect
                checkboxColor="primary"
                label="Tags"
                selectedValues={tags.toJS()}
                setSelectedValues={(values) =>
                  dispatch(setFilter("tags", values))
                }
                options={tagsOptions}
              />
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
}

Filters.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Filters));
