import _ from "lodash";
import sg from "styles";

export default (theme) =>
  _.merge(
    {
      container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 71,
        background: "#fff",
      },
      contentContainer: {
        display: "flex",
        justifyContent: "space-between",
      },
      logo: {
        width: 160,
        "@media print": {
          width: 80,
        },
      },
      linksContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "@media (max-width: 600px)": {
          display: "none",
        },
        "@media print": {
          display: "none",
        },
      },
      drawerContainer: {
        "@media (min-width: 600px)": {
          display: "none",
        },
      },
      link: {
        marginLeft: 20,
        cursor: "pointer",
      },
      logoutButton: {
        color: "#8B8A9D",
        marginLeft: 20,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      drawerPaper: {
        width: "80%",
        backgroundColor: "#fff",
      },
      menuIcon: {
        fontSize: 35,
      },
      menuIconButton: {
        "@media (min-width: 600px)": {
          display: "none",
        },
        "@media print": {
          display: "none",
        },
      },
      popover: {
        backgroundColor: "#F2F2F2",
        padding: "5px 10px",
        marginTop: 5,
      },
      mailToLink: {
        margin: "0 5px",
        cursor: "pointer",
      },
    },
    sg,
  );
