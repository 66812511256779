import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    paper: {
      borderRadius: 5,
      marginTop: 50,
      padding: 20,
    },
    input: {
      color: '#000',
      zIndex: 1,
    },
    menuPopover: {
      zIndex: 100,
    },
    menuPaper: {
      background: '#fff',
    },
    textfield: {
      background: '#fff',
    },
    button: {
      minWidth: 150,
      width: 150,
      borderRadius: 25,
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },
  },
  sg,
)
