// ASSETS

// CONSTANTS
// LIBS
import cn from "classnames";

// REACT
import React, { Fragment } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import { Typography, withStyles } from "@material-ui/core";

import styles from "./style.js";

function Home({ classes }) {
  return (
    <Fragment>
      <div className={classes.subheader}>
        <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
          <Typography variant="h1">
            Catalogo delle prestazioni contro la povertà
          </Typography>
          <Typography
            className={classes.subtitle}
            color="secondary"
            variant="body1"
          >
            Elenco delle misure di sostegno e integrazione fornite a cittadini e
            cittadine con difficoltà socio economiche
          </Typography>
        </div>
      </div>
      <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
        <div className={classes.infoText}>
          <p>
            <strong>
              Gli interventi pubblici di sostegno del reddito contro la povertà
            </strong>{" "}
            sono molti e assai diversi, e questo:
          </p>
          <ul>
            <li>
              impone alle persone in difficoltà economica di essere sempre
              informate su che cosa possono richiedere, quando ed a chi; col
              rischio che le famiglie più fragili non riescano nemmeno a
              richiedere interventi ai quali potrebbero accedere
            </li>
            <li>
              impone agli operatori che incontrano nuclei in povertà di essere
              sempre aggiornati sull’intero panorama delle misure, e di poterne
              informare compiutamente i cittadini.
            </li>
          </ul>
          <p>
            L’applicativo che qui potete vedere consiste in un
            <strong>“Catalogo delle prestazioni contro la povertà”</strong>{" "}
            sempre aggiornato (a cura di una redazione dedicata) di tutte le
            prestazioni nazionali e regionali (piemontesi) a sostegno del
            reddito, e<strong> offre agli operatori due funzionalità</strong>:
          </p>
          <ul>
            <li>
              permettere a ciascuno di informarsi sulle prestazioni, cercando
              entro il Catalogo quelle che interessano, e ottenendo una scheda
              dettagliata che le descrive
            </li>
            <li>
              quando un operatore riceve un nucleo in difficoltà, poter inserire
              un veloce profilo del nucleo, e ricavare le prestazioni che in
              quel momento quel nucleo potrebbe richiedere, anche stampando per
              il cittadino una sintesi in linguaggio semplice,
              <strong>
                {" "}
                che spieghi come dove e quando può richiedere le prestazioni.{" "}
              </strong>
              Nel profilare un nucleo nessun dato è obbligatorio, e si può
              scegliere se e quale inserire
            </li>
          </ul>
          <strong>
            Se volete informazioni scrivete a: welfareinforma.cn@acli.it
          </strong>
        </div>
      </div>
    </Fragment>
  );
}

Home.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Home));
