// REDUX
import immutable from "immutable";
import auth from "./auth";
import comuni from "./comuni";
import global from "./global";
import routing from "./routing";
import schede from "./schede";
import users from "./users";
import indirizzi from "./indirizzi";
import tags from "./tags";
import staticContents from "./static-contents";

// LIBS

export default immutable.Map({
  auth,
  comuni,
  global,
  routing,
  schede,
  users,
  indirizzi,
  tags,
  staticContents,
});
