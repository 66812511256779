// CONSTANTS

// REACT
import React from 'react'
import {
  func, shape,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Typography, withStyles,
} from '@material-ui/core'

// STYLES
import cn from 'classnames'
import styles from './style.js'
import regione from 'assets/img/regpiemonte.svg'
import lavoro from 'assets/img/lavoroPoliticheSociali.png'
import actionaidhorix from 'assets/img/actionaidlogohoriz.svg'

function Footer({
  classes,
}) {
  return null;

  return (
    <div className={classes.container}>
      <div className={classes.footerWrapper}>
        <div className={classes.firstRow}>
          <div className={classes.logoContainer}>
            <img
              className={classes.logoActionAid}
              src={actionaidhorix}
              alt="actionaid"
            />
          </div>
        </div>
        <div className={classes.firstRow}>
          <div className={classes.logoContainer}>
            <Typography color="secondary" variant="body1">
              Con il contributo di
            </Typography>
            <img className={classes.logoQuadrato} src={lavoro} alt="lavoro" />
          </div>
          <div className={classes.logoContainer}>
            <Typography color="secondary" variant="body1">
              In collaborazione con
            </Typography>
            <img className={classes.logoQuadrato} src={regione} alt="regione" />
          </div>
        </div>
        <Typography variant="caption" color="secondary">
          Per il Progetto: OLTRE LA POVERTÁ: percorsi di inclusione sociale
        </Typography>
        <Typography variant="subtitle2" color="secondary">
          {
            '"Finanziamenti per il sostegno di progetti di rilevanza locale promossi da organizzazioni di volontariato e associazioni di promozione sociale ai sensi degli articoli 72 e 73 del D.lgs 117/2017, Codice del terzo settore" CUP J69F18001160001'
          }
        </Typography>
        <div className={cn(classes.thirdRow, classes.logoContainer)}>
          <Typography color="secondary" variant="body1">
            Con capofila
          </Typography>
          <Typography color="secondary" variant="caption">
            ACLI PIEMONTE
          </Typography>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
}

export default withStyles(styles)(translate()(Footer))
