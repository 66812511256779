import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    paper: {
      borderRadius: 5,
    },
    chevronRight: {
      position: 'absolute',
      right: 10,
      width: 24,
      height: 24,
      color: theme.palette.secondary.main,
      '@media (min-width: 600px)': {
        right: 40,
      },
    },
    divider: {
      color: theme.palette.secondary.main,
      '@media (min-width: 600px)': {
        margin: '0 30px',
      },
      '&:first-child': {
        display: 'none',
      },
    },
    list: {
      marginTop: 50,
      borderRadius: 5,
      backgroundColor: 'white',
    },
    listItem: {
      minHeight: 70,
      '&:hover': {
        backgroundColor: 'rgba(83,120,178, 0.05)',
      },
      '@media (max-width: 600px)': {
        minHeight: 60,
        padding: 0,
      },
      '@media print': {
        pageBreakInside: 'avoid',
      },
    },
    listItemText: {
      marginLeft: 23,
    },
    listItemTextTypography: {
      color: theme.palette.primary.main,
      paddingRight: 60,
    },
    text: {
      display: 'inline-block',
    },
    tutorial: {
      marginTop: 60,
      textAlign: "center",
    }
  },
  sg,
)
