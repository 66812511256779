import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    divider: {
      width: '100%',
      marginRight: 0,
      marginBottom: 20,
    },
  },
  sg,
)
