import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRestUri, getSchedeRecenti } from "redux/getters";
import {
  getSchedeRecentiAjax,
} from "redux/actions";
import moment from "moment";

export const useWidgetPraticheRecenti = () => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const schedeRecenti = useSelector(getSchedeRecenti);

  const orderedSchedeRecenti = useMemo(
    () =>
      [...schedeRecenti].sort((schedaA, schedaB) =>
        moment(schedaA.created).isAfter(moment(schedaB.created)) ? -1 : 1,
      ),
    [schedeRecenti],
  );

  useEffect(() => {
    dispatch(getSchedeRecentiAjax(restUri));
  }, [restUri, dispatch]);

  return { orderedSchedeRecenti };
};
