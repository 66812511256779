export const norme = {
  l: 'Locali',
  r: 'Regionali',
  n: 'Nazionali'
}
export const bisognoAffrontato = {
  br: 'Basso reddito',
  ab: 'Abitazione',
  la: 'Lavoro',
  tr: 'Trasporti',
  di: 'Disabilità / Non autosufficienza',
  tu: 'Tutte'
}
export const areaUtenza = {
  mi: 'Minori',
  ad: 'Adulti',
  di: 'Disabili / Non autosufficenti',
  an: 'Anziani',
  do: 'Donne',
  tu: 'Tutte'
}
export const condizioneEconomica = {
  s: 'Sì',
  n: 'No'
}
