// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, string, bool,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  MuiThemeProvider, withStyles,
} from '@material-ui/core'
import CustomButton from 'components/button'
import {
  ArrowRightAlt,
} from '@material-ui/icons'

// LIBS
import cn from 'classnames'

// STYLE
import {
  alternative,
} from '../../../../themes'
import styles from './style.js'

function NavigationBar({
  backHref,
  nextHref,
  nextText,
  onNextClick,
  classes,
  disabled,
}) {
  return (
    <div
      className={cn(classes.buttonsContainer, {
        [classes.singleButton]: !backHref,
      })}
    >
      {!!backHref && (
        <CustomButton color="secondary" href={backHref} variant="outlined">
          Indietro
        </CustomButton>
      )}
      <MuiThemeProvider theme={alternative}>
        <CustomButton
          color="secondary"
          disabled={disabled}
          href={nextHref}
          onClick={onNextClick}
          variant="contained"
        >
          {nextText}
          <ArrowRightAlt className={classes.icon} />
        </CustomButton>
      </MuiThemeProvider>
    </div>
  )
}

NavigationBar.propTypes = {
  backHref: string,
  classes: shape({}).isRequired,
  disabled: bool,
  nextHref: string,
  nextText: string,
  onNextClick: func,
  t: func.isRequired,
}

NavigationBar.defaultProps = {
  backHref: null,
  disabled: false,
  nextHref: null,
  nextText: 'Prosegui',
  onNextClick: null,
}

export default withStyles(styles)(translate()(NavigationBar))
