import { useDispatch, useSelector } from "react-redux";
import {
  getAjaxLoading,
  getRestUri,
  getStaticContentsGlossary,
} from "../../../../redux/getters";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAdminStaticContentsAjax,
  updateAdminStaticContentsAjax,
} from "../../../../redux/actions";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export const useAdminGlossaryEditor = () => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const glossary = useSelector(getStaticContentsGlossary);
  const isFetching = useSelector(getAjaxLoading("GET_ADMIN_STATIC_CONTENTS_1"));

  const initialGlossaryContent = useMemo(
    () => (glossary && glossary.content) || "",
    [glossary],
  );

  const [glossaryContent, setGlossaryContent] = useState(
    initialGlossaryContent,
  );

  const defaultEditorState = useMemo(
    () =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(initialGlossaryContent)),
      ),
    [initialGlossaryContent],
  );

  const isSaveButtonDisabled = useMemo(
    () =>
      initialGlossaryContent === glossaryContent ||
      !glossaryContent ||
      !glossary,
    [initialGlossaryContent, glossaryContent, glossary],
  );

  const onEditorChange = useCallback(
    (editorState) => {
      const newGlossaryContent = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );
      if (newGlossaryContent === "<p></p>\n") {
        setGlossaryContent("");
      } else {
        setGlossaryContent(newGlossaryContent);
      }
    },
    [setGlossaryContent],
  );

  const onSaveGlossaryClick = useCallback(() => {
    dispatch(
      updateAdminStaticContentsAjax(restUri, {
        type: "glossary",
        content: glossaryContent,
      }),
    );
  }, [dispatch, restUri, glossaryContent]);

  useEffect(() => {
    dispatch(getAdminStaticContentsAjax(restUri, "glossary"));
  }, [dispatch, restUri]);

  return {
    isFetching,
    glossaryContent,
    defaultEditorState,
    onEditorChange,
    onSaveGlossaryClick,
    isSaveButtonDisabled,
  };
};
