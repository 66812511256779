import { createSelector } from "reselect";

export const getUsers = createSelector(
  [(state) => state.getIn(["users", "list"])],
  (users) => users.toJS(),
);

export const getUserLoggedPermissions = createSelector(
  [(state) => state.getIn(["users", "me", "permissions"])],
  (permissions) => (permissions ? permissions.toJS() : []),
);
export const getMe = (state) => state.getIn(["users", "me"]);
export const getUser = (state) => state.getIn(["users", "item"]);
export const getIsLogged = (state) => state.getIn(["users", "isLogged"]);
export const getUsersFilters = (state) => state.getIn(["users", "filters"]);
export const getUserComune = (state) => state.getIn(["users", "me", "comune"]);
export const getIsSuperAdmin = (state) =>
  getUserLoggedPermissions(state).indexOf("CAN_CRUD_USERS") !== -1;
