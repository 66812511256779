import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    switch: {
      width: 82,
      height: 50,
      padding: 17,
      marginLeft: 15,
      marginRight: 15,
    },
    thumb: {
      width: 32,
      height: 32,
      border: '1px solid rgba(117, 153, 178, 0.5)',
    },
    track: {
      backgroundColor: '#7599B2',
    },
    switchBase: {
      padding: 9,
      '&$checked': {
        transform: 'translateX(32px)',
      },
    },
    checked: {},
  },
  sg,
)
