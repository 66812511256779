import { useForm } from "../use-form";
import { createAdminTagsAjax } from "../../redux/actions";
import immutable from "immutable";
import joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { getIsCreateTagDialogOpen, getRestUri } from "../../redux/getters";

const schema = joi
  .object()
  .keys({
    name: joi.string().required(),
  })
  .required();

const initialState = immutable.fromJS({ name: "" });

export const useCreateTagDialog = () => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const isCreateTagModalOpen = useSelector(getIsCreateTagDialogOpen);

  const { data, setInitial, onChangeEvent, validations, onSubmit } = useForm({
    initial: initialState,
    joiValidationSchema: schema,
    onSubmit: () => {
      dispatch(createAdminTagsAjax(restUri, data));
      setInitial(initialState);
    },
  });

  return { isCreateTagModalOpen, data, onSubmit, validations, onChangeEvent };
};
