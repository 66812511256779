import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    outlinedInput: {
      height: 50,
      paddingLeft: 7,
    },
    input: {
      '&::placeholder': {
        color: theme.palette.secondary.main,
      },
    },
    formControl: {
      width: '100%',
    },
    textFieldTypography: {
      marginBottom: 10,
    },
    helper: {
      color: 'red',
      marginTop: 5,
    },
  },
  sg,
)
