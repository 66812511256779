import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    marginLeft: {
      margin: '0 20px 0',
    },
  },
  sg,
)
