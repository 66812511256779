import React, { Fragment, useCallback } from "react";
import {
  Button,
  Grid,
  MuiThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { dark } from "../../../../themes";
import cn from "classnames";
import ChoiceBox from "../../../home/components/choice-box";
import profila from "assets/img/welfareinformafamily.png";
import trova from "assets/img/trova.svg";
import styles from "./style.js";

const AdminSearch = ({ classes }) => {
  return (
    <div>
      <MuiThemeProvider theme={dark}>
        <div className={classes.cover}>
          <div className={cn(classes.wrapper, classes.coverContent)}>
            <Typography color="textPrimary" variant="h2">
              Cosa vuoi cercare?
            </Typography>
          </div>
        </div>
      </MuiThemeProvider>
      <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
        <Grid className={classes.container} container spacing={3}>
          <Grid item container xs={12} sm={6}>
            <ChoiceBox
              href="#/admin/nucleo"
              title="Profila un nucleo familiare"
              subtitle="e trova informazioni"
              image={profila}
              iconClass={classes.icon}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <ChoiceBox
              href="#/admin/catalogo"
              title="Trova informazioni"
              subtitle="senza profilare il nucleo familiare"
              image={trova}
              iconClass={classes.icon2}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(AdminSearch);
