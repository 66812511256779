import { useDispatch, useSelector } from "react-redux";
import {
  getAjaxLoading,
  getRestUri,
  getStaticContentsGlossary,
} from "../../../../redux/getters";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAdminStaticContentsAjax,
  updateAdminStaticContentsAjax,
} from "../../../../redux/actions";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export const useAdminGlossary = () => {
  const dispatch = useDispatch();

  const restUri = useSelector(getRestUri);
  const glossary = useSelector(getStaticContentsGlossary);

  const glossaryContent = useMemo(
    () => (glossary && glossary.content) || "",
    [glossary],
  );

  useEffect(() => {
    dispatch(getAdminStaticContentsAjax(restUri, "glossary"));
  }, [dispatch, restUri]);

  return {
    glossaryContent,
  };
};
