import _ from 'lodash'
import sg from 'styles'

export default () => _.merge(
  {
    paper: {
      padding: '20px 30px',
      '@media (min-width: 600px)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: 75,
      },
    },
    container: {
      display: 'inline-block',
      '&:not(:last-child)': {
        marginRight: 35,
        marginBottom: 15,
      },
      '@media (min-width: 600px)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
          marginRight: 0,
          marginBottom: 0,
        },
      },
    },
    image: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 10,
    },
    icon: {
      width: 40,
      height: 40,
      marginRight: 15,
    },
    containerTypography: {
      fontSize: 14,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  sg,
)
