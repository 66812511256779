// CONSTANTS
// REACT
import React from 'react'
import {
  func, node, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  MuiThemeProvider,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'

// STYLE
import {
  dark,
} from '../../../../themes'
import styles from './style.js'

function FormContainer({
  children, classes, images, step, title,
}) {
  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      <MuiThemeProvider theme={dark}>
        <div className={classes.stepContainer}>
          <div>
            <Typography
              className={classes.number1}
              color="textPrimary"
              variant="h2"
            >
              {step}
            </Typography>
            <Typography
              className={classes.number2}
              color="textPrimary"
              variant="h2"
            >
              /3
            </Typography>
          </div>
        </div>
      </MuiThemeProvider>
      <Paper className={classes.box}>
        {children}
        <div className={classes.images}>
          {images.map((image) => (
            <div key={image} className={classes.imageContainer}>
              <img className={classes.image} src={image} alt="step" />
            </div>
          ))}
        </div>
        <Typography
          className={classes.optionalData}
          color="secondary"
          variant="subtitle2"
        >
          Dato facoltativo
        </Typography>
      </Paper>
    </div>
  )
}

FormContainer.propTypes = {
  children: node.isRequired,
  classes: shape({}).isRequired,
  images: shape({}).isRequired,
  step: string.isRequired,
  t: func.isRequired,
  title: string.isRequired,
}

export default withStyles(styles)(translate()(FormContainer))
