/* eslint-disable */
// CONSTANTS
// REACT
import React, { useRef, Fragment } from "react";
import { bool, func, shape } from "prop-types";
import { translate } from "react-i18next";
import { LinearProgress, Typography, withStyles } from "@material-ui/core";

// REDUX
import { useSelector } from "react-redux";

// LIBS
import cn from "classnames";
import moment from "moment";
// STYLE
import { getScheda } from "redux/getters/schede";
import chapters from "constants/chapters";
import { getAjaxLoading, getPrintMode } from "redux/getters/global";
import { GET_SCHEDE_BY__ID_AJAX_SUCCESS } from "redux/action-types/ajax";
import { getIndirizzo } from "redux/getters";

import IndiceContenuti from "../indice-contenuti";
import styles from "./style.js";

function VersioneOperatore({ classes, setOpen, open }) {
  const chaptersRefs = chapters.map(() => useRef());
  const scheda = useSelector(getScheda);
  const indirizzo = useSelector(getIndirizzo);
  const isLoading =
    useSelector(getAjaxLoading(GET_SCHEDE_BY__ID_AJAX_SUCCESS)) || !scheda;
  const printMode = useSelector(getPrintMode);

  console.log("printMode? : ", printMode);

  const schedaContent = (chapter) => {
    if (scheda.get(chapter.textId) !== "") {
      return scheda.get(chapter.textId);
    }

    return "Nulla è previsto sul punto";
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {chapters.map((chapter, index) => {
        return (
          <Fragment>
            <Typography
              className={
                chapter.level === 0
                  ? classes.title
                  : cn(classes.title, classes.subtitle)
              }
              variant="h2"
              ref={chaptersRefs[index]}
            >
              {`${chapter.number}. ${chapter.title}`}
            </Typography>
            {isLoading ? (
              <LinearProgress className={classes.progress} />
            ) : (
              <Typography
                variant="body2"
                className={classes.rteContainer}
                dangerouslySetInnerHTML={{
                  __html: schedaContent(chapter),
                }}
              />
            )}
          </Fragment>
        );
      })}
      <IndiceContenuti
        onChapterSelected={(index) => {
          setOpen(false);
          window.scrollTo({
            top:
              chaptersRefs[index].current.getBoundingClientRect().top +
              window.scrollY -
              160,
            behavior: "smooth",
          });
        }}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

VersioneOperatore.propTypes = {
  classes: shape({}).isRequired,
  open: bool.isRequired,
  setOpen: func.isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(VersioneOperatore));
