// CONSTANTS
// REACT
import React from 'react'
import {
  func, shape, string,
} from 'prop-types'
import {
  translate,
} from 'react-i18next'
import {
  Link,
  MuiThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  ChevronLeft,
} from '@material-ui/icons'

// STYLE
import styles from './style.js'
import {
  alternative,
} from '../../themes'

function BackButton({
  classes, href, text, onClick,
}) {
  return (
    <MuiThemeProvider theme={alternative}>
      <Link
        className={classes.link}
        color="secondary"
        href={href}
        onClick={onClick}
      >
        <ChevronLeft />
        <Typography variant="button" className={classes.buttonText}>
          {text}
        </Typography>
      </Link>
    </MuiThemeProvider>
  )
}

BackButton.propTypes = {
  classes: shape({}).isRequired,
  href: string,
  onClick: func,
  t: func.isRequired,
  text: string.isRequired,
}

BackButton.defaultProps = {
  href: null,
  onClick: null,
}

export default withStyles(styles)(translate()(BackButton))
