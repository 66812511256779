// REACT
import React, { useMemo, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  Link,
  Popover,
  Typography,
  withStyles,
  ButtonBase,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import cn from "classnames";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getRestUri,
  getMe,
  getMainRoute,
  getAdminRoute,
  getIsLogged,
} from "redux/getters";
import { deleteUsersTokensAjax } from "redux/actions/ajax";

import styles from "./style.js";
import logo from "assets/img/logo.svg";

function Header({ classes }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const restUri = useSelector(getRestUri);
  const user = useSelector(getMe);
  const isLogged = useSelector(getIsLogged);
  const mainRoute = useSelector(getMainRoute);
  const adminRoute = useSelector(getAdminRoute);
  const pathHash = window.location.hash;

  const isSchedaDetail = useMemo(
    () => /^#\/scheda\/[a-z0-9]+\/?/.test(pathHash),
    [pathHash],
  );

  const toggleDrawer = (op) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(op);
  };
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  return (
    <div className={classes.container}>
      <div className={cn(classes.wrapper, classes.wrapperWidthLimiter)}>
        <div className={classes.contentContainer}>
          <Link href="#/" className={classes.logo}>
            <img src={logo} alt="logo" />
          </Link>
          <div className={classes.linksContainer}>
            <Link
              variant="subtitle1"
              color="secondary"
              href="#/admin"
              className={classes.link}
            >
              <Typography>Area Privata</Typography>
            </Link>
            {(isSchedaDetail || (mainRoute === "admin" &&
              !!isLogged &&
              adminRoute !== "adminLogin" &&
              adminRoute !== "adminRecoveryPassword")) && (
                <React.Fragment>
                  <Link
                    variant="subtitle1"
                    color="secondary"
                    href="#/admin/glossary"
                    className={classes.link}
                  >
                    <Typography>Glossario</Typography>
                  </Link>
                  <Link
                    variant="subtitle1"
                    color="secondary"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className={classes.link}
                  >
                    <Typography>Contatta</Typography>
                  </Link>
                  <ButtonBase
                    disableTouchRipple
                    disableRipple
                    onClick={() => dispatch(deleteUsersTokensAjax(restUri))}
                    className={classes.logoutButton}
                  >
                    <Typography>Logout</Typography>
                  </ButtonBase>
                </React.Fragment>
              )}
            <Popover
              id={id}
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: classes.popover,
              }}
            >
              <Typography variant="subtitle1" className={classes.typography}>
                {"Invia un'e-mail a:"}
                <Link
                  variant="subtitle1"
                  color="secondary"
                  href="mailto:welfareinforma.cn@acli.it?Subject=Richiesta"
                  className={classes.mailToLink}
                >
                  welfareinforma.cn@acli.it
                </Link>
                per esprimere un feedback o porre domande inerenti la
                piattaforma
              </Typography>
            </Popover>
          </div>
          <IconButton
            className={classes.menuIconButton}
            onClick={() => setOpen(true)}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </div>
      </div>
      {open && (
        <div className={classes.drawerContainer}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
          >
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <MenuList>
                <MenuItem
                  onClick={() => {
                    window.location.href =
                      "https://forms.gle/qsZ2T3vtPzAN1d2U9";
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    className={classes.link}
                  >
                    Quaderno della sperimentazione
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "#/come-funziona";
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    className={classes.link}
                  >
                    Come funziona
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "mailto:welfareinforma.cn@acli.it";
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    className={classes.link}
                  >
                    Contatti
                  </Typography>
                </MenuItem>
                {mainRoute !== "admin" ? (
                  <MenuItem
                    onClick={() => {
                      window.location.href = "#/admin";
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      className={classes.link}
                    >
                      Admin
                    </Typography>
                  </MenuItem>
                ) : (
                  adminRoute !== "adminLogin" && (
                    <MenuItem
                      onClick={() => dispatch(deleteUsersTokensAjax(restUri))}
                    >
                      <Typography
                        variant="subtitle1"
                        color="secondary"
                        className={classes.link}
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  )
                )}
              </MenuList>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(Header));
