/* eslint no-underscore-dangle: 0 */
/* eslint max-len: 0 */

// CONSTANTS

// REACT
import React, { Fragment, useEffect, useRef, useState } from "react";
import { func, shape } from "prop-types";
import { translate } from "react-i18next";
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  ErrorOutline,
  IndeterminateCheckBox,
  SearchOutlined,
} from "@material-ui/icons";
import { MuiThemeProvider } from "@material-ui/core/styles";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setPrintMode } from "redux/actions";
import { getFilters, getPrintMode, getSchede } from "redux/getters";
// LIB
import moment from "moment";
// STYLE
import TextField from "components/text-field";
import Filters from "components/filters";
import cn from "classnames";
import stampa from "assets/img/stampa.svg";
import send from "assets/img/send.svg";
import styles from "./style.js";
import { common, dark } from "../../themes";

function SchedeList({ classes }) {
  const [checked, setChecked] = useState([]);
  const [alphabet, setAlphabet] = useState({});
  const textHandler = useRef(null);
  const [filteredSchede, setFilteredSchede] = useState([]);
  const [open, setOpen] = useState(false);
  const printMode = useSelector(getPrintMode);
  const schede = useSelector(getSchede);
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const [text, setText] = useState(filters.get("text"));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const toggleAll = () => {
    const toggleAllIndex = checked.indexOf(0);

    if (toggleAllIndex === -1) {
      setChecked([0, ...filteredSchede.map((value, index) => index + 1)]);
    } else {
      setChecked([]);
    }
  };

  useEffect(() => {
    const temp = {};
    setFilteredSchede(schede);
    schede.forEach((scheda, index) => {
      if (temp[scheda.titolo_prestazione[0].toUpperCase()] === undefined) {
        temp[scheda.titolo_prestazione[0].toUpperCase()] = index;
      }
    });
    setAlphabet(temp);
    if (checked.length === 1 && checked[0] === 0) setChecked([]);
  }, [schede, printMode, checked]);

  useEffect(() => {
    setChecked([]);
  }, [filters]);

  const handleTextChange = (value) => {
    setText(value);
    if (textHandler.current) {
      clearTimeout(textHandler.current);
    }
    textHandler.current = setTimeout(() => {
      dispatch(setFilter("text", value));
      textHandler.current = null;
    }, 500);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tra le schede selezionate almeno una non è più presentabile in questo
          momento.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Vuoi continuare comunque con l'anteprima di stampa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              dispatch(setPrintMode("schede"));
            }}
            color="primary"
            autoFocus
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>
      {!printMode && (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textFieldInput}
                placeholder="Scrivi qui la prestazione che cerchi"
                onChange={(ev) => handleTextChange(ev.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlined className={classes.searchOutlined} />
                  </InputAdornment>
                }
                value={text}
              />
            </Grid>
          </Grid>
          <MuiThemeProvider theme={common}>
            <Filters />
          </MuiThemeProvider>
        </Fragment>
      )}
      {filteredSchede.length ? (
        <Paper
          classes={{
            root: cn(classes.paper, {
              [classes.paperPrintMode]: !!checked.length,
            }),
          }}
        >
          {printMode !== "schede" ? (
            <List
              dense
              classes={{
                root: classes.list,
              }}
            >
              {!printMode && (
                <ListItem
                  classes={{
                    root: classes.listItem,
                  }}
                  dense
                  button
                  disableRipple
                  onClick={toggleAll}
                >
                  <ListItemIcon
                    classes={{
                      root: classes.listItemIcon,
                    }}
                  >
                    <Checkbox
                      color="primary"
                      icon={
                        <CheckBoxOutlineBlank
                          classes={{
                            root: classes.checkBoxOutlineBlank,
                          }}
                        />
                      }
                      checkedIcon={
                        checked.length === filteredSchede.length + 1 ? (
                          <CheckBox
                            classes={{
                              root: classes.checkBox,
                            }}
                          />
                        ) : (
                          <IndeterminateCheckBox
                            classes={{
                              root: classes.checkBox,
                            }}
                          />
                        )
                      }
                      classes={{
                        root:
                          checked.indexOf(0) === -1
                            ? cn(classes.checkBoxUnchecked, classes.checkBox)
                            : classes.checkBox,
                      }}
                      edge="start"
                      checked={checked.indexOf(0) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": "checkbox-list-secondary-label-0",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                    }}
                    id="checkbox-list-secondary-label-0"
                  >
                    <Typography
                      classes={{
                        root:
                          checked.indexOf(0) !== -1
                            ? cn(
                                classes.listItemTextTypographyChecked,
                                classes.listItemTextTypography,
                              )
                            : classes.listItemTextTypography,
                      }}
                    >
                      Seleziona Tutto
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {filteredSchede.map((scheda, index) => {
                const labelId = `checkbox-list-secondary-label-${index + 1}`;

                const isAfterStartingDate = moment(
                  scheda.inizio_richiesta || moment().subtract(1, "day"),
                ).isBefore(moment());
                const isBeforeEndingDate = moment(
                  scheda.fine_richiesta || moment().add(1, "day"),
                ).isAfter(moment());
                const isSenzaScadenza = Boolean(scheda.senza_scadenza);

                const isPresentabile =
                  isAfterStartingDate &&
                  (isSenzaScadenza || (!isSenzaScadenza && isBeforeEndingDate));

                const linkToScheda = `#/scheda/${scheda._id}`;
                const comuneATS = filters.get("comuneATS");

                const fullSchedaPath = Boolean(comuneATS)
                  ? `${linkToScheda}/${comuneATS}`
                  : linkToScheda;

                return (
                  <Fragment>
                    <Divider
                      classes={{
                        root: classes.divider,
                      }}
                    />
                    <ListItem
                      classes={{
                        root: classes.listItem,
                      }}
                      role={undefined}
                      dense
                      button
                      disableRipple
                      component="a"
                      href={fullSchedaPath}
                    >
                      {Object.values(alphabet).indexOf(index) !== -1 && (
                        <div className={classes.circle}>
                          <Typography>
                            {scheda.titolo_prestazione[0].toUpperCase()}
                          </Typography>
                        </div>
                      )}
                      {!printMode && (
                        <ListItemIcon
                          classes={{
                            root: classes.listItemIcon,
                          }}
                          onClick={handleToggle(index + 1)}
                        >
                          <Checkbox
                            color="primary"
                            icon={
                              <CheckBoxOutlineBlank
                                classes={{
                                  root: classes.checkBoxOutlineBlank,
                                }}
                              />
                            }
                            checkedIcon={
                              <CheckBox
                                classes={{
                                  root: classes.checkBox,
                                }}
                              />
                            }
                            classes={{
                              root:
                                checked.indexOf(index + 1) === -1
                                  ? cn(
                                      classes.checkBoxUnchecked,
                                      classes.checkBox,
                                    )
                                  : classes.checkBox,
                            }}
                            edge="start"
                            checked={checked.indexOf(index + 1) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        classes={{
                          root: classes.listItemText,
                        }}
                        id={labelId}
                      >
                        <Typography
                          classes={{
                            root:
                              checked.indexOf(index + 1) !== -1 || !!printMode
                                ? cn(
                                    classes.listItemTextTypographyChecked,
                                    classes.listItemTextTypography,
                                  )
                                : classes.listItemTextTypography,
                          }}
                        >
                          {`${scheda.titolo_prestazione}`}
                        </Typography>
                      </ListItemText>
                      {!printMode && (
                        <Fragment>
                          {!isPresentabile && (
                            <Tooltip
                              title={
                                <Typography variant="body1" color="inherit">
                                  {
                                    "In questo momento le richieste non si possono più presentare."
                                  }
                                </Typography>
                              }
                              placement="left"
                              classes={{
                                tooltip: classes.tooltip,
                              }}
                            >
                              <ErrorOutline
                                classes={{
                                  root: classes.errorOutline,
                                }}
                              />
                            </Tooltip>
                          )}
                          <ChevronRight
                            iconstyle={classes.chevronRight.path}
                            classes={{
                              root: classes.chevronRight,
                            }}
                          />
                        </Fragment>
                      )}
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          ) : (
            <div>
              {filteredSchede.map((scheda) => (
                <div className={classes.scheda} key={scheda.id}>
                  <Typography variant="h2">
                    {scheda.titolo_prestazione}
                  </Typography>
                  <Typography className={classes.schedaText} variant="body2">
                    {scheda.scheda_utente}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </Paper>
      ) : (
        <Typography variant="h2" className={classes.noResults}>
          {!filters.get("comuneATS") &&
          ["r", "l"].includes(filters.get("norme"))
            ? "Inserisci un comune per iniziare la ricerca."
            : "Nessun risultato presente, prova a cambiare i filtri della tua ricerca."}
        </Typography>
      )}
      {!!printMode && <div className={classes.layer} />}
      {!!checked.length && (
        <MuiThemeProvider theme={dark}>
          <AppBar className={classes.appBar} component="div">
            {printMode ? (
              <Toolbar className={classes.toolbar}>
                <Button
                  className={classes.buttonAction}
                  onClick={() => window.print()}
                >
                  <div className={classes.iconButton}>
                    <img className={classes.image} src={stampa} alt="step" />
                  </div>
                  Stampa
                </Button>
                <Button
                  className={classes.buttonAction}
                  onClick={() => dispatch(setPrintMode(null))}
                >
                  Annulla
                </Button>
              </Toolbar>
            ) : (
              <Toolbar className={classes.toolbar}>
                <Button
                  className={classes.buttonAction}
                  onClick={() => dispatch(setPrintMode("indice"))}
                >
                  <div className={classes.iconButton}>
                    <img className={classes.image} src={stampa} alt="step" />
                  </div>
                  Anteprima indice per operatore
                </Button>
                <Button
                  className={classes.buttonAction}
                  onClick={() => {
                    const expired =
                      schede.filter(
                        (scheda, index) =>
                          checked.indexOf(index + 1) !== -1 &&
                          scheda.fine_richiesta,
                      ).length > 0;
                    expired ? setOpen(true) : dispatch(setPrintMode("schede"));
                  }}
                >
                  <div className={classes.iconButton}>
                    <img className={classes.image} src={stampa} alt="step" />
                  </div>
                  Anteprima scheda per utente
                </Button>
                {checked.length === 1 && (
                  <Button
                    className={classes.buttonAction}
                    onClick={() => {
                      if (navigator.share) {
                        navigator
                          .share({
                            title: "OLP",
                            text: "Guarda la scheda",
                            url: window.location.pathname,
                          })
                          .then(() => null)
                          .catch(() => null);
                      }
                    }}
                  >
                    <div className={classes.iconButton}>
                      <img className={classes.image} src={send} alt="step" />
                    </div>
                    Invia Link
                  </Button>
                )}
              </Toolbar>
            )}
          </AppBar>
        </MuiThemeProvider>
      )}
    </Fragment>
  );
}

SchedeList.propTypes = {
  classes: shape({}).isRequired,
  t: func.isRequired,
};

export default withStyles(styles)(translate()(SchedeList));
