import _ from 'lodash'
import sg from 'styles'

export default (theme) => _.merge(
  {
    container: {
      width: '100%',
      position: 'relative',
      paddingTop: 30,
    },
    title: {
      position: 'relative',
      zIndex: 1,
      paddingBottom: 20,
      paddingLeft: 100,
    },
    stepContainer: {
      position: 'absolute',
      top: 0,
      left: -40,
      width: 120,
      height: 120,
      borderRadius: 120,
      background: theme.palette.primary.main,
      zIndex: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 30,
    },
    box: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      padding: '30px 20px',
      '@media (min-width: 600px)': {
        paddingRight: 0,
        padding: '40px 40px 0',
      },
    },
    images: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      height: '100%',
      right: 0,
      top: 0,
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'rgba(139, 138, 157, 0.1)',
      '@media (max-width: 600px)': {
        display: 'none',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 157,
    },
    image: {
      width: 67,
      height: 67,
    },
    number1: {
      fontSize: 25,
      display: 'inline-block',
    },
    number2: {
      fontSize: 17,
      display: 'inline-block',
    },
    optionalData: {
      padding: '20px 0 30px',
    },
  },
  sg,
)
